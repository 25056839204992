

#changePwd {
  background-color: #f6f7f8;
  // padding-top: 80px;
  @media (max-width: 767px) {
    margin-top: 35px;
  }
  .change-pwd-container {
    // max-width: 1140px;
    // margin: auto;
    padding: 25px 0px;
    // padding: 20px 40px;
    font-family:"Nunito";
    .ant-form-vertical .ant-form-item-label > label {
      font-weight: 500;
      font-size: 18px;
    }
    h2 {
      font-weight: 700;
      text-align: center;
      color: #bd0c21;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
    .Quatation-header {
      // max-width: 1140px;
      // margin: auto;
      padding: 25px 0px;
      // padding: 20px 40px;
      background-color: #706e6e;
      font-family:"Nunito";
      .ant-form-vertical .ant-form-item-label > label {
        font-weight: 800;
        font-size: 18px;
      }
      h2 {
        font-weight: 700;
        text-align: center;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
    .ant-card {
      width: 1200px;
      margin: auto !important;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
      margin: 25px 0px;
      border-radius: 10px;
      @media (max-width: 767px) {
        width: 350px;
      }
    }
    .change-quotation-btn {
      button {
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 14px;
        width: 100%;
        height: 40px;
        margin-top: 6px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .change-clear-btn {
      button {
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 15px;
        height: 40px;
        width: 100%;
        margin-top: 6px;
        padding: 0px 70px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
.one-package-data .ant-form-vertical .ant-form-item-label > label {
  font-weight: normal !important;
  font-size: 15px !important;
}
.view-agent-bc {
  display: block;
  margin-left: auto;
}
.view-pkg-v1 {
  font-size: 18px;
  color: #bd0c21;
}
