// .contact_wrapper {
//   // background-color: #f5fbf7;
//   // background-color: #ff603b;
//   // background-image: linear-gradient(to right, #bd0c21, #35459c);
//   padding: 100px 0 0 0;
//   width: 100%;
//   .inner_wrapper {
//     padding: 8px;
//         box-shadow: 1px 0px 8px lightgrey;

//     .contact_header {
//       text-align: center;
//       margin-bottom: 16px;
//       h2 {
//         font-size: 24px;
//         color: #000000;
//         font-weight: 600;
//       }
//     }
//     .dateStyle {
//       width: 100%;
//     }
//     .isdCode {
//       width: 30%;
//     }
//     .mobile {
//       width: 70%;
//     }
//     .center {
//       text-align: center;
//     }
//     Button {
//       margin-top: 10px;
//     }
//   }
// }
// .from-contact-24 .ant-form-item {
//   margin-bottom: 6px;
// }


.contact-us-page {
  font-family: "Nunito";
  background-color: #f8f9fa;
  color: #333;
  // padding-top: 70px;

  .heroS {
    // background: linear-gradient(to right, #4a90e2, #50b9f4);
    background-image: url('https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/bg/bg.jpg');
    color: white;
    text-align: center;
    padding: 60px 20px;
    padding-top: 130px;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 16px;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 24px;
    }

    button {
      font-size: 1rem;
      padding: 10px 20px;
      border-radius: 5px;
    }
  }

  .contact-details {
    padding: 40px 20px;

    .info-box {
      background: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      margin-bottom: 20px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      font-family: "Nunito" !important;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
      }

      svg {
        margin-bottom: 10px;
      }

      p {
        font-size: 1.1rem;
        font-weight: bold;
      }

      span {
        color: #777;
      }

      .mail-us-i {
        // display: flex;
        //   justify-content: center;
        position: relative;
        display: inline-block;
        // color: #333;
        /* Text color */
        font-size: 18px;
        text-decoration: none;
        padding: 5px 10px;
        cursor: pointer;
        overflow: hidden;

        // &:hover {
        //   border-bottom: 1px solid #4a90e2;
        //   // max-width: fit-content;

        // }
      }

      .mail-us-i::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 1px;
        background-color: #007bff;
        /* Border color */
        transition: all 0.3s ease;
        transform: translateX(-50%);
      }

      .mail-us-i:hover::after {
        width: 100%;
        left: 0;
        transform: translateX(0);
      }
    }
  }

  .contact-form-section {
    background: white;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px;

    .ant-divider {
      font-size: 1.2rem;
      color: #4a90e2;
    }

    form {
      .ant-row {
        margin-bottom: 16px;
      }

      .ant-input,
      .ant-input-textarea {
        border-radius: 5px;
      }

      .ant-btn {
        width: 100%;
        border-radius: 5px;
        background: #4a90e2;
        border: none;

        &:hover {
          background: #357ab7;
        }
      }
    }
  }

  .faq-section {
    margin: 0 10%;
    padding: 40px 20px;

    .ant-collapse {
      background: white;
      border-radius: 10px;

      .ant-collapse-item {
        border-bottom: none;

        &:last-child {
          border-radius: 0 0 10px 10px;
        }
      }

      .ant-collapse-header {
        font-weight: bold;
        font-size: 1rem;
        color: #4a90e2;
      }
    }

    .faq-drop-ab {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      background: white;
      border-radius: 8px;
    }
  }

  .map-section {
    padding: 40px 20px;

    .ant-divider {
      font-size: 1.2rem;
      color: #4a90e2;
    }

    div {
      border-radius: 10px;
      overflow: hidden;
    }
  }

  // Global Styling
  .ant-divider {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
  }

  .ant-btn {
    font-size: 1rem;
    text-transform: uppercase;
  }
}

.zigZagD {
  position: relative;
  margin-bottom: -18px;
  width: 100%;
  filter: invert(.9);
}