

.visa-search-1 {
  margin-top: 30px;
}

.visa-poplar {
  text-align: center;
  padding-bottom: 25px;
}

.visa-card {
  background-color: #fff;
  border-radius: 2px;
  width: 92%;
  transition: 0.3s ease-out;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  .ant-card-body {
    padding: 0 !important;
  }
}

.visa-card:hover {
  transform: scale(1.1, 1.1);
  box-shadow: 3px 3px 35px -21px rgba(0, 0, 0, 0.77);
  z-index: 9999;
  cursor: pointer;
  background-color: #fff;
}

.visa-pkg-name {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 25px;
  color: #000;
  font-weight: 500;
  margin: 0;
}

.visa-pkg-name span {
  width: 100%;
  float: left;
  text-align: center;
  color: #9b9b9b;
  font-size: 15px;
  font-weight: normal;
  padding-top: 5px;
}

.visa-pkg-price {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 25px;
  padding-top: 20px;
  font-weight: 600;
}

.orange-color {
  min-height: 320px;
  color: #bd0c21;
  border-bottom: 3px solid #bd0c21;
}

.blue-color {
  color: #4dd0da;
  border-bottom: 3px solid #4dd0da;
}

.violet-color {
  color: #784dda;
  border-bottom: 3px solid #784dda;
}

.yellow-color {
  color: #f3af3e;
  border-bottom: 3px solid #f3af3e;
}

.applying-visa {
  background-color: #ffb74d !important;
  padding-top: 80px;
  padding-bottom: 30px;
}

.simple-visa {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  text-align: center;
}

.choose-section {
  margin-top: 30px;
}

.central-asia li a {
  color: #929292;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
  border-right: 1px solid #929292;
  line-height: 12px;
}

.asia-two {
  margin-top: 20px;
}

.all-tabs-country .ant-tabs-tab {
  font-size: 20px !important;
}

@media screen and (max-width: 768px) {
  .hotels_search_container .hotel_banner .hotel_search {
    position: static;
    transform: none;
  }

  .hotels_search_container .hotel_banner .heading_text {
    top: 8px;
    padding: 24px 16px;
    bottom: auto;
  }

  .hotels_search_container {
    margin-top: 41px;
  }

  .hotels_search_container .hotel_banner .heading_text {
    position: static;
    transform: none;
    //background-color: #002172;
  }

  .hotels_search_container .hotel_banner .heading_text h2 {
    font-size: 16px;
    font-weight: 700;
  }

  .hotels_search_container .hotel_banner .hotel_search {
    position: static;
    transform: none;
  }
}

.holidays_search_container_1 {
  padding-top: 80px;

  .visaSearch {
    position: absolute;
    top: 30%;
    left: 50%;
    max-width: 900px;
    width: 100%;
    transform: translate(-50%, 0);
    padding-top: 15px;

    @media (max-width: 991px)  {
      top: 25%;
    }

    // @media (max-width: 767px) {
    //   position: initial;
    //   transform: none;
    //   left: 0;
    //   top: 0;
    //   padding-top: 0;
    // }
  }

  .visaSearch {
    .flight_search_box {
      border-radius: 0;
    }

    h4 {
      font-size: 45px;
      font-weight: 700;
      color: #fff;
      font-family:"Nunito";
      text-align: center;
      cursor: pointer;
      text-shadow: 2px 3px 2px grey;

      @media (max-width: 767px) {
        font-size: 16px !important;
      }
    }
  }

  .primary-btn {
    background: linear-gradient(90deg, #320063, #0f76bb) !important;
    text-align: center;
    display: block;
    color: #fff;
    font-size: 16px;
    outline: 0;
    font-weight: 600;
    border: 1px solid #f6f9fc !important;
    // border-radius: 40px !important;
    height: 50px;
    width: 158px;
  }

  .ant-select-selector {
    height: 50px !important;
    border-radius: 0px !important;
    padding: 14px 11px;
    border-color: transparent !important;

    border-right-width: 0px !important;

    border: 1px solid #f6f9fc !important;
    color: #bfbfbf;

    .ant-select-selection-search-input {
      color: black;
    }

    .ant-select-selection-placeholder {
      border: 0;
      background: none;
      font-weight: 600;
      font-size: 15px;
      line-height: 46px !important;
      outline: medium none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #000000;
    }

    .ant-select-selection-item {
      border: 0;
      background: none;
      font-weight: 600;
      font-size: 15px;
      outline: medium none;
      line-height: 46px !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #000;
    }

    .ant-select-selection-search-input {
      height: 50px !important;
    }
  }

  .visa-block {
    position: relative;
    z-index: 0;
  }

  .visImage {
    height: 150px;
    object-fit: cover;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      object-position: center center;
      height: 100%;
    }
  }

  .visa-guide:hover {
    text-decoration: underline !important;
  }
}

.visa-modify-data {
  h4 {
    display: none;
  }

  .visaSearch {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .visapanel-bg {
    // background-color: #2f81ed;
    background-image: linear-gradient(to right, #bd0c215f, #35459c62);
    padding: 10px 10px;
    position: relative !important;
  }

  .visaSearch h4 {
    font-size: 18px !important;
  }

  .primary-btn {
    width: 100%;
  }

  .visa-modify-data {
    padding: 10px 10px;
  }
}

.padding-outerdiv-work-searchbar {
  padding-right: 3%;

  @media (max-width: 767px) {
    padding-right: 1%;
  }
}

.tabs-fbt-space {
  background-color: #f5f5f5;
}