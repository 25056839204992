.hotel-fair-box {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 5px;

  .cards {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .gstdetails,
  .pricedetails {
    background-color: #fff;
    padding: 0.75rem;

    button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0;
      color: #1c2b39;
      font-size: 14px;
      text-decoration: none;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        text-decoration: none;
      }

      i {
        margin-right: 5px;
      }
    }
  }

  .cards-body {
    padding: 0.75rem !important;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    span {
      &:nth-child(1) {
        margin-left: 20px;
      }
    }
  }

  .total-fare {
    padding: 15px;

    .price-block {
      display: flex;
      justify-content: space-between;

      .total-text {
        font-size: 18px;
      }

      .price {
        font-size: 18px;

        span {
          font-weight: 600;
        }
      }
    }

    .book-facilities {
      ul {
        margin-bottom: 0;

        li {
          font-size: 12px;
          color: #bd0c21;

          span {
            border-bottom: 1px dotted #bd0c21;
          }

          a {
            color: #969dac;
            border-bottom: 1px dotted #969dac;
          }
        }
      }
    }
  }
}

.new-preview-box {
  margin-top: 54px;
}

.grand_tCard_row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  margin: 6px !important;
  justify-content: space-between;
  white-space: nowrap;

  .d-flex-col {
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    .anticon-question-circle {
      margin: 0 0 14px 5px;
      color: #969dac;
      cursor: pointer;
    }
  }
}