

// .promocodes {
//   .deals-details-wrapper {
//     background-image: url("../../assets/images/deals_details_bg.jpg");
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//     height: 100%;
//     width: 100%;
//     margin-top: -67px;
//     padding-top: 387px;
//     position: relative;
//     @media (max-width: 767px) {
//       background-position: center;
//       padding-top: 250px;
//     }
//     .bread-crumb-block {
//       position: absolute;
//       top: 70px;
//       ul.breadcrumb {
//         padding: 10px 16px;
//         list-style: none;
//         background-color: transparent;
//       }
//       ul.breadcrumb li {
//         display: inline;
//         font-size: 12px;
//       }
//       ul.breadcrumb li + li:before {
//         padding: 8px;
//         color: #dcdee3;
//         content: "/\00a0";
//       }
//       ul.breadcrumb li a {
//         color: #dcdee3;
//         text-decoration: none;
//       }
//       ul.breadcrumb li a:hover {
//         color: #dcdee3;
//         text-decoration: underline;
//       }
//       ul.breadcrumb li span {
//         color: #fff;
//         font-weight: 700;
//       }
//     }
//   }
//   .main-text {
//     position: absolute;
//     top: 50%;
//     left: 0;
//     right: 0;
//     @media (max-width: 767px) {
//       top: 55%;
//     }
//     h2 {
//       color: #fff;
//       font-family:"Nunito";
//       font-size: 45px;
//       text-align: center;
//       @media (max-width: 767px) {
//         font-size: 24px;
//       }
//     }
//   }
//   hr {
//     margin-bottom: 0px;
//     margin-top: 0px;
//   }
// }
// .promocode-wrapper {
//   // background-color: #f6f7f8;
//   .promocodes-section {
//     padding: 25px;
//     @media (max-width: 767px) {
//       padding: 25px 5px;
//     }
//     .coupon-card {
//       .coupon-content {
//         border: solid 1px #ccc;
//         margin-top: -8px;
//         padding: 10px;
//         border-radius: 35px;
//         border-top-left-radius: 0px;
//         border-top-right-radius: 0px;
//         background-color: #fff;
//         .coupon-code {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           p,
//           h2 {
//             margin-bottom: 0px;
//           }
//           h2 {
//             font-size: 20px;
//             @media (max-width: 767px) {
//               font-size: 17px;
//             }
//           }
//           .codes {
//             p {
//               font-size: 11px;
//             }
//           }
//           .copy-code {
//             cursor: pointer;
//             padding: 2px 7px;
//             background-color: #eeffdd;
//             color: #417505;
//             border: 2px dotted red;
//             font-size: 11px;
//           }
//         }
//         .coupon-expiry-details {
//           display: flex;
//           justify-content: space-between;
//           .countdown {
//             p {
//               font-size: 11px;
//             }
//           }
//           .expiry-date {
//             color: red;
//           }
//           .more-details {
//             color: blue;
//             display: none;
//           }
//           p {
//             margin-bottom: 0px;
//           }
//         }
//       }
//     }
//   }
// }
// .offers-slider {
//   .imagewrapper {
//     height: 159px;
//   }
//   .slide-img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     border-radius: 35px;
//     border-bottom-left-radius: 0px;
//     border-bottom-right-radius: 0px;
//   }
// }
// .promocode-wrapper{
//   background: #fff;
//   // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
//     border-radius: 5px;
//     margin-top: 56px;
//   }
//     .promocodes-section{
//   box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
// }



.offers-page {
  font-family: "Nunito", sans-serif;
  .zigZagD {
    position: relative;
    margin-bottom: -18px;
    width: 100%;
    filter: invert(.9);
}

  .banner-section {
    background: linear-gradient(to bottom right, #4facfe, #00f2fe);
    padding: 30px 0;
  }

  .animated-banner {
    text-align: center;
    padding: 120px 20px 20px;
    background: rgba(0, 123, 255, 0.1);
    border-radius: 10px;
    animation: fadeInUp 1.5s ease;
    border-bottom: 14px solid #c2c5c6;

    h1 {
      font-size: 36px;
      color: #003580;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      color: #007bff;
      margin-bottom: -10px;
    }

    .explore-btn {
      background: #007bff;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background: #0056b3;
      }
    }

    .skyline {
      position: relative;
      bottom: -26px;
      filter: brightness(0.8);
    }

    .sky-wheel {
      position: relative;
      right: 610px;
      top: 37px;
      animation: rotate-clockwise 12s linear infinite;
      filter: brightness(0.8);
    }

    @keyframes rotate-clockwise {
      from {
        transform: rotate(0deg);

      }

      to {
        transform: rotate(360deg);

      }
    }
  }

  .section-title {
    text-align: center;
    margin: 20px 0;


    font-size: 28px;
    color: #003580;
    margin-bottom: 10px;


    .underline {
      width: 160px;
      height: 4px;
      background: #007bff;
      margin: 0 auto;
      border-radius: 0 0 4px 4px;
    }
  }

  .coupon-card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    .offer-img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .coupon-content {
      padding: 15px;

      .offer-title {
        font-size: 18px;
        font-weight: bold;
        color: #003580;
        max-width: 225px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

      }

      .off-desc-p {
        max-width: 225px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 44px;
        text-overflow: ellipsis;
        font-family: "nunito";
        font-weight: 400;
        font-size: 14px;
        margin: 0;
      }

      .offer-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .expiry {
          font-size: 14px;
          color: #6c757d;
          display: grid;
          margin: 0;
        }

        // .redeem-btn {
        //   background: #007bff;
        //   color: white;
        //   padding: 5px 10px;
        //   border: none;
        //   border-radius: 5px;
        //   cursor: pointer;
        //   transition: background 0.3s ease;

        //   &:hover {
        //     background: #0056b3;
        //   }
        // }
        .redeem-btn {
          display: inline-block;
          position: relative;
          padding: 10px 20px;
          font-size: 12px;
          color: #fff;
          background: linear-gradient(90deg, #ff7b54, #ff3e3e);
          border: 0px dashed #fff;
          border-radius: 8px;
          text-align: center;
          cursor: pointer;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
          overflow: hidden;
          transition: all 0.3s ease-in-out;
          // .pr-txt-o{
          //   margin: 0;
          //   border-left: 2px dashed #fff;
          //   border-right: 2px dashed #fff;
          // }
        }

        .redeem-btn:hover {
          background: linear-gradient(90deg, #ff3e3e, #ff7b54);
          transform: scale(1.03);
        }

        .redeem-btn:before,
        .redeem-btn:after {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          background: #fff;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
        }

        .redeem-btn:before {
          left: -7px;
        }

        .redeem-btn:after {
          right: -7px;
        }

      }
    }
  }
}

.containerOf {
  padding: 20px;
  width: 1200px;
  margin: 0 auto;

  // .ant-tabs {
  //   &-tab {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;
  //     margin: 0 8px;
  //     padding: 10px 16px;
  //     border-radius: 12px;
  //     transition: all 0.3s;
  //     cursor: pointer;

  //     &:hover {
  //       background: #f0f2f5;
  //       transform: translateY(-3px);
  //     }

  //     &-active {
  //       background: #e6f7ff;
  //       border-bottom: 3px solid #1890ff;
  //       box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  //     }
  //   }
  // }

  // .tab-header {
  //   display: flex;
  //   align-items: center;
  //   flex-direction: column;
  //   gap: 5px;

  //   .tab-image {
  //     width: 40px;
  //     height: 40px;
  //     border-radius: 50%;
  //     object-fit: cover;
  //     border: 2px solid #ababab;
  //     padding: 4px;
  //     background: white;
  //     &:active{
  //       border: 2px solid #1890ff;
  //     }
  //   }

  //   .tab-title {
  //     font-size: 14px;
  //     font-weight: 500;
  //     text-transform: capitalize;
  //     color: #333;
  //   }
  // }

  // .tab-content {
  //   margin-top: 20px;

  //   .section-title {
  //     font-size: 24px;
  //     font-weight: bold;
  //     margin-bottom: 20px;
  //   }
  // }
  .ant-tabs {
    &-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 8px;
      padding: 10px 16px;
      border-radius: 12px;
      transition: all 0.3s;
      cursor: pointer;
      border: none !important; // Remove default border
      background: transparent; // Ensure no background leaks

      &:hover {
        background: #f0f2f5;
        transform: translateY(-3px);
      }

      &.ant-tabs-tab-active {
        background: #e6f7ff;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

        .tab-image {
          border-color: #1890ff !important; // Force border color change
        }
      }
    }

    // Remove the bottom border from the tab container
    &-nav {
      &::before {
        border: none !important;
      }
    }
  }

  .tab-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;

    .tab-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #d9d9d9 !important; // Default border color for inactive tabs
      padding: 4px;
      background: white;
      transition: border-color 0.3s ease; // Smooth transition for border color
    }

    .tab-title {
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      color: #333;
    }
  }

  .tab-content {
    margin-top: 20px;

    .section-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}

// .tab1-content-off{
//   max-height: 800px;
//   position: relative;
//   overflow-y: scroll;
//   overflow-x: hidden;
//   background: #f4f4f4;
//   padding: 4px;
// }