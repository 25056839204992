.features-container {
    text-align: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .features-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0c50bd;
    font-family: "Nunito";
  }
  
  .features-subheading {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
    font-family: "Nunito";
  }
  
  .carousel-arrow {
    font-size: 24px;
    color: #333;
  }
  .feature-cont-card{
    display: flex;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    justify-content: space-evenly;
    padding: 4px 8px;
  }
  
  .feature-card {
    background-color: #fff;
    
    padding: 10px;
    text-align: center;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
  }
  
  .icon-container {
    width: 50px;
    height: 50px;
    background-color: #f9f9f9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .feature-icon {
    width: 30px;
    height: 30px;
  }
  
  .feature-title {
    font-size: 18px;
    font-weight: bold;
    color: #35459c;
    margin: 10px 0;
    font-family: "nunito";
  }
  
  .feature-description {
    font-size: 12px;
    color: #666;
    font-family: "Nunito";
  }
  