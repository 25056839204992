

.main-searchbar-tabs-box-popup {
    border: 2px solid transparent;
    width: 100%;
    margin-top: -25%;
    position: relative;
    margin-bottom: 6%;

    @media (max-width: 1200px)  {
        width: 100%;
        margin-top: -25%;
    }

    @media (max-width: 991px)  {
        margin-top: 0%;
    }

    @media (max-width: 767px) {
        margin-top: 3%;
    }
}

.main-searchbar-tabs-box {
    border: 2px solid transparent;
    width: 100%;
    margin-top: -31%;
    position: relative;
    margin-bottom: 12%;
    display: block;
    height: 100%;

    @media (max-width: 1200px)  {
        width: 100%;
        margin-top: -25%;
    }

   @media (max-width: 991px)  {
        margin-top: -30%;
    }

    @media (max-width: 991px)  {
        margin-top: -30%;
    }

    @media (max-width: 770px) {
        margin-top: -33%;

    }

    @media (max-width: 767px) {
        display: none;
        margin-top: 3%;
    }
}

.main-searchbar-tabs-boxfor-mob-view {
    display: none;

    @media (max-width: 767px) {
        display: block;
        position: relative;
        margin-top: -15%;
        width: 90%;
        margin-left: 5%;
        margin-bottom: 10%;

    }
}

.main-searchbar-tabs-box1 {

    width: 1200px;
    height: 100%;
    margin: auto;
    background-color: white;
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-bottom: 2%;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);

    @media (max-width: 1200px)  {
        width: 93%;
        margin-top: -155px;
    }

    @media (max-width: 991px)  {
        width: 93%;
    }

    @media (max-width: 767px) {
        width: 100%;
        height: 100%;
    }
}

.tabs-main-box-outer {
    // border-bottom: 1px solid rgb(207, 207, 207);
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
        margin-bottom: 4%;
    }

}

.tabs-main-box-inner {
    width: 35%;
    text-align: center;
    color: #000000;
    padding: 1%;
    margin-top: -60px;

    margin-left: 18px;
    background: white;
    border-radius: 9px;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);


    @media (max-width: 991px)  {
        width: 50%;
    }

    @media (max-width: 770px) {
        width: 50%;
    }

    @media (max-width: 767px) {
        width: 30%;
    }


    .src-Icon {
        width: 50px;
    }
}

.tabs-main-box-inner1 {
    width: 15%;
    text-align: center;
    color: #000000;
    padding: 1%;





    @media (max-width: 991px)  {
        width: 13%;
    }

    @media (max-width: 770px) {
        width: 15%;
    }

    @media (max-width: 767px) {
        width: 30%;
    }



}

.tabs-main-box-inner2 {
    width: 15%;
    text-align: center;
    color: #000000;
    padding: 1%;





    @media (max-width: 991px)  {
        width: 13%;
    }

    @media (max-width: 770px) {
        width: 15%;
    }

    @media (max-width: 767px) {
        width: 30%;
    }



}

.tabs-main-box-inner3 {
    width: 15%;
    text-align: center;
    color: #000000;
    padding: 1%;





    @media (max-width: 991px)  {
        width: 13%;
    }

    @media (max-width: 770px) {
        width: 15%;
    }

    @media (max-width: 767px) {
        width: 30%;
    }



}

// .tabs-main-box-inner:hover {
//     background-color: #f0f0f0;
//     color: #363a45;
// }

.combinesearch-tab-headng {
    font-size: 18px;
    font-weight: 800;
    font-family: "Nunito";
}

.active2 {
    color: #007bff;
    width: 15%;


}

.upperheading-of-searchlinkbar {
    font-weight: bold;
    color: white;
    position: absolute;
    z-index: 1;
    top: 17%;
    left: 9%;
    display: block;

    @media (max-width: 991px)  {
        top: 27%;
        left: 4%;
    }

    @media (max-width: 770px) {
        top: 55%;
        left: 4%;
    }

    @media (max-width: 767px) {
        display: block;
        top: 8%;
        left: 11%;
        top: 3%;
    }

}


.upperheading-of-searchlinkbarholiday {
    font-weight: bold;
    color: rgb(255, 255, 255);
    position: absolute;
    z-index: 1;
    top: 35%;
    left: 9%;
    display: block;

    @media (max-width: 991px)  {
        top: 25%;
        left: 4%;
    }

    @media (max-width: 770px) {
        top: 40%;
        left: 4%;
    }

    @media (max-width: 767px) {
        display: block;
        top: 30%;
        left: 11%;
    }

}

.upperheading-of-searchlinkbarhotel {
    font-weight: bold;
    color: white;
    position: absolute;
    z-index: 1;
    top: 35%;
    left: 9%;
    display: block;

    @media (max-width: 991px)  {
        top: 25%;
        left: 4%;
    }

    @media (max-width: 770px) {
        top: 40%;
        left: 4%;
    }

    @media (max-width: 767px) {
        display: block;
        top: 30%;
        left: 11%;
        font-weight: bold;
        color: white;
        position: absolute;
        z-index: 1;
    }

}