

$primary: #d5436a;
$secondry: #05004e;

.add-plus-icon {
    font-size: 30px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.action-images {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 17px;

    img {
        max-width: 35px;
        max-height: 35px;
        margin-right: 15px;

        @media (max-width: 767px) {
            max-width: 25px;
            max-height: 25px;
            margin-right: 10px;
        }
    }
}