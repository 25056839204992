.trip-planner {
    padding: 20px;
    background-color: #ffffff;
  
    h2 {
      font-size: 28px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 6px;
      color: #0c50bd;
      font-family: "Nunito";
    }
  
    p {
      font-size: 16px;
      text-align: center;
      color: #666;
      margin-bottom: 20px;
    }
  
    .ant-tabs {
      margin-bottom: 20px;
  
      .ant-tabs-tab {
        font-size: 14px;
        font-weight: 500;
        padding: 10px 15px;
        color: #666;
  
        &:hover {
          color: #1890ff;
        }
      }
  
      .ant-tabs-tab-active {
        color: #1890ff;
        font-weight: 600;
  
        .ant-tabs-tab-btn {
          color: #1890ff;
        }
      }
  
      .ant-tabs-ink-bar {
        background-color: #1890ff;
      }
    }
  
    .ant-spin {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
    }
  
    .slick-slider {
      .slick-list {
        margin: 0 auto;
        padding: 10px 0;
      }
  
      .slick-prev,
      .slick-next {
        font-size: 20px;
        color: #666;
  
        &:hover {
          color: #1890ff;
        }
  
        &:before {
          font-size: 20px;
          color: inherit;
        }
      }
  
      .slick-dots {
        bottom: -10px;
  
        li {
          width: 10px;
          height: 10px;
  
          button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ccc;
  
            &:hover {
              background-color: #1890ff;
            }
          }
  
          &.slick-active button {
            background-color: #1890ff;
          }
        }
      }
    }
  
    .ant-card {
      width: 266px !important;
      height: 320px;
      margin: 10px;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
  
      &:hover {
        transform: scale(1.05);
        box-shadow:1px 0px 8px #ffa80a;
      }
  
      .ant-card-cover {
        height: 160px;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
  
      .ant-card-meta {
        padding: 10px;
  
        .ant-card-meta-title {
          font-size: 16px;
          font-weight: 600;
          color: #333;
        }
  
        .ant-card-meta-description {
          font-size: 14px;
          color: #666;
        }
      }
  
      p {
        font-size: 12px;
        color: #888;
        margin: 5px 10px 0;
      }
    }
  
    // Responsive styles
    @media (max-width: 1024px) {
      .slick-slider {
        .slick-list {
          padding: 5px 0;
        }
      }
  
      .ant-card {
        width: 180px;
        height: 280px;
  
        .ant-card-cover {
          height: 140px;
        }
  
        .ant-card-meta {
          padding: 8px;
  
          .ant-card-meta-title {
            font-size: 14px;
          }
  
          .ant-card-meta-description {
            font-size: 12px;
          }
        }
      }
    }
  
    @media (max-width: 600px) {
      .slick-slider {
        .slick-list {
          padding: 0;
        }
      }
  
      .ant-card {
        width: 100%;
        height: auto;
  
        .ant-card-cover {
          height: 200px;
        }
  
        .ant-card-meta {
          padding: 10px;
  
          .ant-card-meta-title {
            font-size: 16px;
          }
  
          .ant-card-meta-description {
            font-size: 14px;
          }
        }
      }
    }
  }
  .trip-planner {
    max-width: 100%;
    padding: 20px;
    @media (max-width:770px){
      max-width: 59%;
    }
    @media (max-width:991px){
      max-width: 59%;
    }
    @media (max-width:1024px){
      max-width: 65%;
    }
}

.trip-planner h2 {
    font-size: 24px;
    text-align: center;
}

.trip-planner p {
    text-align: center;
    font-size: 16px;
    color: #666;
}

.ant-tabs {
    width: 100%;
}

.destination-card {
    width: 100%; // Makes sure the card adapts to screen size
    max-width: 300px; // Prevents oversized cards
    margin: auto;
}

.destination-card img {
    width: 100%;
    height: 180px; // Fixed height for uniformity
    object-fit: cover; // Ensures images scale correctly
}

@media screen and (max-width: 768px) {
    .trip-planner {
        padding: 10px;
    }

    .trip-planner h2 {
        font-size: 20px;
    }

    .destination-card {
        max-width: 250px;
    }
}

@media screen and (max-width: 480px) {
    .trip-planner h2 {
        font-size: 18px;
    }

    .destination-card {
        max-width: 220px;
    }

    .destination-card img {
        height: 150px;
    }
}
