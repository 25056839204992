/* Chatbot Icon */
.chatbot-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 1000;
}

.chatbot-icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.249);
}

/* Chatbot Window */
.chatbot-window {
  position: fixed;
  bottom: 80px;
  right: 60px;
  width: 280px;
  height: 360px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 1001;
}

/* Chatbot Header */
.chatbot-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chatbot-header button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

/* Chatbot Messages */
.chatbot-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.chatbot-message {
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 12px;
  max-width: 80%;
  font-size: 14px;
}

.bot-message {
  background-color: #e9e9e9;
  align-self: flex-start;
}

/* User message bubble */
// .user-message {
//   background-color: #f5d3cf;
//   /* Background color for user message */
//   color: #000;
//   /* Text color */
//   align-self: flex-end;
//   /* Align to the right */
//   position: relative;
//   /* For the pseudo-element positioning */
//   padding: 10px 15px;
//   margin: 10px 0;
//   border-radius: 16px 16px 4px 16px;
//   /* Bottom-left corner more rounded */
//   max-width: 70%;
//   font-size: 14px;
// }
/* User message bubble with sharp tail */
.user-message {
  background-color: #fde4e1;
  /* Light red/pink color */
  color: #000;
  /* Text color */
  align-self: flex-end;
  /* Align message to the right */
  position: relative;
  /* For pseudo-element positioning */
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 16px;
  /* Rounded corners */
  max-width: 70%;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  margin-left: 36px;
}

/* Triangle sharp tail for user message */
.user-message::after {
  content: '';
  position: absolute;
  bottom: 0px;
  /* Align with the bubble's bottom padding */
  right: -8px;
  /* Adjust to attach to the right edge */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0px 12px 20px;
  /* Triangle dimensions */
  border-color: transparent transparent transparent #fde4e1;
  /* Match bubble color */
}

/* Bot message bubble */
.bot-message {
  background-color: #e9effe;
  /* Background color for bot message */
  color: #000;
  /* Text color */
  align-self: flex-start;
  /* Align to the left */
  position: relative;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 16px 16px 16px 4px;
  /* Bottom-right corner more rounded */
  max-width: 70%;
  font-size: 14px;
}

/* Time styling for messages */
// .message-time {
//   font-size: 12px;
//   color: #888;
//   text-align: right;
//   margin-top: 4px;
// }

/* Chatbot Input */
.chatbot-input {
  display: flex;
  border-top: 1px solid #ccc;
}

.chatbot-input input {
  flex: 1;
  padding: 10px;
  border: none;
  font-size: 14px;
}

.chatbot-input button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.chatbot-questions {
  .question-button {
    border-radius: 6px;
    background: rgba(213, 255, 213, 0.646);
    border: 1px solid lightgreen;
    margin: 1px 2px;
  }
}