 
.visa-Review {
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  .visaDetailCard {
    border-radius: 5px;
    .visa-details {
      p {
        margin: 0 !important;
      }
    }
    .overflow-hidden{
      border: 1px solid #35459c;
      position: absolute;
      right: 9px;
      height: 255px;
      width: 190px;
      border-radius: 4px;
      @media (max-width: 767px){
        border: 1px solid #35459c00;
      position: relative;
      right: 0px;
      height: 0px;
      width: 0px;
      border-radius: 4px;
      }
    }
    .visaDetailImage {
      // height: 100%;
      // width: 122px;
      // object-fit: cover;
      width: 100%;
    /* width: 170px; */
    object-fit: cover;
    height: 100%;
    }
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }

  .ant-card {
    border-radius: 8px;
  }
  .card_wrapper,
  .pax-info-wrapper,
  .paxcontact_wrapper {
    margin-bottom: 16px;
  }
}
.uploadimg{
  .uplod-p{
    // font-weight: 600;
    font-size: 16px;
    .uplod-span{
      border: 1px solid rgb(0, 218, 0);
      border-radius: 8px;
      padding: 1px 6px;
      background: rgb(188, 255, 182);
      // color: rgb(0, 255, 64);
      font-size: 14px;
    }
  }
}