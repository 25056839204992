 
.bus-block {
  position: relative;
  z-index: 1;
  .bus-banner {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .buses-search {
    position: absolute;
    //top: 50%;
    left: 50%;
    max-width: 1200px;
    width: 100%;
    transform: translate(-50%, 0);
    padding-top: 15px;
    margin-top: -116px;

    .flight_search_box {
      border-radius: 0;
    }
    @media (max-width: 767px) {
      position: initial;
      transform: none;
      left: 0;
      top: 0;
      padding-top: 0;
    }
  }

  .bus-search-header {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: 767px) {
      position: inherit;
      padding: 10px;
    }
    h2 {
      font-size: 34px;
      text-shadow: 3px 3px 4px grey;

      font-weight: 700;
      color: #fff;
      font-family:"Nunito"; ;
      @media (max-width: 767px) {
        font-size: 16px;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
  .hotels_search_box {
    border-radius: 0;
  }
}
 

.img-text-center {
  text-align: center;
}
.bus-inner-trvels {
  padding-top: 30px;
  padding-bottom: 30px;
}
.tickets-book-trv {
  padding-bottom: 25px;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  color: #1c2b39;
}
.img-text-center-loc {
  text-align: center;
}
.travel-locations {
  padding-top: 50px;
  justify-content: center;
}
.text-case-font {
  color: #a5a5a5;
  font-size: 14px;
}
.img-text-center-loc p {
  margin-bottom: 3px !important;
}
.booking-seletion {
  font-size: 1.5rem;
}
.mt-3-p {
  margin-top: 1rem;
}
.offline-bus-bokk {
  padding-bottom: 25px;
}
.offline-bus-bokk li {
  list-style: inside;
}
.bdr-trv-bus {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .bus-block {
    // background-color: #2f81ed;
    // background-image: linear-gradient(to right, #bd0c215f, #35459c62);
    // padding: 14px;
    // padding-top: 20%;
  }
}
.bus-support-24 {
  padding-top: 25px;
}

.img-text-center:hover {
  background: #f8f9fa;
  //box-shadow: 0 1px 6px 0 rgb(32 33 36);
  border-radius: 4px;
}
.new-bus-top {
  margin-bottom: 20px !important;
}


