

#travelerDetails {
  // background-color: #f6f7f8;
  padding-top: 100px;
  .ant-form-item-label > label {
    font-weight: 700;
  }

  .backToList {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 15px 0 15px;
    @media (max-width: 767px) {
      padding: 15px 15px 0 15px;
    }
    p {
      cursor: pointer;
      display: flex;
      align-items: center;
      .anticon {
        padding-right: 10px;
      }
    }
  }
  .traveller-container {
    max-width: 1000px;
    margin: 0 auto;
  }

  .passenger {
    padding: 20px 15px;
    font-family:"Nunito";

    .passengers-header {
      display: flex;
      justify-content: space-between;
      h2 {
        font-weight: 700;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      .addTravellerBtn {
        button {
          background: linear-gradient(90deg, #09b0dd, #026ba5);
          color: #fff;
          border: none;
          font-size: 20px;
          height: 50px;
          @media (max-width: 767px) {
            font-size: 15px;
            height: 35px;
          }
        }
        .delete-btn {
          background: #fff;
          color: #bd0c21;
          border: solid 1px #bd0c21;
        }
      }
    }

    .description {
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 1.75em;
      color: #1c2b39;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    .travellers-list {
      .traveler-custom-card {
        box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
        padding: 15px;
        margin: 25px 0px;
      }
      .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h6 {
          font-size: 1rem;
          line-height: 1.75em;
          color: #1c2b39;
          font-weight: 700;
          margin-bottom: 0px;
          margin: 0px;
        }
        .actionbtn {
          .anticon {
            font-size: 18px;
            color: #bd0c21;
          }
          .anticon-delete {
            padding-left: 15px;
            color: red;
          }
        }
      }
      .ant-form {
        button {
          font-size: 16px;
          height: auto;
          font-weight: 600;
          margin-top: 18px;
          height: 33px;
        }
      }
    }
  }
}
.travellers-list .ant-form-item {
  margin-bottom: 0px;
}
.trv-env-21 {
  margin-top: 15px;
}
