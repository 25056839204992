.manage-markup-section {
  background-color: #f6f7fb;
  padding: 20px;
  .card-bt-gap {
    margin-bottom: 20px;
  }
  .card-add-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .reports-btns {
    display: flex;
    justify-content: flex-end !important;

    button {
      margin-right: 17px;
    }
  }
  .table-heading {
    text-align: center;
    p {
      margin-bottom: 0px;
    }
    span {
      color: red;
    }
    .total-amounts {
      margin-top: 20px;
    }
  }
}
// #passportModal {
// .ant-modal {
//     // width: 700px !important;
//     // transform-origin: 380px 193px !important;
//     .ant-modal-content {
//         width: 700px !important;
//     }
// }
// }

.ant-table-pagination-right {
  float: none !important;
  /* align-items: center; */
  text-align: center !important;
  padding-top: 20px;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.anticon .anticon-plus {
  color: #bd0c21 !important;
}

.ant-upload-select-picture-card i {
  color: #bd0c21;
  font-size: 32px;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #08aad8;
}

.table-scroll-none {
  ::-webkit-scrollbar {
    display: none !important;
  }
}

.gstDetails {
  .gst-content {
    display: flex;
  }
}

/* ANTD Modal custom styles */

.ant-modal-close-x {
  color: #fff;
}


.ant-modal-content {
  border-radius: 15px !important;
  padding: 0px !important;
}
.ant-modal-header {
  color: #fff;
  background: #08aad8 !important;
  padding:16px !important;
  h6 {
    color: #fff !important;
  }
}
.ant-modal-body{
  padding :16px !important;
}

.ant-modal-footer{
  padding :16px !important;
}

.ant-table-thead > tr > th {
  background: #08aad8 !important;
  color: #fff !important;
}
/* ANTD Modal custom styles */
