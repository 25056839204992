 

.ant-table-cell {
  .airlineTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    .matrixCount {
      font-size: 12px;
    }
    .fa-filter {
      margin: 0 5px;
    }
  }
}

.sort-block {
  @media (max-width: 767px) {
    display: none;
  }
  .ant-card-body {
    padding: 0;
  }

  .radio-btns-sort {
    display: flex;
    padding: 15px;
    @media (max-width: 767px) {
      padding: 0;
    }
    .sort-text {
      color: #505c75;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      margin-right: 10px;
      font-family: 'Nunito';
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  .ant-radio {
    position: absolute;
    opacity: 0;
    display: none;
    .ant-radio-input {
      &:checked + span > .active-background {
        border-bottom: 2px solid #003b95 !important;
        width: 150px;
        p {
          color: #003b95;
          margin-bottom: 0;
        }
        .price-type {
          font-weight: 600;
          font-family: 'Nunito';
        }
        .total-fare-filter {
          margin-bottom: 30px;
        }
      }
    }
  }
  .ant-radio-wrapper {
    margin-right: 30px;
    @media (max-width: 991px)  {
      margin-right: 103px;
    }
    @media (max-width: 767px) {
      margin-right: 0;
      border-right: 1px solid #ccc;
      &:last-child {
        border-right: none;
      }
    }
  }
  .ant-radio-wrapper-checked {
    border-bottom: 0px solid #003b95;
    span {
      &:nth-child(2) {
        .checkmark {
          .active-background {
            color: #003b95;
            .price-type {
              font-weight: 600;
              font-family: 'Nunito';
              span {
                margin-left: 6px;
                display: inline-block;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
  .active-background {
    @media (max-width: 767px) {
      padding: 2px !important;
    }
    .price-type {
      text-transform: capitalize;
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 600;
      padding: 0px 30px;
      font-family: 'Nunito';
      @media (max-width: 991px)  {
        padding: 0px 20px;
      }
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
    .total-fare-filter {
      margin-bottom: 10px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 9px;
        margin-bottom: 0;
        span {
          display: block;
        }
      }
    }
  }
  span.ant-radio + * {
    padding: 0;
  }
}
.airline-matrix-table {
  .ant-table {
    overflow: hidden;
  }
}
.airline-text {
  color: #003b95;
  font-weight: bold;
  cursor: pointer;
  .matrixCount {
    font-size: 10px;
  }
}
