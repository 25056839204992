.flighttic-info-d1 {
  position: relative;
  //height: 150px;
}

.tic-gredient-f {
  //background-image: linear-gradient(to bottom, #bd0c21, #35459c, white);
  width: 100%;
  position: absolute;
  top:62px;
  left: 0;
  height: 60%;
}

.tic-city-name-d2 {
  color: #fff !important;
  font-weight: bold;
  font-size: 24px;
  padding-top: 20px;
}

.tic-city-name {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  padding-top: 0px;
}

.border-hr {
  border-bottom: 1px solid #e2e2e2;
  padding: 4px 0;
}

.city-f3 p {
  margin-bottom: -7px;
  font-size: 18px;
  font-weight: bold;
}
.city-f13 p {
  margin-bottom: -7px;
  font-size: 14px;
  font-weight: bold;
}

.card-tic-f3 {
  border: 1px solid #e2e2e2;
  box-shadow: 0 1px 1px 0 rgba(19, 41, 104 , .2);
  border-radius: 6px;
}

.city-f3 span {
  color: #a8a8a8;
  font-size: 13px;
}
.city-f13 span {
  color: #a8a8a8;
  font-size: 13px;
}

.last-date-d3 {
  padding-top: 21px;
  padding-left: 13px;
}

.card-tic-f3 .ant-card-body {
  padding: 9px !important;
}

.row-top-f4 {
  padding-top: 10px;
}

.cnfrom-f3 {
  color: #26b265 !important;
}



.city-f4 {
  span {
    color: #a8a8a8 !important;
    font-size: 13px;
  }

  p {
    margin-bottom: -2px !important;
    font-size: 16px;
    font-weight: bold;

  }

}

.card-tic-f1 {
  border: 1px solid #a8a8a8;
  margin-top: 20px;
  box-shadow: 0 1px 1px 0 rgba(19, 41, 104 , .2);
  border-radius: 6px;
}

.card-tic-f1 .ant-card-body {
  padding: 9px !important;
}

.city-tt-f4 p {
  margin-bottom: -2px;
  font-size: 16px;
  font-weight: bold;
}

.city-tt-f4 span {
  color: #a8a8a8;
  font-size: 13px;
}

.logo-f3-text {
  display: flex;
}

.sg-logo-flight {
  display: block;
  padding-left: 10px;
}

.info-flight-t22 {
  padding-bottom: 10px;
}

.city-tt-f5 p {
  margin-bottom: -4px;
  font-size: 14px;

  color: #000;
}

.city-tt-f5 span {
  color: #a8a8a8;
  font-size: 14px;
}

.city-from-d3 {
  display: flex;
  justify-content: start;
}

.city-from-d33 {
  display: flex;
  justify-content: start;
}

.city-f55 p {
  margin-bottom: -7px;
  font-size: 15px;
  font-weight: bold;
  padding: 0px 5px 0px 5px;
}

.card-tic-f55 {
  border: 1px solid #dedede;
}

.city-f55 span {
  color: #7684d1;
  font-size: 13px;
}

.dotted-line-flight {
  border: 1px solid #a8a8a8;
  border-style: dotted;
  width: 194px;
  margin-top: 14px;
  margin-bottom: 8px !important;
}

.city-f55 {
  text-align: center;
  
}

.baggagge-f4 {
  display: flex;
}

.baggagge-icon-4 .fa {
  text-align: center;
  line-height: 28px;
  font-size: 20px;
}

.info-line-bag {
  padding-left: 10px;
}

.baggagge-bottom-sec {
  padding-top: 25px;
}

.allowance-baggage {
  padding-left: 10px;
}

.baggagge-icon-5 .fa {
  font-size: 20px;
}

.allowance-baggage span,
.bus-ticket-info-type span {
  font-size: 16px;
  font-weight: 100;
}

.city-tt-f6 p {
  margin-bottom: -4px;
  font-size: 14px;
  color: #000;
}

.baggagge-cacel {
  display: flex;
}

.baggagge-f5 {
  display: flex;
  justify-content: end;
}

.baggagge-f5 p {
  color: #ea1b37;
}

.policy-city {
  color: #000 !important;
  margin-bottom: 3px;
}

.allowance-baggage-1 span {
  color: #a8a8a8;
  font-size: 13px;
}

.baggagge-f43 span {
  color: #a8a8a8;
  font-size: 13px;
}

.card-tic-f2 {
  border: 1px solid #a8a8a8;
  margin-top: 20px;
  box-shadow: 0 1px 1px 0 rgba(19, 41, 104 , .2);
  border-radius: 6px;

  .pax-add-ssr {
    border-bottom: 1px dotted #d2d2d2;
  }

}

.card-tic-f2 .ant-card-body {
  padding: 9px !important;
}

// Media query for print styles
// @media print {


//   .details-airlines-contact {
//     display: flex;
//     flex-wrap: wrap;
//   }

//   // .contact-person-d4 {
//   //   flex: 0 0 calc(50% - 8px);
//   //   margin-bottom: 8px;
//   // }
// }
@media print {
  body {
    margin: 0;
    padding: 0;
  }
  .print-container {
    width: 100%;
    text-align: center; /* Example for alignment */
  }
  .no-print {
    display: none; /* Hides unnecessary elements */
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
}

// .card-tic-f2 {
//   border: 1px solid #a8a8a8;
//   margin-top: 20px;
//   box-shadow: 0 1px 1px 0 rgb(19, 41, 104 / 20);
//   border-radius: 6px;

//   .pax-add-ssr {
//     border-bottom: 1px dotted #d2d2d2;
//   }
// }

// .card-tic-f2 .ant-card-body {
//   padding: 9px !important;
// }

.contact-person-d4 p {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: bold;
}

.contact-person-d4 {

  .r-meals {

    // display: flex;
    .meals-sel {
      .meals-col {
        .meals-city {
          font-size: 12px;

          .pax-ssr-txt {
            color: #5b5b5b;
          }
        }
      }
    }
  }

  .pax-ssr {
    color: #333;
  }

  .s-meals {
    color: #5e5e5e;
   // border-bottom: 1px solid #d3d3d3;
    max-width: max-content;
  }

  span {
    color: #a8a8a8;
  }
}

.details-airlines-contact {
  margin-bottom: 10px;
}

.modify-contact-btn {
  text-align: end;
}

.tic-btn-contact2 {
  background: #ea1b37 !important;
  text-align: center;

  color: #fff;
  font-size: 14px;
  outline: 0;
  border: 0;
  font-weight: 600;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .city-from-d3 {
    display: block;
  }

  .dotted-line-flight {
    width: 100%;
    margin-top: 5px;
  }
}

.booking-flight-details-info {
  border: 0;

  .termCon_wrapper {
    padding: 0 10px;
    text-align: justify;

    p {
      font-size: 14px;
      margin-bottom: 2px;
    }

    .description {
      margin-left: 10px;

      .title {
        font-weight: 800;
        font-size: 14px !important;
      }

      p {
        font-size: 13px !important;
      }
    }

    .cancellation-wrapper {
      margin: 0 auto;
      width: 100%;

      .policytitle {
        text-align: center;
        font-weight: 800;
      }

      .cancellation-block-body {
        li {
          span {
            font-size: 14px;
            color: #333;
            font-weight: 600;
          }
        }
      }
    }
  }

  .farerules {
    div {
      font-size: 14px;
    }
  }
}

.trip-type-selector {
  display: grid;

  .ant-btn {
    width: 100%;
  }

  .invoice-btn {
    margin-top: 5px;
  }
}

.break-pnr {
  margin-bottom: 2px !important;
}

.car-data-mo {
  .table-container {
    overflow-x: auto;
  }

  .my-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .my-table th,
  .my-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .my-table th {
    background-color: #f2f2f2;
  }
}

.cms-pages-width-hot {
  background: #fff !important;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 3px 4px 8px grey;
  border-radius: 3px;
}
.tickkkket{
  .ant-card{
    background: white !important;
  }
}
.tic-city-name-d21 {
  color: #000000 !important;
  font-weight: bold;
  font-size: 24px;
  padding-top: 20px;
}