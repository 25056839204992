

.About-us-page {
    margin: 0 auto;
    width: 80%;
    padding-top: 130px;
    padding-bottom: 30px;

    .hdr {
        display: flex;
        justify-content: center;
        // padding-top: 60px;
        // background: #003b95;

        h2 {
            color: #003b95;
            border-bottom: 2px solid #003b95;
            font-weight: 600;
            font-size: 2.5em;
        }
    }

    @media (max-width: 767px) {
        width: 100%;
    }
}

.container-box {
    max-width: 100%;
    padding: 1em;
    display: flex;

    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        padding: 0;
        display: block;
    }
}

.textContainer {
    flex: "0 0 50%";
    padding: "20px";
}

.image {
    flex: "0 0 50%";
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.hedng-txt {
    border-left: 4px solid #003b95;
    background: linear-gradient(79deg, #003b9563, transparent);
    max-width: max-content;
    color: #003b95;
    font-family: nunito;
    padding: 0 20px;
    font-weight: 600;
    // font-size: 24px;
}

.paragraph {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    font-family: "nunito";
}

.wrapper-part {
    width: 100%;
    margin: 0 auto;
    height: 400px;
    background-color: linear-gradient(to right, #320063, #0f76bb);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}

.content {
    max-width: 1024px;
    width: 100%;
    padding: 0 4%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading1 {
    font-size: 4em;
    color: white;
    font-family: playfair display;
}

.container1 {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.heading2 {
    font-family: playfair display;
    font-size: 2.5em;
    font-weight: 600;
    color: #bd0c20;
}

.cont {
    display: flex;

    // align-items: center;
    @media (max-width: 767px) {
        display: contents;
    }
}

.imagestyle {
    width: 35%;
    height: 35%;
    padding-right: 1em;
    padding-top: 8px;

    // box-shadow: 2px 3px 2px rgb(198, 198, 198);
    @media (max-width: 767px) {
        width: 100%;
    }
}

.para {
    font-size: '1em';
    line-height: '1.5';
}

.container-contact {
    margin-top: 10px;
    text-align: center;
}

.playfair {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 3em;
    color: #003b95;
}

.contct-abt-pg {
    background: #e3f8fe;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 1px 2px 12px lightgray;

    @media (max-width: 767px) {
        display: contents;
    }
}

.sub-cont {
    flex: 1;
    margin: 0;

    @media (max-width: 767px) {
        margin: 2%;
    }
}

.img-1 {
    flex: 1;
    box-shadow: 1px 6px 20px 9px #ccc;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btn-primary {
    background: #003b95 !important;
    opacity: 1 !important;
    padding: 10px 18px 10px 18px !important;
    box-shadow: none !important;
    border-width: 0px !important;
    border-style: solid !important;
    border-color: #000000 !important;
    border-color: RGBA(0, 0, 0, 1) !important;
    border-radius: 6px !important;
    color: #ffff;
}

// a {
//     color: #003b95;
// }

.footer-container {
    padding: 20px 0;
}

.feature {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    width: 30px;
    margin-right: 10px;
}

.hdr-img {
    img {

        @media (max-width: 767px) {
            width: 100%;
            padding-top: 70px;
        }
    }
}

.company-info-container {
    max-width: 100%;
    // margin: 40px auto;
    padding: 20px;
    background: linear-gradient(to right, #f0f4f8, #e8eff5);
    // background: url(../../assets/images/abt-us.jpg) no-repeat 80%;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: "Nunito", sans-serif;
    color: #333;


    .company-title {
        font-size: 2rem;
        font-weight: bold;
        color: #004080;
        text-align: center;
    }

    .subtitle {
        text-align: center;
        font-size: 1.2rem;
        margin-bottom: 20px;
        color: #555;
    }

    .info-container {
        display: flex;
        justify-content: space-around;

        .info-sec-img {
            width: 40%;
        }

        .info-sec {
            width: 65%;

            .info-section {
                margin-bottom: 8px;
                border: 1px solid #ddd;
                border-radius: 6px;
                overflow: hidden;

                .section-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: linear-gradient(0deg, #003b9570, #9fc9fb52);
                    color: #fff;
                    padding: 4px 12px;
                    cursor: pointer;

                }

                .section-header:hover {
                    background: linear-gradient(90deg, #003b9570, #9fc9fb52);

                    h2 {
                        color: #fff;
                    }
                }

                .section-content {
                    padding: 15px;
                    font-size: 1rem;
                    background: #fff;
                    color: #333;
                    line-height: 1.6;
                    transition: all 0.3s ease-in-out;
                    margin: 0;
                }
            }
        }

    }



}

.anim {
    width: 100%;
}

.abts-cont {


    // max-width: 80%;


    background: #fff;
    overflow: hidden;
    width: 100%;
    position: relative;



    .abots-container {
        max-width: 80%;
        margin: 40px auto;
        padding: 20px;
        background: linear-gradient(to right, #f0f9ff, #eaf4fb);
        border-radius: 12px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        font-family: "Nunito",
            sans-serif;
        color: #333;
        position: relative;
        z-index: 9;
    }


    .about-title {
        font-size: 1.8rem;
        font-weight: bold;
        color: #004080;
        text-align: center;
        margin-bottom: 20px;
    }

    .why-choose-list {
        list-style: none;
        padding: 0;
        margin: 0 0 20px;
    }

    .why-choose-list li {
        font-size: 1rem;
        margin: 10px 0;
        padding-left: 20px;
        position: relative;
    }

    .why-choose-list li::before {
        content: "✔";
        position: absolute;
        left: 0;
        top: 0;
        color: #0078d4;
        font-size: 1.2rem;
    }

    .vision-text {
        font-size: 1.1rem;
        line-height: 1.6;
        margin: 20px 0;
        color: #444;
        text-align: center;
    }

    .cta-text {
        font-size: 1.2rem;
        font-weight: bold;
        color: #004080;
        text-align: center;
        margin-top: 20px;
    }
}

.more-abt {
    // background: linear-gradient(135deg, #f0f4ff, #dbeafe);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Nunito', sans-serif;
    margin: 20px auto;
    max-width: 100%;

    .about-title {
        font-size: 2.5rem;
        color: #003366;
        text-align: center;
        margin-bottom: 20px;
        font-weight: 700;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    }

    .why-choose-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .why-choose-list li {
        margin-bottom: 20px;
        padding: 15px;
        background: #ffffff;
        border: 1px solid #e0e7ff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        display: flex;

        // justify-content: space-evenly;
        p {
            margin: 0;
            padding-left: 20px;
            margin-top: 8px;
        }
    }

    .why-choose-list li:hover {
        transform: scale(1.03);
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
        background: linear-gradient(135deg, #f0f4ff, #dbeafe);
    }

    .why-choose-list li strong {
        display: block;
        font-size: 1.2rem;
        color: #1d4ed8;
        margin-bottom: 5px;
    }

    .why-choose-list li:hover strong {
        color: #003366;
    }

    .why-choose-list li span {
        font-size: 1rem;
        color: #4b5563;
    }

    @media (max-width: 768px) {
        .more-abt {
            padding: 20px;
        }

        .about-title {
            font-size: 2rem;
        }

        .why-choose-list li {
            padding: 10px;
        }
    }
}