/* Datepicker Custom styles */
.modifySelect {
  @media (max-width: 767px) {
    margin: 10px 0;
  }
}

.react-datepicker-component {
  .react-datepicker-input {
    input {
      background: #fff !important;
      padding-left: 38px !important;
      padding-right: 0 !important;
    }

    .button-wrapper {
      position: absolute !important;
      left: 0 !important;
      right: initial !important;

      i {
        color: #bd0c21;
      }
    }
  }

  .react-datepicker-input.has-value {
    input {
      font-weight: 100 !important;
      color: #505c75 !important;
    }
  }
}

/* End of Datepicker Custom styles*/

.flight_search_box {
  input {
    border-color: #bcd5f5;
  }

  background: #fff;
  padding: 20px 20px 15px 20px;
  border-radius: 5px;
  margin-top: 10px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  .flight-header {
    margin-bottom: 4px;

    label {
      display: flex;
      align-items: flex-end;
      font-size: 14px;

      &:active {
        color: #1c2b39;
        font-weight: 700;
      }
    }

    .flight-class {
      label {
        font-size: 12px;
        margin-bottom: 7px;
      }

      .col {
        padding-left: 0;

        .custom-select {
          border: transparent;
          font-size: 12px;
          font-weight: 700;
          line-height: 19px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .flight-body {
    .custom-column {
      margin-bottom: 0.5rem;
      position: relative;

      label {
        font-size: 11px;
        font-weight: 700;
        color: #1c2b39;
        margin-bottom: 0;
      }

      .flight-search-btn {
        background-color: #bd0c21;
        color: #fff;
        border-color: transparent;

        // &:hover {
        //   background-color: #eb5196;
        // }
      }

      .city-increment {
        position: absolute;
        right: -20px;
        top: 50%;
      }
    }

    .return-date-col {
      .react-datepicker-component {
        .react-datepicker-input.has-value {
          &::after {
            content: "\f00d";
            font-family: "Nunito";
            right: 10px;
            position: absolute;
            top: 0px;
            border-left: 1px solid #bcd5f5;
            padding-left: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          // &:hover {
          //   color: red !important;
          // }
        }
      }
    }
  }


  .multi-city-wrapper {
    .city-count {
      width: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .place-box {
      width: 36% !important;
      padding-right: 10px;
      padding-left: 10px;
    }

    .departure-date-box {
      width: 23%;
    }

    .city-increment {
      margin-left: 10px !important;
      align-self: center;
    }
  }
}

.plus-icon {
  font-size: 25px;
  padding-left: 30px;
}

.flight-search {
  .ant-form-item-label {
    padding-bottom: 0;

    label {
      font-size: 11px;
      font-weight: 700;
      color: #1c2b39;
      height: 20px;
      display: none;
    }
  }

  .originwrapper {
    position: relative;
    margin-top: -7px;

    @media (max-width: 767px) {
      margin-bottom: 27px;

    }

    .arrow-flight-inr {
      position: absolute;
      bottom: 0;
      top: 30%;
      right: -12px;
      z-index: 20;
      transition: all 0.5s ease 0s;
      cursor: pointer;

      @media (max-width: 767px) {
        bottom: 0;
        top: 80%;
        right: 15px;
        transform: rotate(90deg);

      }
    }

    .arrow-flight-inr:hover {
      transform: rotate(360deg);

      @media (max-width: 767px) {
        transform: rotate(450deg);
      }
    }
  }
}

.flight-passenger {
  .pax-modal {
    position: absolute;
    z-index: 4;
    left: -101px;
    background: #fff;
    width: 280px;
    height: 351px;
    border: 1px solid #bcd5f5 !important;
    border-radius: 4px;
    box-shadow: 1px 4px 8px lightgray;

    @media (max-width: 767px) {
      left: 0;
    }

    .pax-modal-wrapper {
      padding: 10px 20px;
      border: none;
      // border-radius:4px ;
      // box-shadow: 1px 4px 8px lightgray;

      @media (max-width: 767px) {
        padding: 10px;
      }

      li {
        display: flex;
        list-style-type: none;
        border-bottom: 1px solid #dcdee3;
        margin-bottom: 2px;
        margin-top: 8px;
        padding-bottom: 8px;

        &:last-child {
          border-bottom-color: transparent;
        }

        .pax-label {
          // flex-grow: 1;
          // text-align: start;

          p {
            margin-bottom: -6px;
            font-size: 16px;
            font-weight: bold;
            color: #1c2b39;
          }

          span {
            font-size: 12px;
            font-weight: 400;
            color: #969dac;
          }
        }

        .pax-count {
          flex-grow: 1;
          align-self: baseline;
          display: flex;
          justify-content: right;
          align-self: center;

          .fa {
            //background: #bd0c21;
            padding: 4px;

            color: #040404;
            line-height: 1;
            vertical-align: middle;

          }

          .fa-minus {
            // border-top-left-radius: 4px;
            // border-bottom-left-radius: 4px;
            border-radius: 10px;
          }

          .fa-plus {
            // border-top-right-radius: 4px;
            // border-bottom-right-radius: 4px;
            border-radius: 10px;
          }
        }
      }

      .pax-ready-btn {
        border-radius: 3px;
        background: #ff5145;
        padding: 6px;
        color: #fefefe;
        font-size: 11px;
        font-weight: 700;
        border: 2px solid #ff5145;
        margin-top: 12px;
        margin-left: -16px;

        &:hover {

          color: #ffffff;
        }

        @media (max-width: 767px) {
          margin: 12px 0 5px 0;
        }
      }

      .cab-hdr {
        // border: 1px solid grey;
        color: #fff;
        border-radius: 12px;
        padding: 2px 16px;
        margin-top: -10px;
        box-shadow: 2px 2px 10px #808080b0;
        background: radial-gradient(circle, #5aa0f3 0%, #053f97 100%);
        display: flex;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
      }
    }
  }
}

.primary-btn {
  cursor: pointer;
  // background: linear-gradient(90deg, #09b0dd, #026ba5) !important;
  background: linear-gradient(90deg, #ff7b54, #ff3e3e) !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 20px;
  transition: smooth;
  outline: 0;
  border: 0;
  font-weight: 600;
  border: 1px solid #f6f9fc !important;

  // border-radius: 40px !important;
  &:hover {
    background: linear-gradient(90deg, #ff7b54, #ff3e3e) !important;
    color: white;
  }

  &:focus {
    background: linear-gradient(90deg, #09b0dd, #026ba5) !important;

  }

  @media (max-width: 991px) {
    margin-left: -2px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
}

.from-to-inputs {
  .ant-row {
    flex-direction: column;
    align-items: flex-start;

    .ant-form-item-control {
      width: 100%;
    }
  }
}

.mr-none {
  margin-right: 0 !important;

  .mr-t {
    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }
}

.class-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .select_wrapper {
    padding: 0 5px;
  }

  .class-type {
    font-size: 11px;
    margin-right: 7px;

    @media (max-width: 767px) {
      font-weight: 700;
    }

    .ant-radio-wrapper {
      color: #ffffff;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .ant-select-selector {
    border: none !important;
    font-size: 12px;
    font-weight: 700;
  }
}

/* .hide-icon-heading-two {
  display: none;
}
 */

/*-----flight--search--css-13-05--*/
.flight-bg-side {
  @media (max-width: 767px) {
    margin-left: 2%;
  }

  .ant-form-item {
    margin-bottom: 0px !important;

    .ant-select-lg {
      border-right: 0px solid #d6d3d3;

      @media (max-width: 767px) {
        border-right: 0px;
        border-bottom: 1px solid #d6d3d3;
      }

      .ant-select-arrow {
        display: none;
      }

      .ant-select-selector {
        height: 80px !important;
        padding: 14px 11px;
        border-color: transparent !important;
        border: 0px solid !important;
        border-right-width: 0px !important;
        // border-top-left-radius: 40px !important;
        // border-bottom-left-radius: 40px !important;
        color: #bfbfbf;
        border: 1px solid #dddddd !important;
        border-radius: 8px;



        .ant-select-selection-search-input {
          height: 80px !important;
          color: #6b6b6b !important;
          font-size: 18px;
          font-weight: 600;
        }

        .ant-select-selection-placeholder {
          border: 0;
          background: none;
          font-weight: 600;
          font-size: 15px;
          outline: medium none;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000000;
          margin-top: 16px;
        }

        .ant-select-selection-item {
          border: 0;
          background: none;
          font-weight: 600;
          font-size: 15px;
          outline: medium none;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000;
          // margin-top: 9px;

        }
      }
    }

    .ant-picker-large {
      padding: 11px 6px 6.5px !important;
      height: 80px;
      border: none;
      border-right: 0px solid #d6d3d3 !important;
      border-radius: 0px;
      color: #bfbfbf;
      border: 1px solid #dddddd !important;
      border-radius: 8px;


      @media (max-width: 767px) {
        border-right: 0px;
        border-bottom: 1px solid #d6d3d3;
        margin-bottom: 12px;
      }

      .ant-picker-input {
        input {
          color: #320063;
          font-weight: bold;
          font-size: 26px !important;
          margin-top: 0px;
          font-family: 'Nunito';
        }
      }

      .ant-picker-input input::placeholder {
        color: black;
      }
    }

    .ant-input-lg {
      height: 80px;
      padding: 11px 11px;
      font-size: 18px;
      font-weight: bold;
      border: none;
      border-radius: 0px;
      color: #000;
      border: 1px solid #dddddd !important;
      border-radius: 8px;

      .ant-picker-input input::placeholder {
        margin: 0px;
      }

    }

    .ant-form-item-explain.ant-form-item-explain-error {
      color: #e0001a !important;
      font-weight: 500;
      // text-shadow: 2px 1px 2px grey;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .destination {
    @media (max-width: 767px) {
      margin-bottom: 27px;

    }

    .ant-form-item {
      .ant-select-lg {
        .ant-select-selector {
          padding-left: 16px;

          @media (max-width: 767px) {
            padding-left: 11px;
          }

          .ant-select-selection-search {
            padding-left: 5px;

            @media (max-width: 767px) {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .originwrapper {
    .ant-form-item {
      .ant-select-lg {
        border-radius: 2px 0 0 2px;
      }
    }
  }

  .ant-btn {
    //  width: 100%;
    height: 55px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 409px;
    position: absolute;
    margin-top: 10px;
    width: 270px;

    @media (max-width: 1200px) {

      margin-left: 345px;
    }
    @media (max-width: 1024px)  {
      
      margin-left: 0px;
    }


    @media (max-width: 991px)  {
      // width: 40% !important;
      // margin-left: 226px;
      width:148px !important;
    }

    @media (max-width: 767px) {
      border-radius: 2px;
      width: 100% !important;
      margin-left: 2px;
    }
  }

  // .searchbut{
  //   margin-left: 409px;
  // }
}

.pax-count-info {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 66px;
}

.f-primary {
  margin-top: 0px !important;
  width: 100%;
  height: 66px;
}

.bdr-icon-f {
  // border-radius: 20px;
  padding-left: 4px;
  color: #000000;
}

.tripTypeActive {
  // background-color: #bd0c21;
  color: #080808;
  padding: 3px;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: 800;
  // border-radius: 8px;
  // border: 1px solid #35459c;
}

.normalhighlight {
  //background: #ffffff75;
  color: #000000;
  padding: 3px;
  // border: 1px solid #35459c;
  // border-radius: 8px;



  // background: #dce1ff;
}

.tripTypeActive1 {
  background-color: #596ac7;
  color: #000;
  border-radius: 8px;
  padding: 4px 5px;
  // margin-bottom: 2%;
}

.normalhighlight1 {
  background-color: #bd0c21;
  color: #f3f3f3;
  border-radius: 8px;
  padding: 4px 5px;
  // margin-bottom: 2%;
}

.returnDate {

  .ant-picker.ant-picker-disabled,
  .ant-picker-input>input[disabled] {
    cursor: pointer !important;
  }
}

// .flight-bg-side .ant-radio {
//   display: none !important;
// }
.primary-btn:hover {
  background: linear-gradient(90deg, #ff7b54, #ff3e3e) !important;
  // background: linear-gradient(90deg, #09b0dd, #026ba5)!important;
  color: white !important;
}

.lowest-ne-p {
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  font-family: "Nunito";
  text-align: center;
  cursor: pointer;
  text-shadow: 2px 3px 2px grey;

  @media (max-width: 767px) {
    font-size: 16px !important;
  }
}

.lowest-ne-p1 {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  font-family: "Nunito";
  text-align: center;
  margin-bottom: 2%;

  @media (max-width: 767px) {
    font-size: 16px !important;
  }
}

/*20/09/2021*/
.agent-tt-btn {
  border: 1px solid #c3c1c1;
}

.second-agent-plan {
  padding-top: 25px;
}

.button-default-v3 {
  position: absolute;
  right: 28px;
}

.train-search-btn .ant-picker-input input::placeholder {
  color: black;
}

.custom-mob-calendar {
  z-index: 999999999;

  .custom-mob-calendar-title {
    display: none;
  }

  @media (max-width: 767px) {
    height: 100%;
    width: 98%;

    margin: 0 auto;
    right: 0px !important;

    left: 0px !important;
    top: 0px !important;

    .custom-mob-calendar-title {
      display: block;
    }

    .ant-picker-panel-container {
      width: 100%;

      .ant-picker-panel {
        width: 85%;
        margin: 0 auto;
        display: block;
      }

      .ant-picker-date-panel,
      .ant-picker-content {
        width: 100%;
      }
    }
  }
}

// .flight-bg-target {
//   background-color: #fff !important;
//   border-radius: 20px;
//   padding-bottom: 47px !important;
//   padding: 10px 10px;
//   box-shadow: 0 1px 2px rgb(0, 0, 0 / 8%);
// }
// .flight-bg-target {
//   // padding-top: 40px;
// }

.outer-div-flight-searchfields {
  // border: 2px solid red;
  // background-color: white;
  // border-radius: 55px;
  padding: 1%;
  // margin-top: 17px;
  // margin-top: 1%;
  // box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, .2);

  // overflow: hidden;
  @media (max-width: 767px) {
    border-radius: 5px;
  }

  .trav-class {
    border: 1px solid #dddddd;
    border-radius: 8px;
    height: 80px;
    background: white;
  }
}

.outer-div-flight-searchfieldscar {
  // border: 2px solid red;
  background-color: white;
  // border-radius: 55px;
  padding: 1%;
  // box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, .2);
  // padding-top: 9%;
  // margin-top: -9%;

  // overflow: hidden;
  @media (max-width: 767px) {
    border-radius: 5px;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 2px rgb(255, 255, 255) !important;
  border-bottom: 1px solid #006bb1;
}

.ant-picker-focused {
  box-shadow: 0 0 0 2px rgb(255, 255, 255) !important;
  border-bottom: 1px solid #006bb1;
}

.row-multi-main-div {
  @media (max-width: 767px) {
    width: 100%;
  }
}

.autosea-multicity-align {
  // border: 2px solid red;
  width: 32%;
  margin-top: -7px;
  margin-bottom: 25px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.autosea-multicity-align-lines {
  @media (max-width: 767px) {
    padding-top: 2%;
    border-top: 1px solid rgb(161, 161, 161);
  }
}

.date-multicity-align {
  @media (max-width: 767px) {
    width: 100%;
  }
}

.date-multicity-align-line {
  margin-right: 1%;
  margin-top: -7px;

  @media (max-width: 767px) {
    padding-bottom: 2%;
    margin-right: 0%;
  }
}

.fly-to-multi-city-age {
  margin-left: 1%;
  margin-right: 1%;

  @media (max-width: 767px) {
    margin-left: 0%;
    margin-right: 0%;
  }
}

.mapmap-mapm-map {
  // border: 2px solid red;
  position: absolute;
  top: 35%;
  left: 86%;
  font-size: 20px;
  color: #5969c7;
}

.mapmap-mapm-map1 {
  // border: 2px solid red;
  position: absolute;
  top: 45%;
  left: 85%;
  font-size: 20px;
  color: #5969c7;
}


.ant-picker-suffix {
  display: none;
  color: #5969c7;
  font-size: 20px;
  margin-top: -3%;
  margin-left: 0%;

  @media (max-width: 767px) {
    margin-top: 0%;
  }
}

.originwrapper .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  .m-0 {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}

.destination .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  .m-0 {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}

.autosea-multicity-align .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  .m-0 {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}

.agent-flight-wrapper .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  .m-0 {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}

.bus-search-wrapper .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  .m-0 {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}


.agent-flight-wrapper .ant-select-focused {
  position: absolute;
  margin-top: -6.5%;

  @media (max-width: 991px) {
    margin-top: -8.5%;
  }

  @media (max-width: 767px) {
    position: relative;
    margin-top: 0%;
  }
}


.originwrapper .ant-select-focused {
  position: absolute;
  margin-top: -6%;

  @media (max-width: 991px) {
    margin-top: -13%;
  }

  @media (max-width: 767px) {
    position: relative;
    margin-top: 0%;
  }
}

.destination .ant-select-focused {
  position: absolute;
  margin-top: -6%;

  @media (max-width: 991px) {
    margin-top: -13%;
  }

  @media (max-width: 767px) {
    position: relative;
    margin-top: 0%;
  }
}

.autosea-multicity-align .ant-select-focused {
  position: absolute;
  margin-top: -6%;

  @media (max-width: 991px) {
    margin-top: -10%;
  }

  @media (max-width: 767px) {
    position: relative;
    margin-top: 0%;
  }
}

.input-names {
  position: absolute;
  margin-top: 0px;
  z-index: 21;
  margin-left: 11px;
  font-weight: 500;
  color: grey;
  // background: white;
}


.Spc-fare-box {
  display: flex;
  @media (max-width: 480px) {
    display: none !important;
  }
}

.cabinclassss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: self-start;
  align-items: start;
  margin-right: 47px;
}

.countfor {
  margin-right: 12px;
}

.searchbut {
  margin-left: 409px;
}

.left-minus {
  border: 1px solid grey;
  width: 26px;
  height: 27px;
  text-align: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  &:hover {
    background-color: rgb(231, 249, 255);
  }
}

.right-plus {
  border: 1px solid grey;
  width: 26px;
  height: 27px;
  text-align: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 1px 0px 6px grey;

  &:hover {
    background-color: rgb(231, 249, 255);

  }

  &:active {
    box-shadow: none;
  }
}

.sarchbtn {
  height: 81px !important;
  border-radius: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  // margin-left: 409px;
  position: absolute !important;
  margin-top: 0px !important;
  width: 170px !important;

  &:active {
    background: rgb(255, 51, 10);
  }
}

.radiobtn {
  width: 170px;
  height: 35px;
  font-weight: 700;
  font-family: 'Nunito';
  border: 1px solid #dddddd;
  padding: 6px;
  border-radius: 8px;
  // box-shadow: 3px 0px 5px 0 #5d6ec6;
  background: #fff;

  &:hover {
    border: 1px solid rgb(84, 84, 84);
    background: rgb(245, 251, 255);
  }

}

.tripTypeA {
  color: rgb(214, 122, 9);
  background-color: rgb(255, 229, 143);
  border-color: rgb(227, 114, 34);
}

.date-day {
  font-size: 2em;
  /* Increase the font size for the day */
  font-weight: bold;
}

.custom-mob-calendar .ant-picker-cell-inner .day {
  font-size: 24px;
  /* Larger font size for the day */
  font-weight: bold;
  /* Optional: make it bold */
}

.custom-date-display {
  display: flex;
  align-items: center;
}

.large-day {
  font-size: 26px;
  /* Larger text for day */
  font-weight: bold;
  margin-right: 8px;
  color: #333;
}

.small-month-year {
  font-size: 12px;
  /* Smaller text for month and year */
  color: #9B9B9B;
}

.day-of-week {
  font-size: 12px;
  color: #9B9B9B;
  margin-top: 2px;
}

// .custom-input-wrapper {
//   position: relative;
//   width: 100%;
// }

.hidden-input {
  visibility: hidden;
  /* The input will be invisible but still occupy space */
}

.custom-display-value {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #000;
  display: flex;
  flex-direction: column;
  // border: 1px solid #dddddd;
}

.travellers-count {
  font-size: 20px;
  /* Bigger text for the number of travellers */
  font-weight: bold;
  margin-top: 8px;
  font-family: 'Nunito';
}

.big-number {
  font-size: 28px;
  /* Very large font for the number */
}

.cabin-class {
  font-size: 14px;
  /* Smaller text for the cabin class */
  color: #666;
  font-weight: 700;
  font-family: 'Nunito';
  margin-top: -4px;
}

.tripTypeAs {
  color: rgb(34, 118, 227);
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: 700;
}

.arrow-bus-inr {
  position: absolute;
  bottom: 0;
  top: 30%;
  right: -10px;
  z-index: 20;
  transition: all 0.5s ease 0s;
  cursor: pointer;

  @media (max-width: 767px) {
    top: 85%;
  }
}

.arrow-bus-inr:hover {
  transform: rotate(360deg);
}

//miui

.css-10o2lyd-MuiStack-root>.MuiTextField-root,
.css-10o2lyd-MuiStack-root>.MuiPickersTextField-root {
  min-width: 100% !important;
  margin-top: -10x;
}

.css-10o2lyd-MuiStack-root {
  padding-top: 5px !important;
}

.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 24px 0 16px !important;
}

.css-oeq6yz-MuiButtonBase-root-MuiIconButton-root {
  padding: 20px 10px 0px !important;
}

.css-1ll44ll-MuiOutlinedInput-notchedOutline {
  top: -17px !important;

  .css-w4cd9x {
    border: none;
    display: block;
    width: 0 !important;
    margin-bottom: 24px;
    padding: 0;
    color: rgb(222 30 30 / 45%);
    font-size: 16px;
    line-height: inherit;
    border: 0;

  }
}