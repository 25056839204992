.mytrips-new {
  padding: 20px 16px;
  margin: 32px auto;
}
.trip-advice {
  color: #05004e;
  font-size: 30px;
  line-height: 48px;
  font-weight: 700;
}
.booking-email-p {
  font-size: 16px;
  line-height: 20px;
  color: #425486;
  letter-spacing: 0;
  margin: 0;
}
.retrieve-booking {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
}
.booking-value-id {
  margin-top: 25px;
}
.booking-value-id label {
  line-height: 24px;
  color: rgb(66, 84, 134);
  font-size: 14px;
  font-weight: 400;
}
.contacts-value-de-retrive {
  width: 96%;
  color: #425486 !important;
  background-color: #f1f2f6 !important;
  border-radius: 8px !important;
  border: 1px solid transparent !important;
  height: 48px;

  padding: 0px 16px;
  font-size: 16px;
  line-height: 24px;
  transition: all 300ms ease 0s;
  .ant-select-selector {
    background-color: #f1f2f6;
    border: 0;
    .ant-select-selection-item {
      align-items: center;
      display: flex;
    }
  }
}
.email-from-book {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 17%;
  height: 180px;
  width: 180px;
}
.fst-book {
  margin-top: 21px;
}
.retrieve-linebar {
 // border-top: 1px solid #eee;
  margin-top: 4%;
}
@media screen and (max-width: 768px) {
  .contacts-value-de-retrive {
    width: 100%;
  }
}

.google-btn-booking {
  width: 100%;
  display: flex;
  text-align: center;
  line-height: 20px;
  padding: 0px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  position: relative;
  overflow: hidden;
  z-index: 2;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  transition: background-color 0.2s linear 0s;
  color: #425486;
  background-color: transparent;
  border: 1px solid #a1a9c3;
  height: 48px;
  font-size: 14px;
  background-color: #bd0c21;
  color: white;
  border-color: #bd0c21;
}
.add-booking-bd {
  border-top: 1px solid #eee;
}
.agent-v3-ll h5 {
  font-size: 18px;
  color: #bd0c21;
}
