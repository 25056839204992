

.flight-filters {
  // .flight-result-indicator {
  //   p {
  //     font-weight: bold;
  //     color: #797474;
  //     margin-bottom: 10px;
  //     padding-bottom: 2px;
  //     border-bottom: 1px solid #d9d9d9;
  //   }

  //   .onw-brn-fil {
  //     color: white;
  //     margin: 5px;
  //     border-radius: 10px;
  //     background: rgb(120, 254, 129);
  //     width: 100px;
  //   }

  //   .ret-brn-fil {
  //     color: white;
  //     margin: 5px;
  //     border-radius: 10px;
  //     background: rgb(120, 254, 129);
  //     width: 100px;
  //   }
  // }
  .flight-result-indicator {
    p {
      font-weight: bold;
      color: #797474;
      margin-bottom: 10px;
      padding-bottom: 2px;
      border-bottom: 1px solid #d9d9d9;
    }

    .onw-brn-fil,
    .ret-brn-fil {
      color: white;
      margin: 5px;
      border-radius: 10px;
      //background: linear-gradient(to right, #0370a9, #08acda);
      background: linear-gradient(90deg, #ff7b54, #ff3e3e);
      width: 100px;
      padding: 1px 10px;
      cursor: pointer;
      border: none;
      height: 28px;
      transition: background 0.3s ease, transform 0.2s ease;
    }

    /* Hover effect - keeping it minimal */
    .onw-brn-fil:hover,
    .ret-brn-fil:hover {
      background: linear-gradient(to right, #0370a9, #08acda);
      /* Slightly darker gradient */
      box-shadow: 1px 2px 8px grey;
    }

    /* Click (active) effect */
    .onw-brn-fil:active,
    .ret-brn-fil:active {
      background: linear-gradient(to right, #0370a9, #08acda);
      /* Lighter on active */
      transform: translateY(2px);
      /* Button shifts down subtly on click */
    }

    /* Selected effect (with a slight glowing border) */
    .onw-brn-fil.selected,
    .ret-brn-fil.selected {
      background: linear-gradient(to right, #0370a9, #08acda);
      border: 2px solid rgba(34, 139, 49, 0.6);
      box-shadow: 0 0 5px rgba(34, 139, 49, 0.3);
      color: black;
    }
  }


  .overall-filter-container {
    .overall-filter-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0px;
      padding-bottom: 2px;
      border-bottom: 1px solid #d9d9d9;

      .filter-text {
        color: #000;
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 600;
      }

      .clear-text {
        cursor: pointer;
        font-size: 12px;
        // background-image: linear-gradient(to right, #0370a9, #08acda);
        color: #0370a9;
        border: 1px solid #036fa900;
        font-weight: 600;


        margin-bottom: 0px;
        border-radius: 12px;
        padding: 1px 4px;

        &:active {
          color: #ffffff;
          background: #0370a9;
          box-shadow: 0 2px 6px 0 rgba(19, 42, 104, 0.377) !important;

        }

        &:hover {
          border: 1px solid #0370a9;
          color: #0370a9;
          box-shadow: 0 2px 6px 0 rgba(19, 42, 104, 0.377) !important;
          transition: ease-in 0.3s;
        }
      }
    }

    .overall-filter-body {
      .ant-collapse {
        background: transparent;
        border: transparent;

        .ant-collapse-item {
          border: transparent;

          .ant-collapse-header {
            padding: 4px 0;
            font-weight: 700;
            //color: #000;
            background: none;
            // .anticon { 
            //     width: 100%;
            //     text-align: end;
            //     left: 0;
            // }
            color: #353535;
            font-size: 14px;
            font-family: "Nunito";


          }
        }
      }

      .stops-filter,
      .slider-filter,
      .airlines-filter,
      .fare-filter,
      .fare-types-filter,
      .connecting-locations {
        .ant-collapse-content-box {
          font-size: 14px;
          font-weight: 700;
          font-family: "Nunito";
          padding: 16px 0;

        }
      }
    }
  }

  .filter-labels {
    p {

      color: #353535;
      font-size: 13px;

      span {
        &:nth-child(2) {
          margin-left: 15px;
          font-size: 12px;
          font-weight: normal;
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
          color: white;

        }
      }
    }
  }

  .ant-collapse-header {
    .ant-collapse-extra {
      span {
        //margin-right: 27px;
        font-size: 12px;
        font-weight: normal;
        color: #fdfefe;
        // border: 1px solid #2e0000;
        color: #2e0000;
        padding: 2px;
        // border-radius: 2px;
        // box-shadow: 0 2px 6px 0 rgba(19, 42, 104, 0.377) !important;

        &:active {
          background: #0080ff;
          color: white;
        }

        &:hover {
          background: #eff7ff;
        }
      }

    }
  }

  .ant-collapse-content-box {
    p {
      margin-bottom: 5px;
      font-size: 12px;
      margin-top: 2px;
    }

    .ant-checkbox-wrapper {
      font-size: 14px;
      font-weight: 700;
      font-family: 'Nunito';
      display: flex;
      width: 100%;
    }

    .ant-checkbox+span {
      flex: 1;
    }

    .slider-label {
      span {
        &:nth-child(2) {
          float: right;
        }
      }
    }
  }
}

.slider-icon-1 .ant-slider {
  margin: 10px 8px !important;
}

.slider-icon-1 .input-range {
  height: 1rem;
  position: relative;
  width: 93%;
}

.slider-icon-1 .input-range__slider:active {
  transform: scale(1);
}

.slider-icon-1 .input-range__slider {
  margin-left: 0rem;
  margin-top: -0.65rem;
}

.ant-checkbox-checked .ant-checkbox-inner::after{
  position: absolute;
  display: table;
  border: 2px solid #000000;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.slider-icon-1 .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  right: 0px;
  left: auto;
  bottom: 13px;
}

.flight-filters .ant-slider-handle {
  background: #fcfcfc;
  border: 1px solid #3f51b5;
}

.flight-filters .ant-slider-track {
  background: #058aff;
}

.flight-filters .ant-slider:hover {
  border-color: #3f51b5 !important;
}

.flight-filters .ant-slider:hover .ant-slider-track {
  background-color: #3f51b5 !important;
}

.flight-filters .input-range__slider {
  width: 14px;
  height: 14px;
}

.check-box-css {
  .ant-checkbox-inner {
    width: 0px;
    height: 0px;
  }
}

.label-nav-set {
  border: 1px solid rgba(165, 165, 165, 0.523);
  border-radius: 4px;
  height: 50px;
  width: 24%;
  text-align: center;
  padding-top: 2px;
  // background-image: linear-gradient(to right, #2e0000, #2e000000);
  background: linear-gradient(293deg, #5fb4e1, #6ccfeb45);


  &:active {
    background: linear-gradient(to right, #006aa4, #00aedf);
    transition: ease 0.4s;
    // background: rgba(0, 162, 255, 0.616);
  }

  &:hover {
    transition: ease 0.4s;
    box-shadow: 0 2px 6px 0 rgba(19, 42, 104, 0.377) !important;
  }

  @media (max-width: 991px)  {
    width: 45%;
  }

}