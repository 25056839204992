#home-page {
  //background-color: #f4f5f6;
  //padding-top: 80px;

  @media (max-width: 767px) {
    padding-top: 0px;
  }

  .hero-banner {
    position: relative;
    z-index: 1;
    // padding-top: 80px;
  }

  .banner-links {
    margin-bottom: 30px;
    background-color: #fff;
    padding: 15px 20px;
    box-shadow: 0 2px 4px 0 #dcdee3;

    .banner-links-wrapper {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;

        li {
          list-style-type: none;
          display: flex;
          padding: 0px 13px;

          p.banner-icon {
            font-size: 25px;
            margin-bottom: 0px;
            padding-right: 15px;
            display: table-cell;
          }

          .banner-box {
            label {
              display: block;
              text-transform: uppercase;
              margin-bottom: 0px;
              font-size: 11px;
              color: #272d38;
              font-weight: 700;
            }

            a {
              color: #bd0c21;
              font-size: 13px;
              letter-spacing: 0.5px;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .topflights_wrapper {
    max-width: 1140px;
    margin: 0 auto;
    padding: 15px;

    .domesticFlights,
    .intFlights {
      h2 {
        text-align: center;
        margin-bottom: 20px;

        @media (max-width: 767px) {
          font-size: 24px;
        }
      }
    }

    .intFlights {
      margin-top: 40px;
    }

    .popular-cards {
      .ant-card {
        border-radius: 5px;
      }

      .ant-card-body {
        padding: 0px !important;
        border-bottom: solid 2px #002273;
        border-radius: 5px;
      }

      .flights-travel-list {
        li {
          border-bottom: solid 1px #ccc;
          padding: 0px 15px 0px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            background-color: #f6f6f6;

            a {
              color: #002273 !important;
              text-decoration: underline;
              text-decoration-color: #002273;
            }
          }

          a {
            padding: 10px 10px;
            color: #333 !important;
            font-size: 20px;

            @media (max-width: 767px) {
              font-size: 13px;
              font-weight: 600;
            }
          }

          p {
            margin-bottom: 0px !important;
            color: #002273;
            font-size: 15px;
          }
        }

        .list-with-img {
          display: flex;
          justify-content: space-between;
          text-decoration: none !important;
          padding: 8px 15px 8px 0px;

          @media (max-width: 767px) {
            padding: 10px;
          }

          .imageItem {
            padding-left: 25px;
            width: 90px;
            height: 40px;

            img {
              height: 100%;
            }
          }

          .airline-btn {
            color: #fff;
            background-color: #bd0c21;
            padding: 10px 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: 400;
            width: 275px;

            @media (max-width: 767px) {
              width: 166px;
              padding: 10px 20px;
              font-size: 12px;
              margin-left: 38px;
            }
          }
        }
      }

      .left-card {
        margin-right: 10px;

        @media (max-width: 767px) {
          margin-right: 0px;
        }
      }

      .right-card {
        margin-left: 10px;

        @media (max-width: 767px) {
          margin-left: 0px;
          margin-top: 15px;
        }
      }
    }
  }
}

/*  ===============  Styles for Need hotel Room ============ */
.need-hotel-room-section {
  background-color: #eaf2fc;
  background-image: url("https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/footer/stars_desktop.png");
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    background-image: url("https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/footer/stars_mobile.png");
    background-size: contain;
    background-position: center;
  }

  .hotel-room {
    max-width: 700px;
    margin: 0 auto;
    padding: 38px 15px 38px 50px;
    min-height: 180px;

    @media (max-width: 767px) {
      padding: 38px 15px;
    }

    .hotel-icon {
      float: left;
      position: relative;
      background-image: url("https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/footer/sprite_footer.png");
      background-position: 0 0;
      width: 105px;
      height: 99px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .hotel-room-text {
      margin-left: 190px;
      text-align: left;

      @media (max-width: 767px) {
        margin-left: 0px;
        text-align: center;
      }

      .room-title {
        font-size: 27px;
        line-height: 35px;
        font-weight: 400;
        margin: 0 auto 12px;
        color: #002172;

        a {
          color: #fff;
          background-color: #bd0c21;
          border-radius: 3px;
          min-height: 50px;
          padding: 10px 30px;
          font-size: 16px;
          text-align: center;
          display: inline-block;
          text-decoration: none;
          font-weight: 700;
        }
      }
    }
  }
}

.icon-info {
  margin-right: 9px;
  width: 41px;
  background: #002273;
  height: 41px;
  line-height: 41px;
  color: #ffffff;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
}

.top-fl-route {
  color: #0c50bd;
  font-size: 28px;
  font-family: "nunito";
  font-weight: 800;
  color: #0c50bd;
  text-align: center;
  margin: 0 0 15px;
  // font-weight: 600;
  position: relative;
  margin-top: 35px;
}

// .top-fl-route::after {
//   content: "";
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 100%;
//   margin: 10px auto;
//   width: 10%;
//   height: 3px;
//   background: #505050;
// }

.tab-content ul {
  // width: 100%;
  float: left;
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 0;
}

.tab-content ul li {
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 4px 0;
  width: 100%;
  font-size: 15px;
  color: #676666;
  line-height: 32px;
  border-bottom: 1px dashed #dfe2e2;
}

.tab-content ul li:last-child {
  border-bottom: 0;
}

.hm_link {
  font-size: 15px;
  color: #676666;
  text-decoration: none;
  cursor: pointer;
}

.srch_btn {
  background: #fff;
  float: right;
  color: #bd0c21;
  border-radius: 4px;
  border: 1px solid #bd0c21;
  padding: 0 10px;
  line-height: 31px;
  position: relative;
  outline: 0;
  display: inline-block;
  min-height: 36px;
  /* min-width: 88px; */
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.top-route-bb {
  background-color: #ffffff;
  margin-bottom: 0px;
}

.sec-bg-top {
  width: 100%;
  // background: #fff;
  border: 1px solid #dfe2e2;
  border-bottom: 2px solid #dfe2e2;
  margin-top: 35px;
  border-radius: 3px;
  display: inline-block;
  padding: 1% 2%;
  background: linear-gradient(94deg, #bd0c215c, #35459c70);
}

.gap-cols-sec {
  padding-left: 8px;
  padding-right: 8px;
}

.offers-bal-cart {
  display: flex;
  background: #fff;
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  justify-content: center;
  color: #000;
  padding: 7px 6px;
  position: relative;
  z-index: 0;
}

.deals-coloums-24 {
  height: 34px;
}

.price-deals-24 p {
  margin-bottom: 0px !important;
  font-size: 14px;
}

.price-deals-24 {
  font-weight: 600;
  padding-left: 10px;
}

.support-24 {
  padding-left: 13px;
}

@media screen and (max-width: 768px) {
  .support-24 {
    padding-left: 0px;
  }

  .step-img-value {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .step-img-tai {
    width: 100%;
  }

  .book-step-option {
    margin-bottom: 10px;
  }
}

.banner-img {
  .slick-slider {
    .slick-list {
      .slick-track {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: fit-content;

        // width: 100% !important;
        .slick-slide {
          width: 100% !important;
          // max-height: 200px;
        }
      }
    }
  }

}

.home-best-24 {
  margin-top: -100px;
  box-shadow: 0 1px 8px lightcyan;


  @media (max-width: 1024px) {
    margin-top: -30px;
  }

  @media (max-width: 991px) {
    margin-top: -10px;
  }

  @media (max-width: 770px) {
    margin-top: -13px;
  }

  @media (max-width: 767px) {
    margin-top: 113px !important;
  }

  // padding-top: 84px;
}
// .bann1 {
//   width: 80%; // Adjust as needed
//   max-width: 600px; // Prevent it from being too large
//   height: auto; // Maintain aspect ratio

//   @media (max-width: 1024px) {
//     width: 70%;
//   }

//   @media (max-width: 768px) {
//     width: 90%;
//   }

//   @media (max-width: 480px) {
//     width: 100%; // Full width on small screens
//   }
// }

.home-best-trip p {
  margin-bottom: 8px;
}

.home-best-trip {
  margin-bottom: 0px;

  .containerTxt {
    position: relative;
    z-index: 1;
    background: #fff;
    margin: 0 auto;
    width: 75%;
  }
}

/*18-09-2021*/
.top-fl-route-email {
  color: #fff;
  font-size: 25px;
  text-align: center;
  margin: 0 0 15px;
  font-weight: 600;
  position: relative;
  margin-top: 16px;
}

.top-fl-route-email::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 10%;
  height: 3px;
  background: #fff;
}

.stay-best-email {
  // background-color: #2f81ed;
  // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
  background: #003b95;
  margin-bottom: 0px;
  // padding-bottom: 20px;
  margin-top: 30px;
  padding: 35px 0;
}

.news-sub-tt {
  height: 60px;
  color: #f4f4f4;
}

.sub-text-up {
  display: flex;

  @media (max-width: 991px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.date-news-travel {
  // padding-top: 3px;
  // padding-bottom: 10px;
  // padding-left: 10px;
  padding: 0 20px 0;
}

.sub-text-up p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
}

.home-from-sub {
  // padding-top: 25px;
  color: #f4f4f4;

  .ant-row {
    display: flex;
    justify-content: space-between;
  }
}

.Formdata-subscribe-main {
  width: 70%;

  @media (max-width: 991px) {
    width: 60%;
  }

  @media (max-width: 767px) {
    width: 60%;
  }
}

.name-sub-date {
  height: 52px;
  font-size: 14px;
  border-radius: 6px;
  width: 100%;
}

.cribe-btn-email {
  background: #003b95 !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 16px;
  outline: 0;
  border: 2px solid #ffff;
  font-weight: 600;
  height: 52px;
  border-radius: 6px !important;
  width: 30%;
  margin-left: 2%;


  @media (max-width: 991px) {
    width: 35%;
  }

  @media (max-width: 767px) {
    width: 35%;
  }
}

.new-img-sec-c1 {
  border-top-left-radius: 40px;
}

.step-img-value {
  width: 630px;
  height: 350px;
  border-top-left-radius: 40px;
}

.divbanner {
  width: fit-content;
  /* Shine */

  // position: relative;
}

.divbanner::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.divbanner:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .7s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}


.step-img-tai {
  width: 96%;
  height: 350px;
  // border-bottom-right-radius:5px;
}

.img-ft-img-sec {
  margin-top: 40px;
  margin-bottom: 20px;
}

.rate-btn-from {
  width: 96%;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
}

.offers__info_rates {
  padding-bottom: 6px;
  padding-left: 5px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.offers__info_rates P {
  margin-bottom: 0px;
  color: #fff;
}

.st-flex {
  display: flex;
}

.sg-country-name {
  font-size: 18px;
  color: #bd0c21 !important;
  font-weight: 600;
  padding-left: 10px;
}

.book-sg-country {
  background: #bd0c21 !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  outline: 0;
  border: 0;
  width: 100%;
}

.hm_link:hover {
  color: #bd0c21 !important;
}

.route-trip-3 {
  margin-bottom: 36px;
}

.recentSearchesRow {
  .ducarosuel {
    // box-shadow: 0 1px 5px rgb(0, 0, 0 / 10%);
    // border-radius: 6px;
    padding: 5px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: space-between;

    // border: 1px solid #eae8e8;
    // margin: 2px 9px 5px;
    cursor: pointer;
    transition: all 0.5s ease;
    background: #fff;
  }

  .origin-item-sel {
    width: 100%;
    display: block;
    flex-wrap: nowrap;
    padding-left: 12px;
    border-left: 2px solid #dbe3ef;
    position: relative;
  }

  .origin-item-sel::before {
    content: " ";
    position: absolute;
    background-color: #dbe3ef;
    padding: 3px;
    border-radius: 50%;
    top: 0;
    left: -4px;
  }

  .origin-item-sel::after {
    content: " ";
    background-color: #dbe3ef;
    padding: 3px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: -4px;
  }

  .city-due {
    display: inline-block;
    // width: 100%;
    font-size: 11px;
    text-transform: uppercase;
    color: #333;
    padding: 0px 0px 0px 5px;

    font-weight: 600;
  }

  .iconsDiv {
    color: #bd0c21 !important;
  }

  .time-due {
    // margin: 5px 0 2px;
    padding: 0px 0px 0px 5px;
    font-weight: 400;
    font-size: 11px;
  }
}

.container-st {
  margin: 12px 15% 12px 10%;
  // .bann1{
  //   width: 100%;
  // }
}


.mark-st-banner {
  margin: 3% 10%;

  .top-img-ho {
    box-shadow: 2px 3px 12px rgb(255, 255, 255);

    &:hover {
      box-shadow: 2px 3px 12px lightblue;
    }
  }

  h6 {
    font-size: 28px;
    font-family: 'Nunito';
    font-weight: 800;
    color: #0c50bd;
    display: flex;
    justify-content: center;
    // margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    font-family: 'Nunito';
    // font-weight: 800;
    color: #666;
    display: flex;
    justify-content: center;
    // margin-right: 64px;
    margin-top: -4px;
  }
}

.mark-st-banner1 {
  margin: 6%;
  margin-left: 9%;
  padding-left: 32px;

  h6 {
    font-size: 28px;
    font-family: nunito;
    font-weight: 800;
    color: #0c50bd;
    display: flex;
    justify-content: center;
    margin-right: 64px;
  }

}