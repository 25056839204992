

.web-check-in-page {
  background-image: url("https://res.cloudinary.com/dz0wvd3lm/image/upload/v1736252834/web-checkin-3_cn6dgp.webp");
  background-repeat: no-repeat;
  background-position: center top;

  height: 335px;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    background-image: url("https://res.cloudinary.com/dz0wvd3lm/image/upload/v1736252835/web-checkin-mob-1_fohiop.webp");
    height: 150px;
  }
}
.web-heading-check {
  font-size: 16px;

  font-weight: 500;
  line-height: 3px;
}
.span-web-check {
  color: #999;

  font-size: 13px;
}
.list-web-checkin {
  display: flex;
  color: #333;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0 , 0.1);
  border-radius: 8px;
  transition: all 0.5s ease;
  padding: 12px 12px;
  cursor: pointer;
}
.list-web-checkin:hover {
  transition: all 0.5s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0 ,0.2);
  transform: scale(1.1) perspective(1px);
  backface-visibility: hidden;

  -webkit-font-smoothing: subpixel-antialiased;
}
.list-cc-img-web {
  border-radius: 50%;
  height: 28px;
  width: 28px;
}
.list-web-checkin span {
  margin-bottom: 0px;
  color: #333;
  font-size: 14px;
  padding-left: 8px;
  padding-top: 3px;
}
