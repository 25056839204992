.hotel-invoice {
  .invoice-wrapper {
    width: 990px;
    margin: auto;
    padding-top: 30px;
    h3 {
      font-size: 21px;
      font-weight: 700;
      color: #1c2b39;
      font-family:"Nunito"; ;
    }
    table {
      thead {
        background: #f5f8fd;
        th,
        td {
          border: 1px solid #bcd5f5;
        }
      }
    }
    .invoice-items {
      display: flex;
      flex-wrap: wrap;
      .invoice-items-list {
        flex-grow: 0;
        flex-basis: 50%;
        p {
          margin-bottom: 1rem;
          font-size: 13px;
          strong {
            color: #1c2b39;
          }
          span {
            padding: 0 3px;
          }
        }
      }
    }
    .invoice-sub {
      margin-top: 25px;
      h3 {
        background: #f5f8fd;
        padding: 10px;
        font-size: 18px;
      }
    }
    .hotels-info-block {
      .hotel-details-info {
        display: flex;
        margin: 15px 0;
        .hotel-ticket-image {
          max-width: 220px;
          width: 220px;
          height: 140px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .hotel-ticket-address {
          padding-left: 15px;
          h4 {
            font-size: 21px;
            font-weight: 700;
            color: #1c2b39;
          }
          p {
            font-size: 13px;
          }
        }
      }
    }
    .holder-details {
      ul {
        li {
          padding-bottom: 8px;
        }
      }
    }
    .important-note-points {
      ul {
        padding-left: 40px;
        li {
          list-style: disc;
          padding-bottom: 8px;
        }
      }
    }
  }
}
