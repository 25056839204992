.loader-wrapper {
    height: 300px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 12;
    margin-bottom: 10px;
}

.loader {
    position: absolute;
    top: 13%;
    left: 26%;
    transform: translate(-50%, -50%) scale(0.5);
    width: 200px;
    height: 150px;
}

.loader-wrapper.loader-text .loader {
    width: auto;
    height: auto;
}

.loader-wrapper.inner-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Replace $inner-bg with appropriate background */

    .loader {
        top: 25%;
        width: 100%;
        height: 100%;
        display: flex;
        margin-top: 9%;
        justify-content: center;
    }
}

.loader-wrapper.food-loader .loader {
    width: auto;
    height: auto;
}

.loader-wrapper.img-gif {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-wrapper.img-gif img {
    width: 20%;
}

.loader-wrapper.loaderhide {
    display: none;
}

.loader-wrapper .loader .chev1, .loader-wrapper .loader .chev5 {
    width: 52px;
    height: 52px;
}

.loader-wrapper .loader .chev2, .loader-wrapper .loader .chev4 {
    width: 70px;
    height: 70px;
}

.loader-wrapper .loader .chev3 {
    width: 90px;
    height: 90px;
}

@media screen and (max-width: 768px) {
    .loader-wrapper .loader {
        transform: translate(-50%, -50%) scale(0.8);
        width: 150px;
        height: 100px;
    }

    .loader-wrapper.img-gif img {
        width: 50%;
    }
}

@media screen and (max-width: 480px) {
    .loader-wrapper .loader {
        transform: translate(-50%, -50%) scale(1);
        width: 120px;
        height: 80px;
    }

    .loader-wrapper.img-gif img {
        width: 70%;
    }
}
