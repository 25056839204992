.top-hotels {
  text-align: center;
  margin: 20px;
}

.title {
  font-size: 24px;
  color: #0056b3;
  margin-bottom: 20px;
}

.hotels-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Two columns */
  gap: 15px;
  @media(max-width:767px){
    display: block;
  }
}

.hotel-item {
  display: flex;
  align-items: center;
}

.hotel-image {
  width: 50px;
  height: 50px;
  border-radius: 13%;
  margin-right: 15px;
}

.hotel-info1 {
  text-align: left;


}

.hotel-nmc {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  font-family: "Nunito";

  .anc-tab{
    &:hover {

      color:#ff7b54 !important;

    }
  }
}

.hotel-typeN {
  font-size: 14px;
  color: #555;
  margin: 0;
}