
.upload-list-inline .ant-upload-list-item {
    float: none !important;
    // width: 200px;
    margin-right: 8px;
  }
  
  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: none !important;
  }
