.refundpolicy-new {
  background-image: url("https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/policy-new.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 18.5em;
  width: 100%;
  margin-bottom: 15px;
}
.policy-container-txt {
  font-family: 'Nunito';
  line-height: 1.6;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  max-width: 80%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);


.policy-title {
  text-align: center;
  color: #003399;
  font-size: 1.8rem;
  margin-bottom: 15px;
  border-bottom: 2px solid #003399;
  padding-bottom: 5px;
}

.policy-intro {
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
}

.policy-list {
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 10px;
  padding: 0 60px;
}

.policy-list li {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
}

.highlight {
  font-weight: bold;
  color: #d9534f;
  background-color: #ffe7e7;
  padding: 2px 4px;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .policy-container {
    padding: 15px;
    font-size: 0.9rem;
  }

  .policy-title {
    font-size: 1.5rem;
  }
}
}