.top-searched-flights {
    text-align: center;
    padding: 20px;
  }
  
  .top-searched-flights h2 {
    color: #0056b3;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .flights-container-Tf {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    &:hover{
        box-shadow: 2px 4px 12px #dadadad3;
    }
    @media (max-width: 991px)  {
      gap:0px;
  }
  }
  
  .flight-card-Tf {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .flight-card-Tf img {
    width: 60px;
    height: 45px;
    object-fit: fit;
    transition: transform 0.3s ease-in-out;
  }
  
  .flight-card-Tf p {
    margin-top: 8px;
    color: #0056b3;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 0;
    &:hover{
        color: rgb(70, 70, 70);
    }
  }
  
  .flight-card-Tf:hover {
    transform: translateY(-8px);
  }
  
  .flight-card-Tf:hover img {
    transform: scale(1.2);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .flights-container-Tf {
      gap: 15px;
    }
    .flight-card-Tf {
      width: 80px;
    }
    .flight-card-Tf img {
      width: 30px;
      height: 30px;
    }
    .flight-card-Tf p {
      font-size: 12px;
    }
  }
  