.filter-elements {
  text-align: left;
  padding: 0 15px;
}
.stops {
  border-top: solid 1px #c3bdbd;
  padding: 15px 5px;
  line-height: 30px;
  .stops-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}
.side-bar {
  // border: #dedada 1px solid;
  background-color: #fff;
  border-radius: 3px;
  // float: left;
}
.clearall {
  color: #a062ce;
  float: right;
  font-size: 12px;
}
.filter-top-bar {
  padding: 15px 5px;
}
.filter-price {
  padding: 2px;
  float: right;
}
.clear-div {
  clear: both;
}
.stops-text {
  font-weight: 700;
}
.outbound-text {
  font-size: 11px;
  font-weight: 600;
  color: gray;
}
.results-count {
  font-size: 21px;
  font-family:"Nunito"; ;
}

@media screen and (max-width: 768px) {
  .filter-section-bus {
    display: none;
  }
}
@media screen and (max-width: 820px) {
  .filter-section-bus {
    display: none;
  }
}
