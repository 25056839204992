.profile-btn{
    background: linear-gradient(90deg, #09b0dd, #026ba5) !important;
    color: #ffffff;
    border-radius: 50px;
    &:hover{
        background: linear-gradient(90deg, #ff7b54, #ff3e3e)!important;
        color: white;
    }
}
.add-anver{
    float:right;
}
.profile-bg {
    padding-top: 100px;
    background-color: #f6f7fb;
}

#profile {
    max-width: 1140px;
    margin: auto;
    padding: 20px 0px;
}

.profile_pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 150px;
        height: 150px;
    }
}

@media only screen and (max-width: 767px) {

    #profile {
        margin-top: 25px;
    }
    .min-width-pf{
        display: none;
    }
    
}
@media only screen and (min-width: 767px) {
    .min-width-pf{
        display: block;
    }
    .max-width-ns{
        display: none;
    }


}
