.termsofconditions-new {
  background-image: url("https://res.cloudinary.com/dz0wvd3lm/image/upload/v1736252729/terms-new_muj016.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 18.5em;
  width: 100%;
  margin-bottom: 15px;
}

.bus-terms-container {
  font-family: 'Nunito', sans-serif;
  line-height: 1.6;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 60px auto 20px;
  max-width: 80%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);


  .bus-terms-title {
    text-align: center;
    font-size: 2rem;
    color: #2a66ff;
    margin-bottom: 20px;
    border-bottom: 3px solid #2a66ff;
    padding-bottom: 10px;
  }

  .bus-terms-intro {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 20px;
  }

  .section {
    margin-bottom: 30px;
  }

  .section-title {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 10px;
    border-left: 5px solid #2a66ff;
    padding-left: 10px;
  }

  .section-list {
    list-style-type: disc;
    margin-left: 20px;
    color: #555;
  }

  .section-list li {
    margin-bottom: 10px;
  }

  .terms-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .images-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }

  @media (max-width: 768px) {
    .bus-terms-container {
      padding: 15px;
    }

    .bus-terms-title {
      font-size: 1.8rem;
    }

    .section-title {
      font-size: 1.3rem;
    }

    .terms-image {
      max-width: 100%;
    }
  }
}

.airlines-terms-cont {
  font-family: 'Nunito', sans-serif;
  line-height: 1.6;
  background-color: #eef6ff;
  border: 1px solid #cde3ff;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 10px;
  // max-width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


  .airlines-title {
    text-align: center;
    font-size: 2rem;
    color: #0056b3;
    margin-bottom: 20px;
    border-bottom: 3px solid #0056b3;
    padding-bottom: 10px;
  }

  .airlines-intro {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 20px;
  }

  .section {
    margin-bottom: 30px;
  }

  .section-title {
    font-size: 1.5rem;
    color: #004080;
    margin-bottom: 10px;
    border-left: 5px solid #0056b3;
    padding-left: 10px;
  }

  .section-list {
    list-style-type: disc;
    margin-left: 20px;
    color: #555;
  }

  .section-list li {
    margin-bottom: 10px;
  }

  .terms-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .images-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }

  @media (max-width: 768px) {
    .airlines-terms-container {
      padding: 15px;
    }

    .airlines-title {
      font-size: 1.8rem;
    }

    .section-title {
      font-size: 1.3rem;
    }

    .terms-image {
      max-width: 100%;
    }
  }
}

.company-tc-cont {

  max-width: 100%;
  // margin: 40px auto;
  padding: 20px;
  background: #fff;
  // background: linear-gradient(to right, #f0f4f8, #e8eff5);
  // background: url(../../assets/images/abt-us.jpg) no-repeat 80%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Nunito", sans-serif;
  color: #333;


  .company-title {
    font-size: 2rem;
    font-weight: bold;
    color: #004080;
    text-align: center;
  }

  .subtitle {
    text-align: center;
    font-size: 15px;
    margin:20px 8%;
    color: #555;
  }

  .info-container {
    display: flex;
    justify-content: space-around;

    .info-sec-img {
      width: 40%;
    }

    .info-sec {
      width: 65%;
      position: relative;
      z-index: 9;

      .info-section {
        margin-bottom: 8px;
        border: 1px solid #ddd;
        border-radius: 6px;
        overflow: hidden;
position: relative;
z-index: 50;
        .section-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: linear-gradient(0deg, #003b9570, #9fc9fb52);
          color: #fff;
          padding: 4px 12px;
          cursor: pointer;

        }

        .section-header:hover {
          background: linear-gradient(90deg, #003b9570, #9fc9fb52);

          h2 {
            color: #fff;
          }
        }

        .section-content {
          padding: 15px;
          font-size: 1rem;
          background: #fff;
          color: #333;
          line-height: 1.6;
          transition: all 0.3s ease-in-out;
          margin: 0;
        }
      }
    }

  }




}