/* Google Fonts link */


// body {
//     font-family: 'Amiri', serif; /* For text with a more calligraphic style */
// }

// h1, h2, h3 {
//     font-family: 'Cinzel', serif; /* For headings with an inscriptional, historical feel */
// }

.popular-destinations {
    .title {
      font-size: 28px;
      font-weight: 800;
      font-family: 'Nunito';
      margin-bottom: 30px;
      text-align: center;
    }
  
    .destinations-slider {
      .destination-card {
        width: 90% !important;
        margin: 0 auto;
        //padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        transition: transform 0.3s;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
        position: relative;
      }
  
      .destination-card:hover {
        transform: scale(1.05);
        box-shadow: 0px 1px 8px #ffa80a;
      }
  
      .destination-image {
        width: 100%;
        height: 250px; /* Adjust height as needed */
        object-fit: cover;
        border-radius: 10px;
      }
  
      .destination-overlay {
        position: absolute;
        top: 183px;
        left: 0;
        width: 100%;
        padding: 10px;
        color: #fff;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-radius: 10px 10px 0 0;
      }
  
      .destination-name {
        font-size: 24px;
        font-weight: 700;
        margin: 0;
        line-height: 16px;
        // font-family: 'Nunito';
        font-family: 'Cinzel', serif;
        color: #fff;
      }
  
      .destination-state {
        font-size: 10px;
        margin-top: 4px;
        font-weight: 100;
        margin-right:-10px;
        text-transform: uppercase;
        font-family: Ixi sans;
        color: #fff;
        letter-spacing: 8px;
      }
  
      .destination-properties {
        font-size: 15px;
        color: #333;
        margin-top: 3px;
        font-family: 'Nunito';
        font-weight: 700;
        margin-right:2px;
      }
    }
  }
  .destination-image-wrapper {
    position: relative;
  }
  
  .destination-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .destination-image-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#00000000, #00000099);
    z-index: 1;
    border-radius: inherit;
  }
  
  .destination-overlay {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 2; /* Place text above the overlay */
    color: #fff;
  }
  