.query-container {
    padding: 24px;
  
    .query-hotels-heading {
      text-align: center;
      margin-bottom: 24px;
  
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
  
      p {
        font-size: 16px;
        color: #555;
      }
    }
  
    .query-hotels-body {
      .ant-card {
        transition: transform 0.3s;
  
        &:hover {
          transform: translateY(-5px);
        }
  
        a {
          text-decoration: none;
          font-size: 18px;
          font-weight: bold;
  
          &:hover {
            text-decoration: underline;
          }
        }
  
        p {
          font-size: 14px;
          margin-top: 8px;
        }
      }
      .slick-list{
        .slick-track{
          margin: 10px 0;
        }
      }
  
      .slick-slide {
        display: flex;
        justify-content: center;
      }
    }
  }
  