

.checkInfoSection {
  .checkInfoWrapper {
    .check-imp-section {
      background-color: #002172;
      height: 100%;
      width: 100%;
      margin-top: -67px;
      .bread-crumb-block {
        padding-top: 67px;
        ul.breadcrumb {
          padding: 10px 16px;
          list-style: none;
          background-color: transparent;
        }
        ul.breadcrumb li {
          display: inline;
          font-size: 12px;
        }
        ul.breadcrumb li + li:before {
          padding: 8px;
          color: #dcdee3;
          content: "/\00a0";
        }
        ul.breadcrumb li a {
          color: #dcdee3;
          text-decoration: none;
        }
        ul.breadcrumb li a:hover {
          color: #dcdee3;
          text-decoration: underline;
        }
        ul.breadcrumb li span {
          color: #fff;
          font-weight: 700;
        }
      }
      .imp-text {
        text-align: center;
        padding: 30px 0px 40px 0px;
        h1,
        h4 {
          color: #fff;
          font-family:"Nunito";
          margin-bottom: 10px !important;
        }
        h1 {
          font-size: 50px;
          font-weight: 700;
          @media (max-width: 767px) {
            font-size: 28px;
          }
        }
        h4 {
          font-size: 27px;
          font-weight: 300;
          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
      }
    }
    .checkInfoBody {
      background-color: #f6f7f8;
      padding: 40px 0px;
      .container {
        max-width: 995px;
      }
      .check-booking-status {
        h2 {
          font-size: 27px;
          text-align: center;
          font-weight: 600;
          margin-bottom: 30px;
          @media (max-width: 767px) {
            font-size: 22px;
          }
        }
        .check-cards {
          margin-bottom: 20px;
          .ant-card {
            box-shadow: 0 2px 16px 0 rgba(28, 43, 57, 0.1);
          }
        }
        .check-card-body {
          h6 {
            font-weight: 600;
            margin-bottom: 20px;
          }
          input {
            height: 37px;
            // margin-right: 20px;
          }
          .send-confirm-btn {
            padding: 18px 35px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 1px;
            font-family:"Nunito";
            background-color: #bd0c21;
            color: #fff;
            border: none;
            @media (max-width: 767px) {
              width: 100%;
            }
          }
        }
        .acc-head-section {
          display: flex;
          margin-bottom: 18px;
          margin-top: 18px;
          align-items: center;
          .acc-heading {
            display: flex !important;
            align-items: center;
            width: 40%;
            @media (max-width: 767px) {
              width: 60%;
            }
            .info-icon {
              margin: 0 20px 4px 0 !important;
              font-size: 20px;
              @media (max-width: 767px) {
                font-size: 16px;
              }
            }
            h6 {
              margin-bottom: 0px !important;
              font-size: 18px;
              font-weight: 600;
              @media (max-width: 767px) {
                font-size: 16px;
              }
            }
          }
          .hr-block {
            width: 60%;
          }
          hr {
            width: 100%;
          }
        }
        .check-accordions {
          padding-top: 8px;
          .ant-collapse-borderless {
            background-color: #fff !important;
            box-shadow: 0 2px 4px 0 rgba(28, 43, 57, 0.1);
            border-radius: 3px;
          }
        }
      }
    }
  }
}
.numbered-list {
  li {
    list-style-type: decimal;
    color: #1c2b39;
    font-size: 13px;
  }
}
.styled-list {
  li {
    position: relative;
    padding-left: 20px;
    &:before {
      margin-right: 12px;
      background-color: #bd0c21;
      top: 7px;
      content: "";
      position: absolute;
      width: 8px;
      left: 4px;
      height: 8px;
      border-radius: 100%;
    }
    &::after {
      top: 9px;
      left: 5px;
      width: 4px;
      height: 4px;
      background-color: #fff;
      content: "";
      border-radius: 100%;
      position: absolute;
    }
  }
}
.contact-form-btn {
  border: 1px solid #bd0c21;
  margin-left: 15px;
  border-radius: 5px;
  color: #bd0c21;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 20px;
  background-color: #fff;
}

.acc-main-head {
  margin-top: 65px;
}
