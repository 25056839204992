

.review-bottom-mobile {
    @media (max-width: 767px) {
        order: 1;
    }
}

.review-top-mobile {
    @media (max-width: 767px) {
        order: 0;
    }
}

.passenger-form {
    .form-group {
        @media (max-width: 767px) {
            flex-wrap: nowrap;
        }
    }
}

.checkout-container {

    // margin-top: 115px;
    .pax-heading {
        width: 100%;
        color: #35459c;
        padding-left: 7px;
        font-weight: 600;
        font-size: 17px;
    font-family: 'Nunito';
    }

    .inner-part-pass1 {
       // border: 1px solid #35459c;
        // padding: 10px;
        border-radius: 6px;
        // margin-top: 12px;

        .heding1 {
            color: #000000;
            font-weight: 700;
            font-size: 20px;
           // margin-top: 7px;
            margin-left: 14px;
           font-family: 'Nunito';
           margin-bottom: 0px;
          //  background: linear-gradient(to right, #bd0c21, #35459c);
            border-radius: 4px;
            width: fit-content;
            //padding: 0 10px 0 10px;

            @media (max-width: 767px) {
                margin-top: -21px;
                font-size: 16px;
                width: auto;
            }
        }
    }
}

.checkout-body-sec {
    .checkout-sticky-part {
       // padding: 5px 24px 24px;
        box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
        border-radius: 4px;
    }

    .checkout-top-part1 {
        width: 35%;

        @media (max-width: 991px)  {
            width: 100%;
        }

        @media (max-width: 767px) {
            order: 0;
        }
    }
}

.ssr-rev-1 {
    .ssr-label {
        padding-bottom: 9px;
    }

    .ssr-rev {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            justify-content: space-around;
        }

        .ssr-m {
            width: 100%;
            padding: 3px 6px;
           // border: 1px solid #d9d9d9;
        }

        .ssr-b {
            padding: 3px 6px;
            //border: 1px solid #d9d9d9;
        }
    }
}

.pop-ssr {
    border: 1px solid #35459c;
    border-radius: 4px;
    padding: 2px;

    .ssr-city {
        color: #bd0c21;
    }
}

.tooltip-table {
    width: 100%;
    /* Restrict table height */
    overflow-y: auto; /* Ensure table scrolls if content exceeds */
  }
  
  .tooltip-table th {
    background-color: #007bff; /* Header background */
    color: white; /* Header text color */
    text-align: left;
    position: sticky;
    top: 0; /* Keep header sticky */
    padding: 8px;
  }
  
  .tooltip-table td {
    padding: 8px;
    border: 1px solid #ddd;
    white-space: nowrap; /* Prevent wrapping */
  }
  
  .tooltip-content {
    padding: 0 !important; /* Remove default tooltip padding */
  }
  
.ant-tooltip-open{
    color: #000000;
 
.ant-tooltip-content {
    max-width: 100% !important;
    border-radius: 10px;
    width: 100% !important;
  
    .ant-tooltip-inner {
    //   padding: 10px 15px;
     padding: 0!important;
      background: none !important;
      border-radius: 4px;
      color: #555 !important;
    }
  }
}