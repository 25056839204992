.cancellation-wrapper {
  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p:first-child {
        text-align: left;
      }
      .text-align {
        text-align: center;
      }
    }
  }
}

.flightCancelDetials {
  display: flex;
}

.promo-modal-header {
  .bus-cancel-form {
    .ant-form-item-label {
      font-weight: bolder;
    }

    .ant-checkbox-group {
      width: 100%;
      .ant-checkbox-wrapper {
        font-weight: 600;
      }
    }
  }
}
