
.busDetails_wrapper {
  .card_wrapper {
    margin-bottom: 16px;

    .busname {
      font-size: 18px;
      font-weight: 500;
      color: #bd0c21;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    p {
      margin-bottom: 0;
    }

    .fromto_wrapper,
    .time_wrapper,
    .points_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media (max-width: 767px) {
        justify-content: space-between;
      }
    }

    .fromto_wrapper {
      .fromto {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .journeyDate {
      color: #1c2b39;
      font-size: 16px;
      font-weight: 600;
    }

    .bus-timings {
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .du-fast {
          position: relative;
          flex: 1;
          color: #555;
          font-size: 10px;
          font-weight: 600;
          border-top: 2px solid #bd0c21;
          margin: 0 5px;
          padding-bottom: 5px;

          span {
            position: absolute;
            top: -7px;
            left: 0;
            right: 0;
            width: fit-content;
            margin: 0 auto;
            background: white;
            padding: 0 5px;
          }
        }

        // .du-fast::before {
        //   content: "";
        //   background-color: #bd0c21;
        //   width: 80%;
        //   height: 2px;
        //   left: 0;
        //   right: 0;
        //   margin: 0 auto;
        //   top: 40%;
        //   position: absolute;
        // }
        li {
          margin-bottom: 5px;
          text-align: start;
          display: inline-block;
          color: #1c2b39;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .hr {
      border: 1px solid #eaebee;
      margin: 10px 0;
    }

    .points_wrapper {
      .point {
        font-weight: 600;
        font-size: 12px;
        color: #969dac;
      }

      .loc {
        font-weight: 600;
      }

      .fa {
        font-size: 12px;
      }
    }

    .busType {
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      color: #969dac;
    }

    .opdetail {
      text-align: center;
      color: #969dac;

      .optitle {
        font-weight: 600;
        font-size: 12px;
      }
    }

    .pricetitle {
      color: #969dac;
      font-weight: 600;
      font-size: 12px;
      text-align: right;
    }

    .infotext {
      font-size: 12px;
      margin-bottom: 0;
      text-align: right;
      color: #969dac;
    }

    .price {
      font-weight: 600;
      text-align: right;
    }

    .view-poli-bus {
       color: "#007bff";
      &:hover {
        color: #032251;
      }
      .ant-modal-content {
        border-radius: 15px !important;
        padding: 0px !important;
      }
      .ant-modal-header {
        color: #fff;
        background: #08aad8 !important;
        padding:16px;
        h6 {
          color: #fff !important;
        }
      }
      .ant-modal-body{
        padding :16px
      }
      
      .ant-modal-footer{
        padding :16px
      }

    }
    .loc-dest-busp{
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;  
    }
  }
}
