.register-section {
  background: url('https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/grey-bg.jpg') !important;
  color: #fff;

  .reg-container {
    max-width: 464px !important;
    margin: auto;
    padding: 25px 10px;
    text-align: center;

    @media (max-width: 767px) {
      padding: 20px 6px;
      margin-top: 50px;
    }

    h2 {
      font-size: 30px;
      color: #fff;

      // @media ( max-width : 767px ) {
      //     font-size: 13px;
      // }
      @media (max-width: 767px) {
        font-size: 25px;
      }
    }

    .register-row {
      padding: 0px 0px !important;

      @media (max-width: 767px) {
        padding: 0px;
      }

      .register-left-section {
        margin-left: 50px;

        @media (max-width: 767px) {
          display: none;
        }

        .left-item {
          padding: 10px 5px;
          text-align: left;
          display: flex;
          align-items: baseline;

          span {
            font-size: 20px;
            margin-right: 10px;
            margin-top: 10px;
          }

          p {
            font-size: 20px;
          }
        }
      }

      .register-card {
        @media (max-width: 767px) {
          background: #ffffff;
          border: none;
        }

        .ant-card-body {
          padding: 15px 24px;

          @media (max-width: 767px) {
            padding: 10px;
          }
        }

        .register-card-body {
          color: #000;

          @media (max-width: 767px) {
            padding: 0px 15px 15px;
          }

          .ant-form-item {
            margin-bottom: 14px;
          }

          .register-btn-container {
            margin-bottom: 0;
          }

          h3 {
            @media (max-width: 767px) {
              color: #000000;
            }
          }

          p {
            margin-bottom: 0;

            @media (max-width: 767px) {
              color: #fff;
            }

            span {
              color: #000;
            }

            .unlock-text {
              @media (max-width: 767px) {
                color: #fff;
              }
            }
          }

          .social-registration {
            display: flex;
            justify-content: space-evenly;

            Button {
              background-color: #fff;
              color: #000;
              // padding: 11px 26px;
              border: none;
              // margin: 0px 16px;
              box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
              margin-top: 0px !important;
              margin-left: 10px !important;
              display: flex;
              flex-direction: row;
              padding: 13px;

              .fbImg {
                max-width: 20px;
                margin-right: 5px;
              }
            }

            .facebook-btn {
              margin: 0px !important;
              // margin-right: 26px;
            }
          }

          .register-form {
            .input-size {
              // padding: 5px 35px;
              // max-width: 313px;
              margin: auto;

              .password-input {
                border-right: solid 1px #fff;
              }

              .pwd-append {
                background: #fff;
                border-left: solid 1px #fff;
                height: 52px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;

                i {
                  @media (max-width: 767px) {
                    color: #000;
                  }
                }
              }

              .error-msg {
                background: #da4c4b;
                color: #fff;
                padding: 3px 0px;
                margin-top: -11px;
                font-size: 14px;
                text-align: left;
                padding-left: 5px;
              }

              input {
                margin-bottom: 10px;
                font-size: 17px;
                padding: 25px 15px;
              }

              .agree-terms {
                display: flex;
              }

              .terms-privacy {
                color: #bd0c21;
                padding: 0px 4px;
              }
            }

            .check-terms-privacy {
              line-height: 1;

              .check-agree {
                display: flex;

                .agree-text {
                  text-align: left;
                  line-height: 1.5;
                }
              }
            }

            .unlock-offers {
              text-align: left;

              .check-offer {
                display: flex;

                p {
                  margin-bottom: 0px;
                }
              }
            }

            .register-btn {
              background-color: #bd0c21;
              border: none;
              font-size: 22px;
              font-weight: 600;
              max-width: 313px;
              padding: 8px 1px;
              margin: auto;
              margin-top: 22px;
              margin-bottom: 0px;

              @media (max-width: 767px) {
                margin-bottom: 20px;
              }
            }
          }

          .ant-col {
            margin-left: 0 !important;
            text-align: center;

            button {
              width: 100%;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .register-footer {
      font-size: 0.8rem;

      filter: Alpha(Opacity=40);
      color: #fff;
      text-align: center;
      margin: 25px 30px 0 30px;

      @media (max-width: 767px) {
        margin: 0 10px;
      }
    }
  }
}

.scial {
  .line-with-or-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0 0 0;
    /* Adjust margin as needed */
  }

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #0477ae;
    /* Line color */
  }

  .ors {
    font-family: "nunito";
    margin: 0 10px;
    /* Spacing around 'OR' text */
    font-weight: 500;
    color: #000000;
    /* Text color */
  }
}

.right-panel {
  @media (max-width: 767px) {
    order: 0;
  }
}

.acc-section {
  @media (max-width: 767px) {
    order: 2;
  }
}

.footer-section {
  @media (max-width: 767px) {
    order: 1;
  }
}

.login-alternatives {
  display: flex;
  margin: 15px 0;

  .fb-btn {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-right: 10px;

    @media (max-width: 767px) {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      margin-bottom: 0;

      @media (max-width: 767px) {
        color: #000 !important;
        margin-left: 10px;
      }
    }

    img {
      height: 20px;
      text-align: center;
    }
  }

  .google-btn {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-right: 10px;

    @media (max-width: 767px) {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      margin-bottom: 0;

      @media (max-width: 767px) {
        color: #000 !important;
        margin-left: 10px;
      }
    }

    img {
      height: 20px;
      text-align: center;
    }
  }
}

.or-text {
  p {
    font-size: 14px;
    color: #000;
    text-align: center;
  }
}

.register-section {
  .ant-form-item {
    .ant-form-item-control {
      min-width: 100%;
    }
  }
}

.ant-input-affix-wrapper::before {
  display: none;
}

.ant-form {

  //   padding: 10px 100px;
  @media (max-width: 767px) {
    padding: 0;
  }
}

.login-text {
  text-align: center;
}

.forgot-text {
  font-size: 14px;
  color: #bd0c21;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
}

.login-alternatives {
  display: flex;
  margin: 15px 0;

  .fb-btn {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-right: 10px;

    p {
      margin-bottom: 0;
    }

    img {
      height: 20px;
      text-align: center;
    }
  }

  .google-btn {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);

    p {
      margin-bottom: 0;
    }

    img {
      height: 20px;
      text-align: center;
    }
  }
}

.or-text {
  p {
    font-size: 14px;
    color: #000;
    text-align: center;
  }
}

.custom-form {

  // Target the label element inside form items
  .ant-form-item-label>label {
    font-size: 16px; // Example font size
    font-weight: 600; // Example font weight
    font-family: "Nunito"; // Example color

  }
}

.loginnn {
  background: linear-gradient(to right, #0370a9, #08acda) !important;
  width: 40%;
  /* display: list-item; */
  /* align-content: center; */
  margin-left: 30%;
  box-shadow: 0px 2px 10px #bcbcbc;

  &:hover {
    color: rgb(44, 44, 44);
    box-shadow: 1px 2px 10px #8f8f8f;
  }
}

.login-hov {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  color: #0370a9;
  cursor: pointer;

  &:hover {
    // border-bottom: #0370a9;
    color: #007bff;
  }
}

.login-hov::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  /* Start at the center */
  width: 0;
  height: 2px;
  background-color: #007bff;
  transition: all 0.4s ease;
  /* Smooth transition */
}

.login-hov:hover::before {
  left: 0;
  /* Expand from center to full width */
  width: 100%;

}

.img-pop-login-reg {
  .info-box {
    width: 500px;
    padding: 30px;
    z-index: 19;

    .benefits-list {
      list-style: none;
      padding: 0;
      margin: 20px 0;
      border-top: 1px dashed #ccc;
      padding: 8px;
    }

    .benefits-list li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;
    }

    .benefits-list strong {
      min-width: 150px;
      /* Ensures consistent width for all headers */
      margin-right: 10px;
      /* Spacing between header and description */
      text-align: left;
      font-weight: bold;
      color: #333;
      line-height: 1.5;
    }

    .benefits-list span {
      flex: 1;
      /* Takes the remaining space */
      text-align: left;
      /* Aligns the description text neatly */
      color: #555;
      line-height: 1.5;
    }

    // text-align: center;
  }



  .info-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .info-text {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .promo-image {
    width: 65%;
    max-width: 400px;
    border-radius: 8px;
    margin: 20px 0;
  }

  .info-list {
    // list-style: none;
    padding: 0;
    margin: 20px 0;
    font-size: 16px;
    font-family: nunito;
    color: black;
    display: flex;
    justify-content: space-between;
  }

  .info-list li {
    margin: 10px 0;
    list-style: inside;
    border: 1px solid green;
    border-radius: 6px;
    box-shadow: 0px 2px 12px #00800066;
    white-space: nowrap;
    align-content: center;
    padding:  6px;
    background: #ffff;
  }

  .login-user-box {
    width: 600px;
  }
}

.login-Hlink {
  border-bottom: 1px solid rgba(211, 211, 211, 0);

  &:hover {
    border-bottom: 1px solid lightgray;
  }
}