// .hotel-detail {
//     display: grid;
//     grid-template-columns: 2fr 1fr;
//     gap: 20px;
//     padding: 20px;
//     font-family: 'Nunito', sans-serif;
//   }

//   /* Header Section */
//   .hotel-header {
//     grid-column: 1 / span 2;
//   }

//   .hotel-name {
//     font-size: 1.8em;
//     font-weight: bold;
//   }

//   .hotel-rating {
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     margin: 5px 0;
//   }

//   .hotel-location {
//     color: gray;
//     font-size: 0.9em;
//   }

//   .hotel-tags .tag {
//     background-color: #eee;
//     padding: 5px 10px;
//     border-radius: 5px;
//     margin-right: 10px;
//   }

//   /* Carousel Section */
//   .hotel-carousel1 {
//     grid-column: 1 / span 2;
//     margin-top: 15px;
//     width: 70%;
//   }

//   .carousel-image img {
//     width: 100%;
//     height: auto;
//     border-radius: 8px;
//   }

//   /* Booking Section */
//   .booking-section {
//     display: flex;
//     flex-direction: column;
//     gap: 15px;
//   }

//   .price-box {
//     text-align: center;
//   }

//   .price-box h2 {
//     font-size: 1.8em;
//     color: #333;
//   }

//   .book-now-btn {
//     background-color: #ff5a5f;
//     color: white;
//     padding: 10px 20px;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
//   }

//   .map-box {
//     position: relative;
//   }

//   .map-image {
//     width: 100%;
//     height: auto;
//     border-radius: 8px;
//   }

//   .map-box span {
//     position: absolute;
//     bottom: 10px;
//     left: 10px;
//     color: #333;
//     font-weight: bold;
//   }

//   .rate-details {
//     font-size: 0.9em;
//   }

//   .rate-details h3 {
//     margin-bottom: 10px;
//   }

//   .rate-details p {
//     font-size: 1.2em;
//     color: #333;
//   }

//   .booking-form input,
//   .booking-form select {
//     width: 100%;
//     padding: 10px;
//     margin-bottom: 10px;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//   }
//   .hotel-Det-v-top{
//     display: flex;
//     justify-content: space-between;

//   }

 

.hotel-det-new {
    padding-top: 100px;
    margin: 0 10%;
}

.hotel-detail-container {
    border-bottom: 1px solid #e6e6e6;
    // padding-bottom: 20px;
}

.hotel-header {
    display: flex;
    // flex-direction: column;
    // gap: 10px;
    justify-content: space-between;

    .show-rooms-btn {
        align-content: center;

        button {
            border-radius: 3px;
            background-color: #0775e2;
            border-color: #0775e2;
            color: #fff;
            font-weight: 600;

            i {
                padding-left: 5px;
            }
        }


    }
}

.hotel-name {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    display: flex;
    font-family: 'Nunito';
    color: #002153;
}



.starRating {
    font-size: 14px;
    color: #67c4f7;
    position: relative;
    //top: 10px;
    font-family: 'Nunito';
}

.hotel-location {
    font-size: 1rem;
    color: #666;
    font-family: 'Nunito';
}

.hotel-tags {
    display: flex;
    gap: 8px;
}

.tag {
    background-color: #f0f0f0;
    color: #333;
    padding: 5px 10px;
    font-size: 0.9rem;
    border-radius: 5px;
    font-family: 'Nunito';
}

.hotel-Det-v-top {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.hotel-carousel1 {
    flex: 3;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.carousel-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.booking-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
}

.price-section h2 {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
    font-family: 'Nunito';
}

.price-section span {
    font-size: 1rem;
    color: #999;
    font-family: 'Nunito';
}

.map {
    // display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1rem;
    font-family: 'Nunito';
    color: #0071c2;
    cursor: pointer;
    border-bottom: 1px solid #0071c200;

    .view-map-Cli {
        position: relative;
        top: -25px;
        left: 185px;
        border: none;

        &:hover {
            transition: all 0.5s ease;
            border-bottom: 1px solid #0071c2;
        }
    }
}

.map img {
    width: 100%;
    border-radius: 5px;
    height: 112px;
}

.rate-details {
    font-size: 16px;
    color: #333;
    font-family: "Nunito";

    h3 {
        font-size: 20px;
        font-weight: 600;
        color: #00164d;
        max-width: 290px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        margin-bottom: 2px;
    }

    .room-rate-Det-box {
        display: flex;
        justify-content: space-between;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        li {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 12px;
        }

        .show-pr {
            font-size: 21px;
            display: inline-grid;
            margin: 0;
            line-height: 20px;
            font-family: 'Nunito';
        }
    }
}

.booking-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.booking-for {
    display: flex;
    // flex-direction: column;
    gap: 10px;

}
.ant-select-selection-placeholder {
    color: black !important;
}

.form-gro {
    line-height: 10px;
    margin-top: 10px;

    label {
        color: #00164d;
        font-weight: 500;
    }
}

.booking-for input,
.booking-for select {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    font-family: 'Nunito';
}

.booking-form input,
.booking-form select {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    font-family: 'Nunito';
}

.hotel-details-header-New {
    margin: 0 10%;

    .details-header-container {
        background: rgb(255, 255, 255);
        padding: 2px auto;

        .Activ-btn-Det {
            display: flex;
            justify-content: space-evenly;
            gap: 10px;
            padding: 4px 4px 0 4px;
            height: 50px;

            .activeDet {
                border: none;
                // border-radius: 3px;
                background-color: #ffffff;
                border-bottom: 1px solid #ff403e;
                color: #ff403e;
                font-weight: 600;

                &:active {
                    border-bottom: 1px solid #c20000;
                    transition: all 0.5s ease;
                }
            }

            .no-btn {
                border: none;
                // border-radius: 3px;
                background-color: #ffffff;
                border-bottom: 1px solid #ffffff;
                color: #0071c2;
                font-weight: 600;
            }
        }
    }

    .hotel-Newdet-block {
        background-color: #fff;
        margin-top: 10px;
        padding: 10px 20px;
        border-radius: 6px;
    }

    .room-Newcard-wrapper {
        padding: 2px 10px;
    }
}

.hotel-Det-v-bottom {
    margin: 10px 10%;

    .description-block {
        margin-top: 16px;
        background-color: #ffffff;
        padding: 10px 20px;
        box-shadow: 0px 0px 12px lightgrey;
        border-radius: 6px;

    }
}

.hotel-rooms-listN {
    background-color: #ffffff;

    .rooms-wrapperN {
        max-width: 1220px;
        width: 100%;
        margin: 0 auto;
        padding: 20px 15px;

        .available-rooms-dates {
            margin-bottom: 15px;
        }

        .Nrooms-list {
            .room-Newcard-wrap {
                padding: 6px;
                border-radius: 6px;

                .ant-card-body {
                    padding: 15px;

                    .room-number-span {
                        background-color: #0123ad;
                        background-image: linear-gradient(to right, #320063, #0f76bb);
                        padding: 1px 10px;
                        margin-bottom: 1px;
                        // border-radius: 10px;
                        display: inline-block;
                        color: #fff;
                        font-weight: 600;
                        font-size: 15px;
                    }

                    .button-wrapper {
                        button {
                            background-color: #0123ad;
                            background-image: linear-gradient(to right, #320063, #0f76bb);
                            color: #fff;
                            border: transparent;
                            padding: 5px 20px;
                            font-size: 12px;
                            font-weight: 600;
                            border-radius: 3px;

                            &:disabled {
                                cursor: not-allowed;
                            }

                            @media (max-width: 767px) {
                                order: 3;
                            }
                        }
                    }

                    .room-Newcard {
                        margin-bottom: 8px;
                        // background-color: #fff;
                        // border-radius: 3px;
                        // -moz-border-radius: 3px;
                        // box-shadow: 2px 2px 0px 0px #dadada;
                        // -webkit-box-shadow: 0 2px 3px 0 #eaebee;
                        // -moz-box-shadow: 0 2px 3px 0 #eaebee;
                        // border-bottom: 1px solid #cccccc;
                        // padding: 16px;
                        padding: 2px;
                        border-radius: 5px;
                        position: relative;
                        padding-bottom: 0;

                        // &:hover {
                        //   box-shadow: 3px 3px 1px 0px #e8e8e8;
                        // }

                        .hotel-room-details-main-card {
                            border: 2px solid transparent;
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;

                        }

                        .hotel-room-details-main-content-card {
                            border: 1px solid rgb(192, 192, 192);
                            // width: 68%;
                            width: 100%;
                            box-shadow: 2px 3px 8px grey;
                            padding: 15px 24px;
                            border-radius: 8px;

                            @media (max-width: 767px) {
                                width: 100%;
                                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0);
                                border: 1px solid rgba(192, 192, 192, 0);
                                padding-bottom: 0%;
                            }
                        }

                        .roomname-reff-part {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            .rm-name-t {
                                border: 1px solid rgb(218, 209, 209);
                                box-shadow: 1px 2px 6px rgb(165, 165, 165);
                                padding: 1px 4px;
                                border-radius: 6px;
                                color: #ffff;
                                background: linear-gradient(353deg, #bd0c21ab, #35459c);
                            }

                            @media (max-width: 770px) {
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-evenly;
                            }

                            @media (max-width: 767px) {
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-evenly;
                            }
                        }

                        .hotel-room-details-main-inclusions-card {
                            border-bottom: 2px solid #e0e0e091;
                            margin: 1%;
                            display: flex;
                            justify-content: space-evenly;
                            flex-wrap: wrap;
                            padding: 3px;
                        }

                        .hotel-room-details-main-inclusions-card1 {
                            border: 1px solid transparent;
                            width: 58%;
                            display: flex;
                            justify-content: space-evenly;
                            flex-wrap: wrap;

                            @media (max-width: 767px) {
                                width: 100%;
                            }
                        }

                        .hotel-room-details-main-inclusions-card2 {
                            width: 50%;
                            // display: flex;
                            // justify-content: center;
                            // align-items: center;

                           @media (max-width: 991px)  {
                                width: 100%;
                            }

                            @media (max-width: 991px)  {
                                width: 100%;
                            }

                            @media (max-width: 770px) {
                                width: 100%;
                            }

                            @media (max-width: 767px) {
                                width: 100%;
                            }

                            .board-b {
                                margin: 0;
                            }

                            .inc-r {
                                display: flex;
                                justify-content: flex-start;
                                flex-wrap: wrap;

                            }
                        }

                        .hotel-room-details-main-inclusions-card-2 {
                            width: 50%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                           @media (max-width: 991px)  {
                                width: 100%;
                            }

                            @media (max-width: 991px)  {
                                width: 100%;
                            }

                            @media (max-width: 770px) {
                                width: 100%;
                            }

                            @media (max-width: 767px) {
                                width: 100%;
                            }

                            .board-b {
                                margin: 0;
                            }

                            .inc-r {
                                display: flex;
                                justify-content: flex-start;
                                flex-wrap: wrap;
                            }
                        }

                        .hotel-room-details-main-price-card {
                            border: 1px solid transparent;
                            width: 42%;
                            display: block;
                            flex-wrap: wrap;
                            justify-content: space-evenly;
                            padding: 1%;

                            @media (max-width: 767px) {
                                width: 100%;
                                text-align: left;
                            }
                        }

                        .hotel-room-details-main-price-card-1 {
                            font-family: "Nunito";
                            border: 1px solid transparent;
                            width: 42%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            padding: 1%;
                            align-items: center;

                            @media (max-width: 767px) {
                                width: 100%;
                                text-align: left;
                            }
                        }


                        .chooseroom-details-hotel {
                            // border: 1px solid red;
                            border-radius: 8px;
                            background: linear-gradient(90deg, #ff7b54, #ff3e3e);
                            //background:linear-gradient(to right, #0370a9, #08acda);
                            color: white;
                            padding: 0px 16px;

                        }

                        .room-image-skel {
                            height: 100%;

                            .ant-skeleton-element,
                            .ant-skeleton-image {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .ant-skeleton-title {
                            margin-top: 0;
                        }

                        .choose-btn-s {
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            align-items: flex-end;
                            justify-content: center;

                            .ant-skeleton-title {
                                margin: 0 0 5px auto;
                            }
                        }

                        .room-Newcard-wrap {
                            display: flex;
                            justify-content: space-between;

                            @media (max-width: 567px) {
                                flex-direction: column;
                            }

                            .hotel-image-box {
                                .hotel-image {
                                    .hotel_image_ {
                                        max-width: 160px;
                                        min-width: 160px;
                                        position: relative;

                                        img {
                                            max-height: 120px;
                                            object-fit: cover;
                                        }

                                        @media (max-width: 567px) {
                                            max-width: 100%;
                                            height: 160px;

                                            img {
                                                height: 100%;
                                                max-height: 160px;
                                            }
                                        }

                                        .gallery-icon {
                                            position: absolute;
                                            right: 6px;
                                            bottom: 6px;
                                            background: rgba(28, 43, 57, 0.6);
                                            width: 30px;
                                            height: 30px;
                                            display: grid;
                                            place-items: center;
                                            border-radius: 3px;
                                            cursor: pointer;

                                            i {
                                                color: #fff;
                                                font-size: 18px;
                                            }
                                        }
                                    }

                                    img {
                                        width: 100%;
                                        border-radius: 3px;
                                    }
                                }
                            }

                            .hotel-info-wrapper {
                                flex: 1;
                                display: flex;

                                @media (max-width: 567px) {
                                    flex-direction: column;
                                }

                                .hotel-room-details {
                                    flex: 1;
                                    display: flex;
                                    justify-content: space-between;
                                    flex-direction: column;
                                    padding: 0 15px;

                                    @media (max-width: 567px) {
                                        padding: 10px 0;
                                    }

                                    .hotel-room-type {
                                        .room-title {
                                            display: flex;
                                            justify-content: space-between;
                                            width: 90%;

                                            .roomName {
                                                color: #00164d;
                                                font-weight: 700;
                                                margin-bottom: 0;
                                            }

                                        }

                                        .pax-icons {
                                            small {
                                                padding-right: 5px;
                                            }

                                            span {
                                                padding-right: 5px;
                                                padding-left: 5px;
                                            }
                                        }
                                    }

                                    .amenities-box {
                                        font-size: 12px;

                                        ul {
                                            li:first-child {
                                                color: #1da023;
                                            }

                                            li {
                                                padding-bottom: 4px;

                                                i {
                                                    background: #f5f8fd;
                                                    -moz-border-radius: 80%;
                                                    border-radius: 80%;
                                                    font-size: 9px;
                                                    padding: 5px;
                                                    margin-right: 4px;
                                                }
                                            }
                                        }

                                    }

                                    .d-flex {
                                        img {
                                            width: 20px;
                                            height: 20px;
                                            position: relative;
                                            margin-top: -3%;
                                        }
                                    }

                                    .description-modal {
                                        font-size: 12px;

                                        .tooltipWrapper {
                                            display: flex;
                                            align-items: center;

                                            .anticon {
                                                padding-right: 5px;
                                            }
                                        }

                                        a {
                                            color: #0775e2;

                                            &:hover {
                                                text-decoration: none;
                                            }

                                            span {
                                                font-size: 8px;
                                            }
                                        }
                                    }
                                }

                                .select-room-btn {
                                    text-align: right;

                                    @media (max-width: 567px) {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                    }

                                    .rooms-left {
                                        background: #f6952d;
                                        color: #fff;
                                        padding: 3px 10px;
                                        border-radius: 3px;
                                        font-size: 10px;
                                        display: inline-block;
                                        margin-bottom: 0;
                                        line-height: 1.5;
                                        vertical-align: bottom;

                                        @media (max-width: 767px) {
                                            display: none;
                                        }
                                    }

                                    .rooms-left-mobile {
                                        @media screen and (min-width: 768px) {
                                            display: none;
                                        }
                                    }

                                    .hotel-room-price {
                                        margin-bottom: 0;

                                        span {
                                            font-size: 22px;
                                            font-weight: 700;
                                            padding-left: 5px;
                                        }

                                        @media (max-width: 767px) {
                                            order: 1;
                                        }
                                    }

                                    .select-button {
                                        background: linear-gradient(to right, #320063, #0f76bb);
                                    }

                                    .active {
                                        background: linear-gradient(to right, #320063, #0f76bb) !important;
                                    }

                                    button {
                                        background: #0123ad;
                                        color: #fff;
                                        border: transparent;
                                        padding: 5px 20px;
                                        font-size: 12px;
                                        font-weight: 600;
                                        border-radius: 3px;

                                        &:hover,
                                        &:active {
                                            background: #0f76bb !important;
                                        }

                                        @media (max-width: 767px) {
                                            order: 3;
                                        }
                                    }

                                    button:disabled {
                                        cursor: not-allowed;
                                    }

                                    .nightCount {
                                        font-size: 12px;
                                        color: #969dac;
                                        margin-bottom: 6px;

                                        @media (max-width: 767px) {
                                            display: none;
                                        }
                                    }

                                    // @media (max-width: 767px) {
                                    //   display: flex;
                                    //   justify-content: space-between;
                                    //   background: #f5f8fd;
                                    //   padding: 10px;
                                    // }
                                }
                            }
                        }

                        @media (max-width: 767px) {
                            padding: 15px;
                        }
                    }
                }
            }

            .showroom-btn-wrapper {
                margin: 30px 0 20px;
                text-align: center;

                button {
                    padding: 15px 30px;
                    -moz-border-radius: 2px;
                    border-radius: 2px;
                    border: 1px solid #bcd5f5;
                    background-color: #f5f8fd;
                    color: #0775e2;
                    cursor: pointer;
                    -webkit-transition: background-color;
                    -moz-transition: background-color;
                    transition: background-color;
                    font-weight: 700;

                    &:hover {
                        background-color: #eaf2fc;
                        border-color: #7aabeb;
                        color: #0775e2;
                    }
                }
            }
        }

        @media (max-width: 767px) {
            padding: 15px;
        }
    }
}

.hotel-rooms-list {
    background-color: #ffffff;

}

.rooms-wrapper {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    padding: 20px 15px;

    .available-rooms-dates {
        margin-bottom: 15px;
    }



    @media (max-width: 767px) {
        padding: 15px;
    }
}

.rooms-list {
    .room-Newcard-wrapper {
        .ant-card-body {
            padding: 15px;
            border-radius: 8px !important;
            padding: 6px 18px !important;

            .room-number-span {
                background-color: #0123ad;
                background-image: linear-gradient(to right, #320063, #0f76bb);
                padding: 1px 10px;
                margin-bottom: 1px;
                // border-radius: 10px;
                display: inline-block;
                color: #fff;
                font-weight: 600;
                font-size: 15px;
            }

            .button-wrapper {
                button {
                    background-color: #0123ad;
                    background-image: linear-gradient(to right, #320063, #0f76bb);
                    color: #fff;
                    border: transparent;
                    padding: 5px 20px;
                    font-size: 12px;
                    font-weight: 600;
                    border-radius: 3px;

                    &:disabled {
                        cursor: not-allowed;
                    }

                    @media (max-width: 767px) {
                        order: 3;
                    }
                }
            }


        }
    }

    .showroom-btn-wrapper {
        margin: 30px 0 20px;
        text-align: center;

        button {
            padding: 15px 30px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            border: 1px solid #bcd5f5;
            background-color: #f5f8fd;
            color: #0775e2;
            cursor: pointer;
            -webkit-transition: background-color;
            -moz-transition: background-color;
            transition: background-color;
            font-weight: 700;

            &:hover {
                background-color: #eaf2fc;
                border-color: #7aabeb;
                color: #0775e2;
            }
        }
    }
}

.room-card {
    margin-bottom: 8px;
    // background-color: #fff;
    // border-radius: 3px;
    // -moz-border-radius: 3px;
    // box-shadow: 2px 2px 0px 0px #dadada;
    // -webkit-box-shadow: 0 2px 3px 0 #eaebee;
    // -moz-box-shadow: 0 2px 3px 0 #eaebee;
    // border-bottom: 1px solid #cccccc;
    // padding: 16px;
    padding: 2px;
    border-radius: 5px;
    position: relative;
    padding-bottom: 0;

    // &:hover {
    //   box-shadow: 3px 3px 1px 0px #e8e8e8;
    // }

    .room-image-skel {
        height: 100%;

        .ant-skeleton-element,
        .ant-skeleton-image {
            width: 100%;
            height: 100%;
        }
    }

    .ant-skeleton-title {
        margin-top: 0;
    }

    .choose-btn-s {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-end;
        justify-content: center;

        .ant-skeleton-title {
            margin: 0 0 5px auto;
        }
    }

    .room-Newcard-wrapper {
        display: flex;
        justify-content: space-between;

        @media (max-width: 567px) {
            flex-direction: column;
        }

        .hotel-image-box {
            .hotel-image {
                .hotel_image_ {
                    max-width: 160px;
                    min-width: 160px;
                    position: relative;

                    img {
                        max-height: 120px;
                        object-fit: cover;
                    }

                    @media (max-width: 567px) {
                        max-width: 100%;
                        height: 160px;

                        img {
                            height: 100%;
                            max-height: 160px;
                        }
                    }

                    .gallery-icon {
                        position: absolute;
                        right: 6px;
                        bottom: 6px;
                        background: rgba(28, 43, 57, 0.6);
                        width: 30px;
                        height: 30px;
                        display: grid;
                        place-items: center;
                        border-radius: 3px;
                        cursor: pointer;

                        i {
                            color: #fff;
                            font-size: 18px;
                        }
                    }
                }

                img {
                    width: 100%;
                    border-radius: 3px;
                }
            }
        }

        .hotel-info-wrapper {
            flex: 1;
            display: flex;

            @media (max-width: 567px) {
                flex-direction: column;
            }

            .hotel-room-details {
                flex: 1;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                padding: 0 15px;

                @media (max-width: 567px) {
                    padding: 10px 0;
                }

                .hotel-room-type {
                    .room-title {
                        display: flex;
                        justify-content: space-between;
                        width: 90%;

                        .roomName {
                            color: #00164d;
                            font-weight: 700;
                            margin-bottom: 0;
                        }

                    }

                    .pax-icons {
                        small {
                            padding-right: 5px;
                        }

                        span {
                            padding-right: 5px;
                            padding-left: 5px;
                        }
                    }
                }

                .amenities-box {
                    font-size: 12px;

                    ul {
                        li:first-child {
                            color: #1da023;
                        }

                        li {
                            padding-bottom: 4px;

                            i {
                                background: #f5f8fd;
                                -moz-border-radius: 80%;
                                border-radius: 80%;
                                font-size: 9px;
                                padding: 5px;
                                margin-right: 4px;
                            }
                        }
                    }

                }

                .d-flex {
                    img {
                        width: 20px;
                        height: 20px;
                        position: relative;
                        margin-top: -3%;
                    }
                }

                .description-modal {
                    font-size: 12px;

                    .tooltipWrapper {
                        display: flex;
                        align-items: center;

                        .anticon {
                            padding-right: 5px;
                        }
                    }

                    a {
                        color: #0775e2;

                        &:hover {
                            text-decoration: none;
                        }

                        span {
                            font-size: 8px;
                        }
                    }
                }
            }

            .select-room-btn {
                text-align: right;

                @media (max-width: 567px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .rooms-left {
                    background: #f6952d;
                    color: #fff;
                    padding: 3px 10px;
                    border-radius: 3px;
                    font-size: 10px;
                    display: inline-block;
                    margin-bottom: 0;
                    line-height: 1.5;
                    vertical-align: bottom;

                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                .rooms-left-mobile {
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }

                .hotel-room-price {
                    margin-bottom: 0;

                    span {
                        font-size: 22px;
                        font-weight: 700;
                        padding-left: 5px;
                    }

                    @media (max-width: 767px) {
                        order: 1;
                    }
                }

                .select-button {
                    background: linear-gradient(to right, #320063, #0f76bb);
                }

                .active {
                    background: linear-gradient(to right, #320063, #0f76bb) !important;
                }

                button {
                    background: #0123ad;
                    color: #fff;
                    border: transparent;
                    padding: 5px 20px;
                    font-size: 12px;
                    font-weight: 600;
                    border-radius: 3px;

                    &:hover,
                    &:active {
                        background: #0f76bb !important;
                    }

                    @media (max-width: 767px) {
                        order: 3;
                    }
                }

                button:disabled {
                    cursor: not-allowed;
                }

                .nightCount {
                    font-size: 12px;
                    color: #969dac;
                    margin-bottom: 6px;

                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                // @media (max-width: 767px) {
                //   display: flex;
                //   justify-content: space-between;
                //   background: #f5f8fd;
                //   padding: 10px;
                // }
            }
        }
    }

    @media (max-width: 767px) {
        padding: 15px;
    }
}

.mapWrapper {
    position: relative;
    z-index: 1;
}