// @import "admin/adminIndex.scss";

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@font-face {
  font-family:"Nunito";
  src: url(assets/fonts/Ubuntu/Ubuntu-Bold.ttf) format("truetype");

  // src: url(assets/fonts/Ubuntu/Ubuntu-Regular.ttf) format("truetype");
  src: url(assets/fonts/Ubuntu/Ubuntu-Light.ttf) format("truetype");
  // src: url(assets/fonts/Ubuntu/Ubuntu-Medium.ttf) format("truetype");
  // font-weight: normal;
}

@font-face {
  font-family:"Nunito";
  src: url(assets/fonts/NotoSans/NotoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family:"Nunito";
  src: url(assets/fonts/NotoSans/NotoSans-Bold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family:"Nunito";
  color: #1c2b39;
  font-size: 14px;
  p{
    margin: 0;
  }
}

ul {
  padding: 0;
  margin: 0 !important;
}

li {
  list-style-type: none;
}

a,
a:hover {
  text-decoration: none !important;
}

textarea:focus,
input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: transparent;
  // box-shadow: 0 0 1px #ddd, inset 0 0px 1px #ddd;
  outline: 0 none;
}

button:focus {
  outline: transparent;
}

.e-hide {
  display: none !important;
}

.e-visible {
  display: block !important;
}

.ant-table-thead>tr>th,
.ant-table tfoot>tr>th {
  padding: 6px 8px !important;
}

.ant-table-tbody>tr>td,
.ant-table tfoot>tr>td {
  padding: 4px 8px !important;
}

.ant-table-column-sorters {
  padding: 0 !important;
}

// .rc-virtual-list-holder {
//   max-height: 356px !important;
// }
@media (max-width: 991px)  {
  .e-md-visible {
    display: block !important;
  }

  .e-md-hide {
    display: none !important;
  }
}

@media (max-width: 1200px)  {
  .e-lg-visible {
    display: block !important;
  }

  .e-lg-hide {
    display: none !important;
  }
}

.payment-btn {
  // background-color: #bd0c21;
  background-color: #0123ad;
  background-image: linear-gradient(to right, #320063, #0f76bb);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 21px;
  font-weight: 600;
  padding: 10px 31px;
  height: 100%;

  @media (max-width: 767px) {
    font-size: 16px;
    padding: 10px 20px;
  }
}

.print-agentlogo {
  display: none !important;
}

@media print {
  body {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;

    .ant-card-body,
    .ant-collapse-content-box {
      padding: 0 !important;

      .ant-table-thead tr th {
        background: #d5436a !important;
        color: #ffffff !important;
      }
    }

    .print-agentlogo {
      display: block !important;

      .printImageStyle {
        width: 100px;
        height: 50px;
      }
    }

    .details-airlines-contact {
      display: flex !important;
      flex-wrap: wrap !important;
    }

    // .contact-person-d4 {
    //   flex: 0 0 calc(40% - 8px) !important;
    //   margin-bottom: 8px !important;
    // }

    .fligh-ticket-container {
      padding: 15px !important;
      margin: 0 !important;
      width: 100% !important;

      .no-tickt {
        color: #0c7cbd;
        font-weight: 600;
        font-size: 20px;
      }
    }

    .ticket-row {
      flex-direction: column !important;

      .ant-col {
        max-width: 100% !important;
      }

      .tic-info-flight {
        display: none !important;
      }
    }

    .header-bg {
      display: none !important;

      .header-container {

        .top-nav-sec,
        .user-icon,
        .app-links {
          display: none !important;
        }
      }
    }

    #footer,
    .ant-dropdown {
      display: none !important;
    }

    #admin {
      .ant-layout {
        .ant-layout-sider {
          display: none !important;
        }
      }

      .ant-layout-header {
        display: none !important;
      }

      .footer {
        display: none !important;
      }
    }
  }
}


iframe {
  width: 100%;
  height: 400px;
}

.text-line {
  text-decoration: line-through;
}

.word-break {
  word-break: break-word;
}

.collapseCustomWhite {
  padding-top: 80px;

  @media (max-width: 767px) {
    padding-top: 5px;
  }
}
.ant-switch-checked {
  background-color: #35459c !important;
}

.ant-modal-title {
  color: #ffffff;
}

.ant-btn-primary {
  color: #ffffff;
  border-color: #0c7cbd;
  background:  linear-gradient(90deg, #ff7b54, #ff3e3e) !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(1, 1, 1, 0.333);

  &:active {
    color: #35459c;
    border-color: #0c8bbd;
    background: #fbfbff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(1, 1, 1, 0.333);
  }
}

.ant-btn-dangerous {
  color: #ffffff;
  border-color: #a20013;
  background: #bd8b0c !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(1, 1, 1, 0.333);
}

.ant-btn-danger {
  color: #ffffff;
  border-color: #35459c;
  background: #35459c !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(1, 1, 1, 0.333);
}