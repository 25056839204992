
.subscribe-footerWeb {
    padding: 5px 0;
    background-image: url('https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/bg/footer-bg.jpg');
    // background-size: cover;
    background-position: center;
    color: white;

    @media (max-width: 768px) {
        display: none;
    }

    @media (max-width: 480px) {
        display: none;
    }

    .zigZag {
        rotate: 180deg;
        top: -12px;
        position: relative;
        width: 100%;


    }

    .zigZagD {
        position: relative;
        margin-bottom: -18px;
        width: 100%;
        filter: invert(.9);
    }
}

.containerSub {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100%;
    // max-width: 1200px; /* Adjust based on your design */
    // margin: 0 auto;
}

.rowSub {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    @media screen and (max-width: 768px) {
        display: block;

    }

}

.colSub {
    display: flex;
    justify-content: space-between;
    margin: 20px auto 0px auto;

}



.service-left ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 30px;
    /* Space between services */
    flex-wrap: nowrap;
}

// .service-left ul li {
//     width: 40%; /* Each service takes up 30% of the width */
//     // margin-bottom: 20px;
// }

.service-box {
    display: flex;
    padding: 0 50px;
    // border-right: 1px solid white;
    margin-right: 2px;

    .icon {
        svg {
            fill: white;
            width: 40px;
            height: 40px;
        }
    }

    .detail {
        margin-left: 12px;
        display: flex;
        align-items: center;

        h5 {
            font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
            text-transform: uppercase;
            color: #fff;
            font-weight: 800;
            margin-bottom: 0;
            font-family: Nunito, sans-serif !important;
            margin: 0px;
        }

        p {
            font-size: 12px;
            color: rgba(white, 0.6);
            margin-bottom: 0;
            // text-transform: capitalize;
            font-family: Nunito, sans-serif !important;
        }
    }
}

.service-box .icon {
    margin-right: 10px;
    font-size: 24px;
    /* Adjust icon size */
}



.service-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.subscribe-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    /* Adjust based on your design */
}

.form-subscribe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.input-email {
    padding: 12px 20px;
    background-color: rgba(255, 255, 255, 0.16);
    /* Light white background */
    color: rgba(255, 255, 255, 0.7);
    /* Light white text */
    border: none;
    border-radius: 50px 0 0 50px;
    /* Rounded on the left */
    flex: 1;
    /* Takes full width before the button */
    font-size: 16px;
}

.input-email::placeholder {
    color: rgba(255, 255, 255, 0.4);
    opacity: 1;
}

.input-email:focus {
    outline: none;
    box-shadow: none;
    /* Removes default focus shadow */
}

.btn-subscribe {
    padding: 12px 20px;
    background-color: #fff;
    color: rgba(var(--theme-color1), 1);
    /* Assuming theme color is set */
    border: none;
    border-radius: 0 50px 50px 0;
    /* Rounded on the right */
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 49px;
    margin-bottom: 24px;
}

.btn-subscribe:hover {
    background-color: #f15a29;
    /* Change hover color if desired */
    color: #fff;
    /* Invert text color on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .service-right {
        justify-content: center;
    }

    .subscribe-form {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }

    .input-email {
        border-radius: 50px;
        margin-bottom: 10px;
        flex: none;
        width: 100%;
    }

    .btn-subscribe {
        border-radius: 50px;
        width: 100%;

    }
}



// .form-css-work-subscribe {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }



// .name-sub-date {
//     border-radius: 25px;
//     padding: 10px 15px;
//     width: 100%;
// }

.cribe-btn-email {
    background-color: #f15a29;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cribe-btn-email:hover {
    background-color: #d14924;
}

@media (max-width: 768px) {

    .service-left,
    .service-right {
        max-width: 100%;
    }

    .rowSub {
        flex-direction: column;
        align-items: center;
    }

    .service-left ul {
        justify-content: center;
    }

    .service-left ul li {
        width: 100%;
        /* Stacks services vertically on smaller screens */
    }
}

.stay-best-emailMob {
    background: #003b95;
    margin-bottom: 0px;
    // padding-bottom: 20px;
    // margin-top: 30px;
    padding: 35px 0;

    @media (min-width: 1024px) {
        display: none;
    }
    @media (min-width: 991px) {
        display: none;
    }
    @media (min-width: 770px) {
        display: none;
    }
   
}