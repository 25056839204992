 

.domestic-airsearch {
  margin-bottom: 20px !important;
  

  @media (max-width: 767px) {
    margin-bottom: -3px !important;
    margin-top: 5px;

  }

  h3 {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
    font-family: "Nunito";
   
    font-size: 30px;
    line-height: 40px;
    color: #000000;
  }

  .ducarosuel {
    // box-shadow: 0 1px 4px rgb(0, 0, 0 / 10%);
    border-radius: 6px;
    padding: 20px;
    justify-content: space-between;
    border: 1px solid #eae8e8;
    margin: 2px 9px 5px;
    cursor: pointer;
    transition: all 0.5s ease;
    // background: linear-gradient(137deg, #ec9aff70, #94b5ff);
    background-color: #ffff;

    // background: linear-gradient(309deg, #35459c70, #5087ff5c);
    &:hover {
      box-shadow: 2px 3px 4px grey;
    }
  }

  .origin-item-sel {
    width: 100%;
    display: block;
    flex-wrap: nowrap;
    padding-left: 12px;
    border-left: 2px dashed #979797;
    position: relative;
    padding-left: 25px;

    .fli8-ic {
      // rotate: 90deg;
      position: absolute;
      left: -9px;
      top: 39%;

      @media (max-width: 767px) {
        left: -6%;
      }
    }
  }

  .origin-item-sel::before {
    content: " ";
    position: absolute;
    background-color: #979797;
    padding: 3px;
    border-radius: 50%;
    top: 0;
    left: -4px;
  }

  .origin-item-sel::after {
    content: " ";
    background-color: #979797;
    padding: 3px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: -4px;
  }

  .city-due {
    h6 {
      font-size: 16px;
      font-family: Nunito, sans-serif;

    }

    display: inline-block;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    color: #6b6b6b;
    height: 16px;
    font-family: Nunito,
    sans-serif;
    // font-weight: 600;
  }

  .time-due {
    margin: 5px 0 2px;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
  }

  .price_go_test {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-left: 14px;
    align-items: flex-end;
  }

  .starting-test {
    font-size: 18px;
    color: #f34f4f;
    text-align: left;
    display: block;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 0px;
  }

  span {
    width: 100%;
    font-weight: 400;
    display: block;
    color: #444;
    font-size: 12px;
  }

  .rs-price {
    font-size: 20px;
    color: #bd0c21;
  }
}

.bubbles-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.bubble {
  width: 100px;
  height: 100px;
  overflow: visible;
}

.bubble circle {
  fill: #9fc1f9;
  /* Blue color for the bubble */
}

.bubble-liquid circle {
  fill: #2a75bb;
  /* Darker blue color for the liquid */
  opacity: 0.8;
  /* Adjust opacity as needed */
  transform-origin: center;
  animation: liquidAnimation 2s infinite alternate ease-in-out;

}

@keyframes liquidAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

.flight-routes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  @media screen and (max-width: 767px) {
    padding: 10px;
    
  }
}

.flight-route-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  height: 91px;
  border-radius: 8px;
  width: 33%;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
  &:hover{
    box-shadow: 2px 3px 8px rgb(210, 206, 255);
  }
  @media screen and (max-width: 1024px) {
    width:46%;
    
  }
  @media screen and (max-width: 991px) {
    width:46%;
    
  }
  @media screen and (max-width: 770px) {
    width:46%;
    
  }
  @media screen and (max-width: 767px) {
    width:100%;
    
  }
}



.image-container img {
  position: relative;
  width: 77%;
  height: 89px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  object-fit: cover;
  overflow: hidden;

}

.image-container img {

  object-fit: cover;
  transition: all 0.3s ease-in-out;

  /* Smooth transition for hover effect */
}

.image-container img:hover {
  transform: scale(1.2);
  /* Scale the image */
  object-fit: cover;
  overflow: hidden;
  /* Ensure it maintains its proportions */
}

// .flit-infoRts {
//  // padding: 10px;
// }

.flit-infoRts h3 {
  margin: 10px 0;
  font-size: 20px;
  color: #002e58;
  text-align: start;
  font-family: "Nunito";
  font-weight: 700;
}

.flit-infoRts {
  .fly-txt-top {
    font-size: .7rem;
    color: #007bff;
    text-align: start;
  }
}

.flit-infoRts a {
  color: #007bff;
  text-decoration: none;

  &:hover {
    color: #002e58;
  }

}

.flit-infoRts a:hover {
  text-decoration: underline;
}