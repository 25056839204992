 

.edit-onward {
  margin-bottom: 15px;

  .card-results {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
    padding: 15px;
    margin-bottom: 15px;

    .flight-border-right {
      border-right: 1px solid #eee;

      @media (max-width: 767px) {
        border-right: none;
      }
    }

    .selected-flights-price-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      @media (max-width: 567px) {
        display: block;
      }

      .selected-flights-price {
        text-align: center;

        @media (max-width: 567px) {
          padding-top: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #eee;
        }

        .price-box {
          .totalFare {
            font-weight: 600;

            @media (max-width: 567px) {
              margin-bottom: 0;
            }
          }

          .choose-button {
            //background-color: #0123ad;
            //background: linear-gradient(to right, #0370a9, #08acda);
            background: linear-gradient(90deg, #ff7b54, #ff3e3e);
            border: none;
            border-radius: 3px;
            color: white;
            font-size: 15px;
            font-weight: 700;
            padding: 4px 13px;
            margin: 10px 0px;
            &:hover {
              background: linear-gradient(to right, #0370a9, #08acda)!important;
              color: white;
            }

          }
        }

        .card-results-travel-wrapper {
          .title {
            font-weight: 600;
            margin-bottom: 3px;
            font-size: 14px;
            color: #bd0c21;

            @media (max-width: 767px) {
              display: inline-block;
              margin-right: 5px;
            }
          }

        }
      }
    }

    .card-results-travel-wrapper {
      .title {
        font-weight: 600;
        margin-bottom: 3px;
        font-size: 14px;
        color: #d5436a;

        @media (max-width: 767px) {
          display: inline-block;
          margin-right: 5px;
        }
      }

      .card-results-travel-details {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
          flex-direction: column;
        }

        // .onward_wrapper {
        //   padding-right: 10px;
        //   border-right: 2px solid #eee;
        //   color: #d5436a;
        //   @media (max-width: 767px) {
        //     padding-right: 5px;
        //     border: none;
        //   }
        //   p {
        //     font-weight: 600;
        //     margin-bottom: 3px;
        //     font-size: 14px;
        //     @media (max-width: 767px) {
        //       display: inline-block;
        //       margin-right: 5px;
        //     }
        //   }
        // }
        .itinary_wrapper {
          flex: 1;
          max-width: 700px;
          margin-right: auto;

          @media (max-width: 767px) {
            max-width: 100%;
            margin-right: 0;
          }

          .itinary-details {
            display: flex;
            flex: 1;

            @media (max-width: 767px) {
              flex-direction: column;
            }

            .itinary-flight-name {
              text-align: center;

              @media (max-width: 767px) {
                display: flex;
                align-items: center;
              }

              .airline-img {
                max-width: 25px;
              }

              .name {
                font-weight: 600;
                font-size: 14px;
              }

              p {
                margin-bottom: 0;
                padding: 2px;
              }
            }

            .fromTo-wrapper {
              flex: 1;

              .cities-from-to {
                display: flex;
                flex-direction: column;
                position: relative;
                padding-right: 10px;
                flex: 1;

                .loc_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .name {
                    font-weight: 600;
                    margin-right: 5px;
                  }

                  .code {
                    font-size: 12px;
                    font-weight: 600;
                  }
                }
              }
            }
          }

          .flight-stops-wrapper {
            text-align: right;
            margin-top: 5px;

            .stops-wrapper {
              font-weight: 900;
              font-size: 11px;
              letter-spacing: 0.5px;
            }
          }

          // .slectedtiming {
          //   display: flex;
          //   align-items: center;
          //   justify-content: space-between;
          //   padding: 0 10px;
          //   .flight-timings {
          //     flex: 1;
          //   }
          //   .timing_wrapper {
          //     flex-wrap: wrap;
          //     .plane-timings {
          //       p {
          //         font-size: 14px;
          //       }
          //     }
          //   }
          //   .travel-time {
          //     p {
          //       font-size: 14px;
          //     }
          //   }
          //   .details-btn {
          //     display: none !important;
          //   }
          // }
        }
      }
    }
  }

  .card-results-travel-wrapper {
    .title {
      font-weight: 600;
      margin-bottom: 3px;
      font-size: 14px;
      color: #bd0c21;

      @media (max-width: 767px) {
        display: inline-block;
        margin-right: 5px;
      }
    }

    .card-results-travel-details {
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      // .onward_wrapper {
      //   padding-right: 10px;
      //   border-right: 2px solid #eee;
      //   color: #d5436a;
      //   @media (max-width: 767px) {
      //     padding-right: 5px;
      //     border: none;
      //   }
      //   p {
      //     font-weight: 600;
      //     margin-bottom: 3px;
      //     font-size: 14px;
      //     @media (max-width: 767px) {
      //       display: inline-block;
      //       margin-right: 5px;
      //     }
      //   }
      // }
      .itinary_wrapper {
        flex: 1;
        max-width: 700px;
        margin-right: auto;

        @media (max-width: 767px) {
          max-width: 100%;
          margin-right: 0;
        }

        .itinary-details {
          display: flex;
          flex: 1;

          @media (max-width: 767px) {
            flex-direction: column;
          }

          .itinary-flight-name {
            text-align: center;

            @media (max-width: 767px) {
              display: flex;
              align-items: center;
            }

            .airline-img {
              max-width: 25px;
            }

            .name {
              font-weight: 600;
              font-size: 14px;
            }

            p {
              margin-bottom: 0;
              padding: 2px;
            }
          }

          .fromTo-wrapper {
            flex: 1;

            .cities-from-to {
              display: flex;
              flex-direction: column;
              position: relative;
              padding-right: 10px;
              flex: 1;

              .loc_wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .name {
                  font-weight: 600;
                  margin-right: 5px;
                }

                .code {
                  font-size: 12px;
                  font-weight: 600;
                  
                }
              }
            }
          }
        }

        .flight-stops-wrapper {
          text-align: right;
          margin-top: 5px;

          .stops-wrapper {
            font-weight: 900;
            font-size: 11px;
            letter-spacing: 0.5px;
          }
        }

        // .slectedtiming {
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-between;
        //   padding: 0 10px;
        //   .flight-timings {
        //     flex: 1;
        //   }
        //   .timing_wrapper {
        //     flex-wrap: wrap;
        //     .plane-timings {
        //       p {
        //         font-size: 14px;
        //       }
        //     }
        //   }
        //   .travel-time {
        //     p {
        //       font-size: 14px;
        //     }
        //   }
        //   .details-btn {
        //     display: none !important;
        //   }
        // }
      }
    }
  }
}

.btncontainer {
  display: flex;
  justify-content: space-between;
  font-family: "Nunito";

  @media (max-width: 767px) {
    flex-direction: row;
    padding: 10px 10px 0 10px;
  }

  .price {
    font-size: 14px;
    font-weight: 800;
    border-radius: 40px;
  }
}

.returntext {
  color: #bd0c21;
  font-weight: 600;
}

.selected-flights-price-wrapper {
  .selected-flights-price {
    .ant-btn-primary {
      //background-color: #0123ad;
      //background: linear-gradient(to right, #0370a9, #08acda) !important;
      background: linear-gradient(90deg, #ff7b54, #ff3e3e)!important;
      border: none;
      border-radius: 3px;
      color: white;
      font-size: 15px;
      font-weight: 700;
      padding: 3px 13px;
      margin: 10px 0px;
      &:hover {
        background: linear-gradient(to right, #0370a9, #08acda) !important;
        color: white;
      }
    }
  }
}

.mob-chn-wor {
  // background-color: #bd0c21;
  color: #bd0c21;
  border-radius: 8px;

  @media (max-width: 767px) {
    background-color: #bd0c21;
    border-radius: 40px;
    color: white;
  }
}
.SepView-Rndtrip{
  .onward-fig{
    border-radius: 19px;
    margin-right: 10px;
    border-top: 3px solid #0370a9;
    padding: 2px;
    width: 50%;
    .onw-txt-hdr{
      color: white;
      margin: 0;
      display: flex;
      border: 1px solid #0370a9;
      border-radius: 14px;
      max-width: fit-content;
      padding: 0px 10px;
      background: linear-gradient(45deg, #0370a9, #08acda);
      margin-top: -17px;
      margin-left: 20px;
    }
  }
  .return-fig{
    width: 50%;
    border-radius: 19px;
    margin-right: 10px;
    border-top: 3px solid #0370a9;
    padding: 2px;
    .ret-txt-hdr{
      color: white;
      margin: 0;
      display: flex;
      border: 1px solid #0370a9;
      border-radius: 14px;
      max-width: fit-content;
      padding: 0px 10px;
      background: linear-gradient(45deg, #0370a9, #08acda);
      margin-top: -17px;
      margin-left: 20px;
    }

  }
}

.txt-cob-fli8{
  // border: 1px solid rgb(161, 161, 161);
  // border-radius: 6px;
  &:hover{
    box-shadow: 0px 1px 8px grey;
  }
}
.edit-Sep-Rndtp {
  position: sticky;
    bottom: 8px;
    z-index: 9;
    // width: 100%; /* Full width */
    // background-color: #fff; /* Background color of the fixed div */
    // box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: shadow for visual separation */
    // padding: 10px; 
    // width: 60%;
  .card-results-sep {
    background: linear-gradient(0deg, #f7fbff, #5ba3f87a);
    background-color: #fcfcfc;
    border-radius: 8px;
    box-shadow: 3px 5px 12px #333333ad;
    padding: 15px;
    margin-bottom: 15px;

    .flight-border-right {
      border-right: 1px solid #eee;

      @media (max-width: 767px) {
        border-right: none;
      }
    }

    .selected-flights-price-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      @media (max-width: 567px) {
        display: block;
      }

      .selected-flights-price {
        text-align: center;

        @media (max-width: 567px) {
          padding-top: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #eee;
        }

        .price-box {
          .totalFare {
            font-weight: 800;
            font-family: "Nunito";
            font-size: 16px;
            margin-bottom: 0;

            @media (max-width: 567px) {
              margin-bottom: 0;
            }
          }
        }

       

        .ant-btn-primary:hover {
          background-color: white;
          color: #ffffff;
          // border: 2px solid #2e0000;
        }
      }
    }

    .card-results-travel-wrapper {
      .title {
        font-weight: 600;
        margin-bottom: 3px;
        font-size: 14px;
        color: #330166;

        @media (max-width: 767px) {
          display: inline-block;
          margin-right: 5px;
        }
      }

      .card-results-travel-details {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
          flex-direction: column;
        }

        .itinary_wrapper {
          flex: 1;
          max-width: 700px;
          margin-right: auto;

          @media (max-width: 767px) {
            max-width: 100%;
            margin-right: 0;
          }

          .itinary-details {
            display: flex;
            flex: 1;

            @media (max-width: 767px) {
              flex-direction: column;
            }

            .itinary-flight-name {
              text-align: center;

              @media (max-width: 767px) {
                display: flex;
                align-items: center;
              }

              .airline-img {
                max-width: 25px;
              }

              .name {
                font-weight: 600;
                font-size: 14px;
              }

              p {
                margin-bottom: 0;
                padding: 2px;
              }
            }

            .fromTo-wrapper {
              flex: 1;

              .cities-from-to {
                display: flex;
                flex-direction: column;
                position: relative;
                padding-right: 10px;
                flex: 1;

                .loc_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-around;

                  .name {
                    font-weight: 600;
                    margin-right: 5px;
                  }

                  .code {
                    font-size: 16px;
                    font-weight: 800;
                    font-family: "nunito";
                      color: #003b95;
                  }
                }
              }
            }
          }

          .flight-stops-wrapper {
            text-align: right;
            margin-top: 5px;

            .stops-wrapper {
              font-weight: 900;
              font-size: 11px;
              letter-spacing: 0.5px;
            }
          }

        }
      }
    }
  }

  .btncontainer {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: row;
      padding: 10px 10px 0 10px;
    }

    .price {
      font-size: 14px;
      font-weight: 700;
      margin: 0;

      @media (max-width: 767px) {
        flex: 1;
      }

      span {
        font-size: 12px;
        margin-right: 5px;
      }
    }

    .tg-primary-btn {
      font-size: 12px;
      background-color: transparent;
      border: none;
      outline: none;
      color: #2e0000;
      font-weight: 600;
    }
  }

  .returntext {
    color: #330166;
    font-weight: 600;
  }
}
.refundRText {
  font-family: "Nunito";
  display: flex;
  // justify-content: center;
  margin-top: 4px;
  font-size: 12px;

  .refund {
    // border: 1px solid #35459c;
    color: #00b436;
    // background: #8591d5b6;
    // padding: 0px 14px;
    // border-radius: 15px;
    margin: 0;
  }

  .Nrefund {
    // border: 1px solid #bd0c21;
    color: #bd0c21;
    // background: #f44b5fbf;
    // padding: 0px 14px;
    // border-radius: 15px;
    margin: 0;
    font-size: 11px;
  }
}
.time-txtS{
  display: grid;
}