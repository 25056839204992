

.checkout-heading {
  // background: linear-gradient(to right, #bd0c21, #35459c);
  // background: linear-gradient(90deg, #09b0dd, #026ba5);;
  // padding: 15px 0;
  // padding-top: 30px;
  background: linear-gradient(90deg, #102443, #026ba5);
  padding: 8px 0;
  padding-top: 25px;

  @media (max-width: 770px) {
    padding-top: 48px;
  }

  @media (max-width: 991px)  {
    padding-top: 60px;
  }

  @media (max-width: 767px) {
    padding-top: 30px;
  }

  .checkout-heading-container {
    max-width: 1300px;
    margin: auto;
    padding: 0 15px;

    .goback {
      margin-bottom: 10px;
      font-size: 18px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      .anticon-arrow-left {
        margin-right: 7px;
      }

      a {
        color: #fff;
        display: flex;
        align-items: center;
      }
    }
    .txt-gp{
      font-size: 12px;
    }
  }

  h3 {
    color: #fff;
    margin: 0px;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.tot-fare-tab {
  .tab {
    display: flex;
    justify-content: space-between;

    .amount-tag {
      font-size: 20px;
    }

    .amount-ta {
      font-weight: 600;
      font-size: 14px;
      font-family: 'Nunito';
    }

    .amou {
      font-size: 16px;
    }

    .amount {
      font-weight: 600;
      font-size: 18px;

      .txt-inr {
        font-size: 12px;
        font-weight: 200;
      }

    }
  }
}

.checkout-section {
  .service-cahrge-table {
    .service-charge-header {
      background-color: #bd0c21;

      ul {
        display: flex;
        justify-content: space-between;
        padding: 15px;

        li {
          color: #fff;
        }
      }
    }

    .service-charge-body {
      ul {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        border: 1px solid #969dac;
        border-top: none;
      }
    }
  }
}

.flight-checkout {
  width: 100%;
  height: 100%;
  margin-top: 47px;
  margin-bottom: 60px;
}

.checkout-body-sec {
  background: #ffffff;
  // padding: 20px 35px;

  @media (max-width: 767px) {
    padding: 15px 0px;
  }

  .checkout-container {
    max-width: 1300px;
    margin: auto;
    padding: 0 15px;

    .checkout-bottom-part {
      width: 65%;

     @media (max-width: 991px)  {
        width: 100%;
      }

      @media (max-width: 991px)  {
        width: 100%;
      }

      @media (max-width: 767px) {
        order: 1;
      }
    }

    .checkout-top-part {
      width: 35%;

     @media (max-width: 991px)  {
        width: 100%;
      }

      @media (max-width: 991px)  {
        width: 100%;
      }

      @media (max-width: 767px) {
        order: 1;
      }
    }

    .checkout-sticky-part {
      // position: sticky;
      // top: 0;
      //padding: 10px 24px 24px;
      background-color: white;

      .ant-card-body {
        padding: 0;
      }

      .heading-3 {
        //border-bottom: 1px solid grey;
        font-weight: 700;
        font-size: 20px;
        font-family: 'Nunito';
        // background: linear-gradient(90deg, #09b0dd, #026ba5);
        background: #f9f9f9;
        padding: 15px;
        color: rgb(0, 0, 0);
        margin: 0px;
      }

      .grand-total-card {
        p {
          margin-bottom: 0;
        }

        .pax-total-price {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;

          .pax-type {
            p {
              font-size: 18px;
              margin-bottom: 0;
            }
          }

          .total {
            display: flex;

            p {
              font-size: 20px;
            }

            .amount {
              font-weight: 700;
            }
          }
        }
      }
    }

    .user-details {
      // margin-bottom: 15px;
      // margin-top: 20px;

      .inner-part {
        // margin-top: 36px;
        background: #ffffff;
        // border: 1px solid #35459c;
        //  padding: 10px;
        border-radius: 6px;
        box-shadow: 0 1px 9px 3px hsla(0, 0%, 89%, .81);
      }

      .inner-part-pass {
        //border: 1px solid #35459c;
        //  padding: 10px;
        border-radius: 6px;
        box-shadow: 0 1px 9px 3px hsla(0, 0%, 89%, .81);
        // margin-top: 40px;

        .heding1 {
          color: #efefef;
          font-weight: 500;
          font-size: 20px;
          margin-top: -26px;
          background: linear-gradient(to right, #bd0c21, #35459c);
          border-radius: 4px;
          width: fit-content;
          padding: 0 10px 0 10px;

          @media (max-width: 767px) {
            margin-top: -21px;
            font-size: 16px;
            width: auto;
          }
        }

        .pax-head-title {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .heading {
        // color: #efefef;
        font-weight: 500;
        font-size: 20px;
        font-weight: 800;
        font-family: 'Nunito';
        //margin-top: -26px;
        //background: linear-gradient(to right, #bd0c21, #35459c);
        color: #000000;
        border-radius: 4px;
        width: fit-content;
        padding: 10px;
        margin: 0px;

        @media (max-width: 767px) {
          margin-top: -21px;
          font-size: 16px;
          width: auto;
        }
      }

      .ant-input-group-wrapper {
        .ant-input-group-addon {
          width: 35%;
          padding: 0;
        }
      }

      .checkout-custom-card {
        padding: 16px 24px;
        box-shadow: 0 2px 2px 0 #dcdee3;
        border: none;
        margin-bottom: 20px;

        .ant-card-body {
          padding: 0;
        }

        .card-adult-text {
          margin-bottom: 13px;

          @media (max-width: 767px) {
            flex-direction: row;
            flex-wrap: inherit;
          }

          .col-md-3,
          .col-md-9 {
            @media (max-width: 767px) {
              padding: 0px;
            }
          }
        }

        .pax-heading {
          font-weight: 600;
          margin-bottom: 4px;
        }

        .pax-head-hr {
          hr {
            border-top: 1px solid #bcd5f5;
            max-width: 100%;
          }
        }

        .pax-info-form {
          .form-group {
            margin-bottom: 25px !important;

            @media (max-width: 767px) {
              margin-bottom: 0px !important;
            }

            .plaintext {
              border: none !important;
            }

            input[type="text"] {
              border: solid 1px #bcd5f5 !important;

              // height: 45px;
              @media (max-width: 767px) {
                font-size: 15px;
                height: 45px;
              }
            }

            label {
              color: #505c75;

              @media (max-width: 767px) {
                font-weight: 600;
              }

              span {
                color: red;
                font-size: 18px;
              }
            }

            select {
              border: solid 1px #bcd5f5;

              @media (max-width: 767px) {
                height: 45px;
                font-size: 15px;
              }
            }

            .title-select {
              max-width: 130px;
              border: solid 1px #bcd5f5;

              @media (max-width: 767px) {
                max-width: 100%;
              }
            }

            .dob-select {
              display: flex;

              @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
              }

              .title-select {
                margin-right: 10px;

                @media (max-width: 767px) {
                  margin-bottom: 18px;
                  margin-right: 0px;
                }
              }

              input {
                max-width: 130px !important;

                @media (max-width: 767px) {
                  max-width: 100%;
                }
              }
            }

            .Country-select {
              max-width: 200px !important;

              @media (max-width: 767px) {
                max-width: 100%;
              }
            }
          }

          .contact-details-text {
            p {
              font-size: 13px;
              color: #969dac;

              @media (max-width: 767px) {
                font-size: 12px;
                margin-top: 10px;
              }
            }
          }

          .register-check {
            display: flex;

            p {
              font-size: 15px;
            }

            span {
              color: #bd0c21;
              font-weight: 700;
            }
          }
        }

        .hr-line {
          margin-top: 0px !important;

          @media (max-width: 767px) {
            display: none;
          }
        }

        .airline-bottom-section {
          .airline-bottom-section-left {
            .trip-timing {
              text-align: center;

              p {
                font-weight: 700;
              }

              .trip-row-mobile {
                @media (max-width: 767px) {
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                }

                .col-md-2 {
                  i {
                    @media (max-width: 767px) {
                      margin-left: -33px;
                    }
                  }
                }

                .arrive-time {
                  p {
                    @media (max-width: 767px) {
                      margin-left: -38px;
                    }
                  }
                }
              }
            }

            .trip-duration {
              display: flex;

              span {
                color: #969dac;
                font-size: 13px;
              }

              p {
                font-size: 14px;
                padding-left: 4px;
              }
            }
          }

          .airline-bottom-section-right {
            text-align: right;

            button {
              background: none;
              color: #bd0c21;
              font-size: 13px;
              font-weight: 700;
              padding: 6px 16px;

              &:hover {
                background-color: #bd0c21;
                color: #fff;
              }
            }
          }
        }
      }

      h3 {
        @media (max-width: 767px) {
          margin-bottom: 12px;
        }
      }
    }


    .change-flight-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // background:linear-gradient(90deg, rgb(9, 176, 221), rgb(2, 107, 165));
      background: #f9f9f9;
      padding: 14px;

      h3 {
        font-size: 20px;
        font-weight: 700;
        font-family: 'Nunito';
        color: rgb(0, 0, 0);
        margin:0px;
      }

      p {
        color: #bd0c21;
        cursor: pointer;
      }
    }

    .flight-details-card {

      // margin-bottom: 20px;
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .book-pay-section {
      margin: 25px 0px 0;
      height: 133px;
      padding-top: 1px;
      //box-shadow: 1px 1px 8px grey;
      border-radius: 2px;



      .ant-card-body {
        padding: 0;
      }

      .book-pay-card {
        // padding: 16px 24px;
        // box-shadow: 0 2px 2px 0 #dcdee3;
        border: none;

        @media (max-width: 767px) {
          padding: 16px;
        }

        .book-pay-tc {
          display: flex;
          justify-content: center;

          span {
            color: black;
            font-size: 16px;
            font-family: 'Nunito';
            font-weight: 700;
          }

          p {
            margin-bottom: 0;
          }

        }

        .book-pay-offers {
          display: flex;

          p {
            font-size: 15px;

            a {
              &:hover {
                color: #bd0c21;
              }
            }
          }
        }

        .book-pay-btn {
          display: flex;
          justify-content: center;

          // button {
          //   background-color: #bd0c21;
          //   color: #fff;
          //   border: none;
          //   font-size: 20px;
          //   font-weight: 600;
          //   padding: 10px 100px;
          //   height: 100%;

          //   @media (max-width: 767px) {
          //     font-size: 16px;
          //     padding: 10px 100px;
          //   }
          // }


          button {
            // background-color: #0123ad;
            background: linear-gradient(90deg, rgb(9, 176, 221), rgb(2, 107, 165));
            color: #fff;
            border: none;
            font-size: 20px;
            font-weight: 600;
            padding: 10px 20px;
            height: 100%;

            &:hover {
              background: linear-gradient(90deg, #ff7b54, #ff3e3e) !important;
              color: white;
            }

            @media (max-width: 767px) {
              font-size: 16px;
              padding: 10px 15px;
            }

          }

          .book-and-content {
            padding-top: 20px;

            p {
              font-size: 15px;
              color: #969dac;
            }
          }
        }
      }
    }

    .book-pay-section1 {
      margin: 25px 0px 0;
      height: 84px;
      //padding-top: 28px;
      box-shadow: 1px 1px 8px grey;
      border-radius: 2px;


      .ant-card-body {
        padding: 0;
      }

      .book-pay-card {
        // padding: 16px 24px;
        // box-shadow: 0 2px 2px 0 #dcdee3;
        border: none;

        @media (max-width: 767px) {
          padding: 16px;
        }

        .book-pay-tc {
          display: flex;
          justify-content: center;

          span {
            color: black;
          }

          p {
            margin-bottom: 0;
          }
        }

        .book-pay-offers {
          display: flex;

          p {
            font-size: 15px;

            a {
              &:hover {
                color: #bd0c21;
              }
            }
          }
        }

        .book-pay-btn {
          display: flex;
          justify-content: center;

          // button {
          //   background-color: #bd0c21;
          //   color: #fff;
          //   border: none;
          //   font-size: 20px;
          //   font-weight: 600;
          //   padding: 10px 100px;
          //   height: 100%;

          //   @media (max-width: 767px) {
          //     font-size: 16px;
          //     padding: 10px 100px;
          //   }
          // }


          button {
            // background-color: #0123ad;
            background-image: linear-gradient(90deg, rgb(9, 176, 221), rgb(2, 107, 165));
            color: #fff;
            border: none;
            font-size: 20px;
            font-weight: 600;
            padding: 10px 20px;
            height: 100%;

            &:hover {
              background: linear-gradient(90deg, #ff7b54, #ff3e3e) !important;
              color: white;
            }

            @media (max-width: 767px) {
              font-size: 16px;
              padding: 10px 15px;
            }

          }

          .book-and-content {
            padding-top: 20px;

            p {
              font-size: 15px;
              color: #969dac;
            }
          }
        }
      }
    }
  }
}

.termslinks {
  color: rgb(0, 59, 149);

  &:hover {
    color: #007bff;
  }
}

.payer-radio-group {
  @media (max-width: 767px) {
    flex-wrap: inherit;
  }
}

.desktop_crou {
  .slide-wrapper {
    // height: 98%;
    // width: 98%;
    padding: 4px;
  }

  @media (max-width: 767px) {
    display: none;
  }

  // @media (max-width: 991px)  {
  //   display: none;
  // }
}

.mobile_crou {
  .slide-wrapper {
    // height: 98%;
    // width: 98%;
    padding: 4px;

    .carousel-img {
      width: 100%;
      height: 200px;
    }
  }

  .text-flight {
    h5 {
      font-size: 18px;
    }

    .slick-arrow {
      width: 40px !important;
      height: 40px !important;
    }

    .slick-prev {
      left: 8px !important;
    }

    .slick-next {
      right: 8px !important;
    }
  }

  display: none;

  @media (max-width: 767px) {
    display: block;
  }

  // @media (max-width: 991px)  {
  //   display: block;
  // }
}

.mdl-footer {
  display: flex;
  justify-content: space-between;

  .ssr-block {
    cursor: pointer;
    //     border: 1px solid #35459c;
    //     border-radius: 4px;
    //     padding: 0 4px;
    //     background: linear-gradient(231deg, #bd0c21, #ffceceb8);
    //     color: #fff;
  }
}

.user-details1 {
  // border: 1px solid #35459c;
  border-radius: 4px;
  // padding: 4px;
  background: white;

  .extraser {
    // background: linear-gradient(to right, #bd0c21, #35459c);
    color: rgb(0, 0, 0);
    //border-radius: 4px;
    width: fit-content;
    //padding: 0 4px;
    font-size: 18px;
    font-family: "Nunito";
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .ssr-div {
    // display: flex;
    padding: 20px;
    justify-content: start;
    background: #ffffff;
    // max-width: 50%;

    @media (max-width: 767px) {
      max-width: 100%;
    }

    .ssr-block {
      //border: 1px solid #35459c;
      border-radius: 4px;
      padding: 1px 4px;
      //background: linear-gradient(45deg, #bd0c2161, #35459c8a);
      cursor: pointer;
      // margin-right: 20px;
      // margin-bottom: 20px;
      margin-top: 13px;

    }
  }

  .inf-ssr {
    display: flex;
    margin-top: 4px;

    .ssr-inf-1 {
      color: #35459c;

    }

    .ssr-inf-p {
      color: #7e7e7e;
      font-size: 12px;
      padding: 2px;

    }
  }
}

.ssr-div-loader {
  display: flex;
  justify-content: start;
  // max-width: 50%;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  .ssr-block-l {
    border: 1px solid #35459c;
    border-radius: 4px;
    padding: 1px 4px;
    background: linear-gradient(45deg, #bd0c2161, #35459c8a);
    cursor: pointer;
    margin-right: 20px;
    animation: pulse 3s infinite;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }



}

.contact-number {
  display: flex;

  .dob-select {
    margin-right: 10px;
  }
}

.hdr-type {
  display: inline-flex;
  color: #000000;
  flex-wrap: wrap;
  text-align: justify;
  width: 100%;
  font-family: 'Nunito';

  .seg {
    width: 20%;
  }
}

.ssr-b {

  // display: flex;
  // max-width: 100%;
  // overflow: scroll;
  // max-height: 200px;
  // justify-content: space-evenly;
  // flex-wrap: wrap;
  // width: 100%;
  .ssr-a {
    border: 1px solid #35459c;
    border-radius: 4px;
    margin: 0 4px;
    max-height: 150px;
    position: relative;
    height: 142px;

    // background: #ffe8eb;
    // white-space: nowrap;
    // max-width: fit-content;
    // width: 100%;
    // .image-container {
    //   position: relative;
    //   overflow: hidden;
    // }

    .ssr-img {
      width: 100%;
      /* Set your desired dimensions here */
      height: auto;
      display: block;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #31313170, #000000d1);
      // background: linear-gradient(to bottom, rgba(49, 49, 49, 0.057), rgba(0, 0, 0, 0.655)); /* Adjust the gradient as needed */
      color: white;
      /* Text color on the overlay */
      padding: 10px;
      /* Adjust the padding as needed */
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .rem-meal {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #313131a9, #000000d1);
      color: white;
      /* Text color on the overlay */
      padding: 10px;
      /* Adjust the padding as needed */
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .ovr-rem-sssr {

        position: absolute;
        top: 0;
        right: 0;
        padding-left: 8px;
        background: linear-gradient(256deg, #00000099, #bd0c21);
        border-bottom-left-radius: 12px;
      }
    }

    .rem-bagg {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #313131a9, #000000d1);
      color: white;
      /* Text color on the overlay */
      padding: 10px;
      /* Adjust the padding as needed */
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .ovr-rem-sssr {

        position: absolute;
        top: 0;
        right: 0;
        padding-left: 8px;
        background: linear-gradient(256deg, #00000099, #bd0c21);
        border-bottom-left-radius: 12px;
      }
    }


    .pr-ssr-tag {
      font-size: 18px;
      /* Adjust the text size as needed */
      font-weight: bold;
      text-align: center;
      background: linear-gradient(90deg, transparent, #35459c, transparent);
    }
  }

  .ssr-c {
    // padding: 10px;
    text-align: center;

    .but-sel {
      background: linear-gradient(90deg, rgb(9, 176, 221), rgb(2, 107, 165));
      /* Background color for the button */
      color: white;
      padding: 2px 12px;
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      display: inline-block;

      &:hover {
        background: linear-gradient(90deg, #ff7b54, #ff3e3e) !important;
        color: white;
      }

      &:active {
        background: transparent;
        color: #bd0c21;

      }


    }

    .desc-ssr {
      font-size: 14px;
      font-weight: normal;
      margin: 0;
      max-height: 60px;
    }

    .rem-meal {
      .rem-ssr {
        background: #ad061a;
        color: white;
        padding: 2px 12px;
        cursor: pointer;
        text-align: center;
        border-radius: 4px;
        // display: inline-block;
        margin-left: 5px;
      }
    }
  }

}

.pax-count-card {
  //border: 1px solid #35459c;
  // border-radius: 4px;
  //padding: 12px;

  .fareTitle1 {
    background: linear-gradient(to right, #bd0c21, #35459c);
    color: white;
    border-radius: 4px;
    width: fit-content;
    padding: 0 4px;
    margin-top: -9px;
  }

  .accordion {
    border-bottom: solid 1px #ccc;
    margin-bottom: 10px;
    padding: 5px 15px;

    // padding-bottom: 0px;
    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
  }

  .mobile_acc {
    border-bottom: none !important;
    padding: 5px;
  }

  .all-taxes {
    font-size: 12px;
    color: #969dac;
    margin-bottom: 0px !important;
    margin-bottom: 10px !important;
  }

  .pax-total-price {
    display: flex;
    justify-content: space-between;
   // margin-top: 10px;

    // padding-bottom: 0px;
    .pax-type {
      p {
        font-size: 18px;
        margin-bottom: 0px;
      }
    }

    .total {
      display: flex;

      i {
        @media (max-width: 767px) {
          font-size: 25px;
          padding-left: 7px;
        }
      }

      p {
        font-size: 20px;
        margin-bottom: 0px;
      }

      .amount {
        font-size: 20px;
        margin-bottom: 0px;
        font-weight: 700;
      }
    }
  }

  .pax-pre-price {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;

    // padding-bottom: 0px;
    .pax-type {
      p {
        font-size: 16px;
        margin-bottom: 0;

        @media (max-width: 767px) {
          margin-bottom: 0px;
          font-weight: 600;
        }
      }
    }

    .total {
      display: flex;

      i {
        @media (max-width: 767px) {
          font-size: 25px;
          padding-left: 7px;
        }
      }

      p {
        font-size: 16px;
        margin-bottom: 0px;
      }

      .amount {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 700;
      }
    }
  }

  .service-price {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 14px;
      margin-bottom: 0;
    }

    .main-price {
      font-size: 14px;
      font-weight: 700;
      padding-left: 6px;
    }
  }

  .pax-count-card-header {
    background-color: #fff;
    border-bottom: none;
    padding: 0px;
  }
}

.pax-count-header-top {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  cursor: pointer;

  @media (max-width: 767px) {
    padding: 8px 0px;
  }

 

  .pax-header-left-sec {
    display: flex;

    p {
      padding-left: 6px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.border-line {
  border-top: solid 1px #ccc !important;
  // margin-top: 15px;
}

.fare-breakdown {
  border: 1px solid #35459c;
  border-top: none;
  border-radius: 1px 1px 6px 6px;
  background: #d4d8ff4a;
  padding: 3px;

  .pax-count-acc-body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    p {
      margin-bottom: 1px;
    }
  }
}

.pricepopup {
  .ant-popover-title {
    padding: 6px 12px;
    font-weight: bold;
  }

  .ant-popover-inner-content {
    padding: 7px 12px 12px 12px;

    .pax-count-acc-body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      color: #505c75;
      padding-top: 5px;

      p {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 0px;
      }
    }
  }
}

.details-modal {
  .details-header {
    p {
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
    }

    button {
      font-size: 34px;
    }
  }

  .modal-body {
    padding: 0px 15px;
  }
}

.faresummary_mobile {
  display: none;

  @media (max-width: 767px) {
    display: block;
    margin-top: 23px;
  }
}

/********** Antd Styles *******/
.passenger-form {
  .ant-form-item-label {
    text-align: start;
  }

  // .pax-infobox{
  //   display: flex;
  //   justify-content: space-between;
  // }
}

.flight-checkout-contact-form {
  .ant-form-item-label {
    text-align: start;
  }
}

/********** fare summary card **********/
.sticky-card-container1 {
  // position: sticky;
  // top: 0;
  //margin-bottom: 13px;

  .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
    background-color: #fff;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    background: #f3f0f0 !important;
    margin:3px;
  }

  .ant-collapse-content-box {
    padding-top: 16px !important;

    p {
      margin-bottom: 0;
    }
  }
}

.book-pay-card {
  .book-pay-tc {
    // .ant-checkbox-checked .ant-checkbox-inner {
    //   background-color: rgb(0, 59, 149);
    //   /* Your desired color */
    //   border-color: rgb(0, 59, 149);
    //   /* Match the background color */
    // }

    // .ant-checkbox-checked .ant-checkbox-inner::after {
    //   border-color: white;
    //   /* Color of the checkmark */
    // }

    // .ant-checkbox:hover .ant-checkbox-inner {
    //   border-color: rgb(0, 59, 149);
    //   /* Change border color on hover */
    // }
    /* Normal (unchecked) state */
    .ant-checkbox .ant-checkbox-inner {
      width: 16px;
      /* Default size; adjust as needed */
      height: 16px;
      border: 2px solid rgb(0, 59, 149);
      /* Normal border color */
      border-radius: 2px;
      /* Optional: for rounded corners */
      background-color: white;
      /* Background for unchecked state */
      transition: all 0.3s ease;
      /* Smooth transition */
    }

    /* Hover state */
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: rgb(0, 59, 149);
      /* Darken or highlight border on hover */
    }

    /* Checked state */
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgb(0, 59, 149);
      /* Fill color for checked state */
      border-color: rgb(0, 59, 149);
      /* Border matches background color */
    }

    /* Checkmark color */
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #ff3e3e;
      /* Color of the checkmark */
    }

    /* Focus state (optional, for accessibility) */
    .ant-checkbox-input:focus+.ant-checkbox-inner {
      outline: none;
      border-color: rgba(0, 59, 149, 0.8);
      /* Slightly lighter focus color */
    }

    /* Disabled state */
    .ant-checkbox-disabled .ant-checkbox-inner {
      border-color: gray;
      background-color: #f5f5f5;
      /* Light gray background */
      cursor: not-allowed;
    }


  }
}

.airline-details-left {
  .airline-dte {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      order: 2;
    }

    h5 {
      font-weight: 700;
      margin-bottom: 0;

      @media (max-width: 767px) {
        font-size: 15px;
        margin-bottom: 5px !important;
      }
    }

    span {
      font-size: 14px;
      color: #969dac;
      margin-left: 10px;

      @media (max-width: 767px) {
        margin-left: 0;
        font-size: 13px;
      }
    }
  }

  .airline-src-dest {
    display: flex;
    padding-top: 6px;
    align-items: center;

    p {
      font-weight: 600;
      margin-bottom: 0;
    }

    .airline-line {
      font-size: 12px;
      width: 100%;
      border-top: solid 1px #969dac;
      margin: 6px 9px;
    }
  }
}

.airline-details-right {
  text-align: right;
  padding-top: 10px;

  p {
    font-weight: 700;
    font-size: 14px;
  }
}

.direct-text {
  p {
    margin-bottom: 0;
    font-size: 13px;
    color: #000;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.airline-details {
  border-bottom: 1px solid #969dac;
  padding-bottom: 15px;

  @media (max-width: 767px) {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .airline-row-mobile {
    align-items: center;

    @media (max-width: 767px) {
      flex-wrap: nowrap !important;
      justify-content: space-between;
    }

    .col-md-5 {
      @media (max-width: 767px) {
        order: 1;
      }

      .airline-dte {
        @media (max-width: 767px) {
          padding: 2px 10px;
        }

        h5 {
          @media (max-width: 767px) {
            font-size: 13px;
          }
        }

        span {
          @media (max-width: 767px) {
            font-size: 10px;
          }
        }
      }
    }

    .col-md-3 {
      @media (max-width: 767px) {
        padding: 0px 2px;
        order: 0;
        max-width: 62px;
      }
    }

    .src-dest-mobile {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .airline-details-left {
    .airline-dte {
      display: flex;
      align-items: center;

      h5 {
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    .airline-src-dest {
      display: flex;
      padding-top: 6px;

      p {
        font-weight: bold;
        color: #000;
      }

      .airline-line {
        font-size: 12px;
        width: 100%;
        border-top: solid 1px #969dac;
        margin: 6px 9px;
      }
    }
  }

  .airline-details-right {
    text-align: right;
    padding-top: 10px;

    p {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.trip-details {
  padding: 15px 0 0;

  .ant-row {
    align-items: center;

    @media (max-width: 767px) {
      justify-content: space-between;
    }
  }

  .trip-timimg-details {
    ul {
      display: flex;

      li {
        margin-right: 15px;
        font-weight: bold;
      }
    }
  }

  .duration-timing {
    display: flex;
    font-size: 12px;

    @media (max-width: 767px) {
      display: none;
    }

    p {
      margin-bottom: 0;
      color: #969dac;
    }

    span {
      color: #000;
    }
  }

  .details-btn {
    text-align: end;
  }
}

.trip-duration-strip {
  background: #eaebee;

  .trip-time {
    display: flex;
    padding: 20px 0px;
    align-items: center;
    padding-left: 40px;

    i {
      font-size: 20px;
    }

    p {
      margin-bottom: 0px;
      font-size: 15px;
      padding-left: 7px;
    }
  }
}

.flight-cards-details {
  background-color: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2) !important;
  font-family: "Nunito" !important;

  .fareTitle {
    font-weight: 600;
  }

  .grand_total_card_row {
    padding: 0;

    p {
      margin-bottom: 14px;
      font-size: 14px;
    }

    .d-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .anticon-question-circle {
        margin: 0 0 14px 5px;
        color: #969dac;
        cursor: pointer;
      }
    }
  }
}

.flight-details-modal-container {
  .trip-body {
    padding-left: 40px;
    padding-top: 14px;

    .trip-strip {
      position: relative;

      &::before {
        display: block;
        content: "";
        position: absolute;
        top: 10px;
        left: -13px;
        bottom: 24px;
        background: #f9b975;
        width: 1px;
      }
    }

    .trip-body-details {
      display: flex;
      position: relative;

      &::before {
        display: block;
        background-color: #f9b975;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        position: absolute;
        left: -15px;
        top: 9px;
        content: "";
      }

      p {
        font-size: 15px;

        &:nth-child(1) {
          margin-right: 30px;
        }
      }
    }
  }

  .trip-bottom-duration {
    padding-left: 40px;
    padding-bottom: 20px;

    p {
      margin-bottom: 0px;
      font-size: 13px;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.gst-details {
  .heading {
    color: #1c2b39;
    font-weight: 100;
    font-size: 20px;

    @media (max-width: 767px) {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}

.add-gst-text {
  display: flex;
  align-items: center;

  h4 {
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 20px;

    @media (max-width: 767px) {
      font-size: 15px;
    }
  }
}

.add-gst-block {
  .ant-collapse-borderless {
    background-color: white;
  }

  .ant-collapse-borderless>.ant-collapse-item {
    border-bottom: transparent;
  }
}

.promo-card {
  margin-top: 25px;
  box-shadow: 0 1px 9px 3px hsla(0, 0%, 89%, .81);
  height: 112px;


  @media (max-width: 767px) {
    margin-top: 20px;
  }

  .promo-c {
    // border: 1px solid #35459c;
    border-radius: 4px;
    // padding: 4px;
  }

  .name {
    font-size: 20px !important;
    //background: linear-gradient(to right, #bd0c21, #35459c);
    width: fit-content;
    padding: 10px;
    border-radius: 4px;
    color: white;
    // margin-top: -18px;
  }

  .promo-input {
    // margin-top: 10px;
    margin: 10px 5px 2px 10px;

    .applied {
      color: #0091ff;
      font-weight: 600;
      background: #e0e0e0;
      padding: 6px 20px;
      border-radius: 8px;
      position: relative;
      margin: 8px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 10px;
        /* Circle diameter */
        height: 10px;
        background: #ffffff;
        /* White circles */
        border-radius: 50%;
        /* Make the element circular */
      }

      &::before {
        left: -4px;
        /* Position slightly outside the container */
        top: 50%;
        transform: translateY(-50%);
      }

      /* Position the end circle */
      &::after {
        right: -4px;
        /* Position slightly outside the container */
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .promo-input-item {
      width: 70%;

      @media (max-width: 767px) {
        width: 65%;
      }

      // .ant-form-item-control-input{
      //   min-height: 40px !important;
      // }
    }

  }

  .ant-form {
    button {
      // background-color: #bd0c21;
      background: linear-gradient(to right, #320063, #0f76bb) !important;
      color: #fff;
      border: none;
      font-size: 16px;

      &:hover {
        background: linear-gradient(90deg, #ff7b54, #ff3e3e) !important;
        color: white;
      }
    }
  }

  .promo-input-btn {
    margin-right: 0 !important;
    // background-color: #d5436a;
    color: #fff;
    border: none;
    font-size: 16px;
  }
}

.service-charge {
  margin-top: 25px;

  .service-charge-header {
    h4 {
      margin-bottom: 25px;
      font-size: 23px;

      @media (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }

  .service-charge-table {
    .ant-table {
      overflow: hidden;
    }
  }
}

.responsive-departure {
  @media (max-width: 767px) {
    order: 2;
  }
}

.flight-review-row {
  @media (max-width: 767px) {
    flex-wrap: wrap-reverse !important;
  }
}

.passport-dates {
  .ant-picker {
    width: 100%;
  }
}

.discount-price {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;

  .total {
    display: flex;

    p {
      margin-right: 5px;
    }
  }
}

// .flights-poins-sec .ant-card-bordered {
//   border-radius: 8px;
//   box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
// }

.grand-total-card .ant-card-bordered {
  border-radius: 8px;
}

.grand-total-card {

  .grand-t {
    background: white;
    color: #272727;
    /* width: 116%; */
    padding: 13px;
    margin-top: -4px;
    // border-radius: 4px;
    //border: 1px solid #35459c;

    .Tf-tag {
      background: linear-gradient(to right, #bd0c21, #35459c);
      max-width: fit-content;
      padding: 0 6px;
      margin-top: -24px;
      color: #fff;
      border-radius: 4px;
    }

  }
}

.flights-poins-sec .flight-syt-time {
  position: relative;
  left: 0px;
  top: 0px;
}

.flights-poins-sec .details-bag-icon {
  position: relative !important;
  top: 0px !important;
  right: 0px !important;
}

@media only screen and (max-width: 768px) {
  .margin-contact .ant-form-item {
    margin-bottom: 0px;
  }
}

.flight-logo {
  margin: 20px 10px;
  display: inline-flex;

  .logo-fli {
    width: 50px;
  }
}

.inner-card {
  border: none;
  margin: 0px 0;
  // @media (max-width: 991px) {
  //   width: 100%;
  // }
  // @media (max-width: 767px){
  //   width: 100%;


  // }


  .type-flight {

    border-bottom-right-radius: 20px;
    width: 70px;
    background: linear-gradient(to right, #bd0c21, #35459c);
    margin-top: -24px;
    margin-left: -20px;


    p {
      padding-left: 5px;
      color: white;
    }
  }




  // .policy {
  //   display: inline-flex;
  //   margin-top: 20px;

  //   .baggage {
  //     margin-left: 20px;
  //   }

  //   .baggage-info {
  //     margin-top: 20px;
  //     border-bottom: 1px solid rgba(170, 170, 170, 0.539);

  //     .flight-logo-1 {
  //       margin: -8px 10px 11px;
  //       text-align: center;
  //     }

  //     p {
  //       margin-bottom: 0;
  //       margin-left: 20px;
  //     }
  //   }

  //   @media (max-width: 767px) {
  //     display: none;
  //   }
  // }

  .inner-flight-card {
    .travel-info {
      font-size: 20px;
      display: inline-flex;
      color: black;
      padding-left: 3px;
      letter-spacing: 1px;
      border-radius: 4px;
      background: linear-gradient(52deg, #003b9585, #ffffff);
      border-left: 3px solid #003b95;
      align-items: center;

      img {
        width: 84px;
        margin-right: 10px;
      }

      .return-plane {
        rotate: -180deg;
      }
    }

    .travel-date {
      font-size: 14px;
      color: #474646;
      padding: 6px;
      display: block;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .travel-date1 {
      font-size: 14px;
      color: #474646;
      padding: 6px;
      display: none;

      @media (max-width: 767px) {
        display: none;
        position: absolute;
        top: 8%;
        left: 55%;
      }
    }

    .flight-logo {
      margin: 20px 10px;
      display: inline-flex;

      .airline-img {
        width: 50px;
        height: 49px;
        margin-right: 11px;
      }
    }

    .flight-name {
      display: inline-block;
      font-size: 14px;

      .airlinename {
        text-overflow: ellipsis;
        font-size: 12px;
        color: #35459c;
        margin: 0px 0 0px -60px;
        position: absolute;
        white-space: nowrap;
        max-width: 51px;
        overflow: hidden;
      }

      .airlinenumber {
        color: #5b5959a5;
        font-size: 12px;
        margin: 14px 0 0 -60px;
        position: absolute;
      }

    }

    .departure-start {
      padding: 10px 6px;
      margin-top: 10px;
      // @media screen and (min-width: 995px) and (max-width: 1130px) {

      //   max-width: 80px;
      // }

      .departure-time {
        font-size: 24px;
        font-weight: 600;
        color: #000;
      }

      .departure-info {
        font-size: 12px;
        color: #636262;
        max-width: 105px;
        overflow: hidden;

        @media (max-width: 767px) {
          max-width: 100%;
        }

        h4 {
          font-size: 12px;
          font-weight: 800;
          margin-bottom: 0;
          color: grey;
          text-overflow: ellipsis;
          max-width: 98px;
          overflow: hidden;
          white-space: nowrap;

          @media (max-width: 767px) {
            max-width: 100%;
          }
        }

        @media (max-width: 767px) {
          align-items: center;
          justify-content: center;
          display: inline-grid;
        }

        @media (max-width: 767px) {
          align-items: center;
          justify-content: center;
          display: inline-grid;
        }
      }



    }
  }
}

.policy {
  .fares {

    h4 {
      font-size: 14px;
      color: #35459c;
      border: 2px solid #35459c00;
      // padding: 1px 3px;
      border-radius: 4px;
      max-width: max-content;

      &:hover {
        color: #bd0c21;
      }

      &:active {
        border: 1px solid #35459c;
        // padding: 1px 3px;
        border-radius: 4px;
        // transition: .2s;
        background: rgb(133, 145, 213);
        color: white;
      }

    }

  }
}

.baggage-table {
  border: 1px solid rgb(184, 184, 184);
  box-shadow: 2px 3px 2px rgb(135, 132, 132);
  background: #f5f5f7;
  border-radius: 4px;
}

.baggage-info {
  margin: 10px 0;

  padding: 0 8px;

  // border-bottom: 1px solid #bdbdbdc8;
  .airtype {
    display: flex;
    justify-content: space-around;

    @media (max-width: 767px) {
      justify-content: start;
      position: absolute;
    }
  }

  .Check-in-Bag {
    display: flex;
    justify-content: space-around;

    @media (max-width: 767px) {
      justify-content: center;
      // position: absolute;
    }
  }

  .Cabin-in-Bag {
    display: flex;
    justify-content: space-around;

    @media (max-width: 767px) {
      justify-content: end;
      position: relative;
      margin-top: -20px;
    }
  }
}

.headerbg {
  display: flex;
  justify-content: space-around;
  background: linear-gradient(to top, #5c68a398, transparent);
  border-radius: 6px;
  padding-top: 5px;

  h4 {
    font-size: 15px;
    // margin-left: 65px;
    // padding: 0 20px;
    // align-items: end;
    font-weight: 600;
    color: rgb(0, 59, 149);
    font-family: 'Nunito';

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}




.travel-duration {
  margin: 15px 0;

  .time {
    text-align: end;

    @media (max-width: 767px) {
      text-align: center;
    }

    p {
      color: grey;
      margin-bottom: 2px;

    }

    img {
      position: relative;
      right: 15%;

      @media (max-width: 767px) {
        right: 0;
      }
    }

    .line-l {
      border-bottom: 1px solid #596ac7;
      width: 162%;
      margin-bottom: 20px;
      margin-top: -12px;
      margin-right: -40px;

      .cir1,
      .cir2 {
        background: #596ac7;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-bottom: 12px;
        margin-top: -12px;
        // margin-top: -5px;

        @media (max-width: 767px) {
          margin-top: -5px;
        }


      }

      .cir1 {
        left: -5px;
      }

      .cir2 {
        right: -111px;

        @media (max-width: 767px) {
          right: 0px;
        }
      }

      @media (max-width: 991px)  {
        width: 157%;
      }

      @media (max-width: 767px) {
        width: 100%;
      }



    }

    .status {
      border: 1px solid rgb(30, 204, 30);
      border-radius: 16px;
      color: #35459c;
      display: inline-flex;
      font-size: 12px;
      padding: 0 20px;
      margin-left: 60px;
      margin-top: 4px;

      @media (max-width: 767px) {
        margin-left: 0px;
      }


    }

    .status1 {
      border: 1px solid rgb(204, 30, 30);
      border-radius: 16px;
      color: rgb(255, 0, 0);
      display: inline-flex;
      font-size: 12px;
      padding: 0 20px;
      margin-left: 45px;
      margin-top: 4px;

      @media (max-width: 767px) {
        margin-left: 0px;
      }


    }
  }
}

.arrival-col {
  // margin-left: 115px;
  margin-left: 6%;

  @media (max-width: 770px) {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    margin-left: 0px;

  }
}

.layOver-time {
  background: linear-gradient(90deg, #09b0dd, #026ba5) !important;
  border-radius: 20px;
  margin-bottom: -11px;
  z-index: 1;
  margin-left: 37%;

  @media (max-width: 767px) {
    margin-left: 14%;
  }

  p {
    color: #efefef;
    margin-bottom: 0px;
    padding: 0px 15px;
    font-size: 12px;
    margin:2px;

    @media (max-width: 767px) {
      padding: 0 10px;
    }

  }
}

.tab1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  overflow: overlay;
  scroll-behavior: smooth;

  .brd {
    border: 1px solid #35459c;
    border-radius: 4px;
    margin: 0 10px;
    max-width: fit-content;
    min-width: 98px;
    background: linear-gradient(to right, #35459c59, #bd0c2154);
  }

  .we {
    color: rgb(0, 197, 0);
    font-size: 18px;
    font-weight: 600;
  }

  .cont {
    // background: #ffb8c0;
    display: block;

    .hd {
      color: #35459c;
    }

    .amntb {
      color: #bd0c21;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 13px;
      }
    }
  }
}

.line-layOver {
  border-bottom: 2px solid rgba(186, 186, 186, 0.6);
  width: 80%;
  margin-left: 15%;

}

.check-in-Rules {
  margin-top: 30px;

  box-shadow: 0 1px 9px 3px hsla(0, 0%, 89%, .81) !important;

  border-radius: 6px;

  &:hover {
    box-shadow: 0 1px 9px 3px hsla(0, 0%, 89%, .81) !important;
  }

  .check-in-hdr {
    // background: linear-gradient(90deg, #c7dffe 0%, #d8f2ff 100%);
    // background: linear-gradient(90deg, #09b0dd, #026ba5);
    background: #f9f9f9;
    padding-left: 16px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    // padding-top: 4px;
    h6 {
      font-size: 18px;
      padding-top: 6px;
      color: #000000;
      font-family: 'Nunito';
    }

    p {
      font-size: 12px;
      margin-top: -4px;
      margin: -32px 0 0 29px;
      color: #000000;
      font-family: 'Nunito';
    }
  }

  .check-in {
    background: rgba(255, 255, 255, 0.623);
    padding: 16px;
    margin: 0;
    border-radius: 6px;

    p {
      margin: 0;

      span {
        margin-right: 6px;
        color: #787676;
      }
    }
  }

}

.mobmobmobmobmob {
  // width: 20%;

  @media (max-width: 767px) {
    border: 2px solid transparent;
    width: 100%;
    text-align: center;
  }
}

.row-of-det-flight {
  justify-content: space-between;

  @media (max-width: 767px) {
    margin-top: 8%;
  }
}

.col-icos-flight {
  @media (max-width: 767px) {
    width: 100%;
  }
}

.col-flight-det-pointsfromto {
  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
}

.email-flight-checkout-select {
  width: 500px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.flightcheckout-mob-elepsis {
  @media (max-width: 767px) {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    // border: 1px solid #000000;
  }
}

.sectionn-work-for-mob-only {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.promo-data-1 {
  padding: 4px;
  // margin-top: -16px;
  // border: 1px solid transparent;
  background: #ffffff77;
  max-height: 180px;
  overflow: auto;
  margin: 2px 10px;

  .promo-cp-coupons {
    background: linear-gradient(264deg, #ffffff, #e0e0e082);
    border-radius: 6px;
    padding: 4px;

  }
}

.ssr-body {
  .ssr-part {
    border: 1px solid #079bcc;
    border-radius: 6px;
    padding: 4px;
    // background: linear-gradient(45deg, #e3e7ff, #ffefef);
    margin-bottom: 4px;
    height: auto;

    .hdr-type {
      display: flex;
      justify-content: space-between;

      .seg-ssr {
        background: linear-gradient(280deg, #0581b7, transparent);
        padding: 0 6px 0 0;
        border-radius: 0px 15px 15px 0;
      }
    }

    .ssr-pax-hdr {
      .ssr-pax-block {
        display: flex;
        justify-content: space-between;
        // border-left: 2px solid #bd0c21;
        padding: 2px 0;

        .ssr-pax-type {
          width: 30%;
          border-left: 2px solid #0581b7;
          padding-left: 4px;
          background: linear-gradient(94deg, #bdbdbd8c, transparent);
          max-width: max-content;
          padding-right: 10px;
          font-family: 'Nunito';
        }

        .seg-ssr-part {
          border: 1px solid #35459c;
          padding: 0 4px;
          border-radius: 8px;
          background: #c1cafa;
          cursor: pointer;

          &:hover {
            background: #ffff;
            color: #35459c;
          }
        }
      }
    }
  }
}

.countdown3 {
  border: 2px solid transparent;
  width: 100%;
  text-align: center;
  z-index: 999;
  position: fixed;
  bottom: 0;
  background-color: #4e89ce;
  margin: 0 auto;
  font-size: 12px;
  padding: 0px 0;
  color: rgb(237, 237, 237);
  overflow: hidden;
  overflow-x: auto;
  // margin-left: -11%;
  height: 29px;

  @media (max-width: 767px) {
    .countdown1 {
      display: none;

    }

    height: 4vh;
    display: block;
    width: 100%;
    position: fixed;
    margin-left: 0%;
    // bottom: 32px;
  }
}

.countdown31 {
  border: 2px solid transparent;
  width: 100%;
  text-align: center;
  z-index: 999;
  position: fixed;
  bottom: 0;
  background-color: #4e89ce;
  ;
  font-size: 12px;
  padding: 0px 0;
  color: rgb(237, 237, 237);
  overflow: hidden;
  overflow-x: auto;
  margin-left: 0%;
  height: 29px;

  @media (max-width: 767px) {
    .countdown1 {
      display: none;

    }

    height: 4vh;
    display: block;
    width: 100%;
    position: fixed;
    margin-left: 0%;
    // bottom: 32px;
  }
}


.countdown3det {
  border: 2px solid transparent;
  width: 100%;
  text-align: center;
  z-index: 999;
  position: fixed;
  bottom: 0;
  background-color: #4e89ce;
  ;
  font-size: 12px;
  padding: 0px 0;
  color: rgb(255, 255, 255);
  overflow: hidden;
  overflow-x: auto;
  margin-left: -11%;
  height: 29px;

  @media (max-width: 767px) {
    .countdown1 {
      display: none;

    }

    height: 4vh;
    display: block;
    width: 100%;
    position: fixed;
    margin-left: 0%;
  }
}

.countdown3det1 {
  border: 2px solid transparent;
  width: 100%;
  text-align: center;
  z-index: 999;
  position: fixed;
  bottom: 0;
  background-color: #4e89ce;
  ;
  font-size: 12px;
  padding: 0px 0;
  color: rgb(255, 255, 255);
  overflow: hidden;
  overflow-x: auto;
  margin-left: 0%;
  height: 29px;

  @media (max-width: 767px) {
    .countdown1 {
      display: none;

    }

    height: 4vh;
    display: block;
    width: 100%;
    position: fixed;
    margin-left: 0%;
  }
}

.countdown3busres {
  width: 94.7%;
  // margin-left: 3%;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0%;
  }
}

.countdown3checkoutbus {
  width: 100%;
  margin: 0%;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: -7%;
  }
}

.countdown3revbus {
  width: 100%;
  margin: 0%;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: -7%;
  }
}

.busCountdown {
  .ant-statistic-content {
    font-size: 18px;
    margin-top: -1%;
  }
}

.busCountdown .ant-statistic-content-value {
  color: rgb(255, 255, 255) !important;
}

.timeout-popup-main {
  border: 2px solid transparent;
  text-align: center;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;

}

.timeout-popup-main1 {
  border: 2px solid rgb(209, 209, 209);
  width: 40%;
  border-radius: 50%;
  text-align: center;

  @media (max-width: 767px) {
    width: 50%;
  }
}

.timeout-popup-main2 {
  border: 2px solid transparent;
  text-align: center;
  width: 100%;
  margin-top: 2%;

}

.timeout-img {
  width: 100%;
  border-radius: 50%;
}

.popup-session-timeout-p-tag {
  font-weight: bold;
  font-size: 15px;
}

.countdown3det {
  border: 2px solid transparent;
  width: 100%;
  text-align: center;
  z-index: 999;
  position: fixed;
  bottom: 0;
  background-color: #4e89ce;
  ;
  font-size: 12px;
  padding: 0px 0;
  color: rgb(255, 255, 255);
  overflow: hidden;
  overflow-x: auto;
  margin-left: -11%;
  height: 29px;

  @media (max-width: 767px) {
    .countdown1 {
      display: none;

    }

    height: 4vh;
    display: block;
    width: 100%;
    position: fixed;
    margin-left: 0%;
  }
}

.countdown-main-box {
  border: 2px solid transparent;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  width: 28%;
  margin: auto;
  font-size: 18px;

  @media (max-width: 767px) {
    width: 70% !important;
    // border: 2px solid black;

  }

  @media (max-width: 991px)  {
    width: 50%;
  }
}

.countdown1 {
  border: 2px solid transparent;
}

.countdown2 {
  border: 2px solid transparent;
}

.countdownfor-hotelrev {
  margin-left: -15%;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: -6%;
  }
}

.checkout-countdown {
  margin-left: 0%;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: -6%;
  }

}

.checkout-countdown1 {
  margin-left: -15%;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: -6%;
  }

}

.phone-view-for-result-page {
  visibility: hidden;

  @media (max-width: 767px) {
    visibility: hidden;
    border: 2px solid transparent;
    position: fixed;
    bottom: 30%;
    left: 0%;
    padding: 0px 10px;
    z-index: 70;
    background-color: #002543;
  }
}

.seatshow {
  display: flex;
  justify-content: space-around;

  @media (max-width: 767px) {
    display: flow;
  }

  .passengerpaxdet {
    margin: 22px 62px;

    @media (max-width: 770px) {
      margin: 14px 0px;
    }

    @media (max-width: 991px)  {
      margin: 16px 32px;
    }

    @media (max-width: 767px) {
      margin: 0px;
      display: flex;
    }
  }

  // .origin-destination-btn{
  //   width: 100px;
  //   height: 60px;
  //   border-radius: 20px;
  //   background: transparent;
  // }
  .passenger-pax-details {
    .passenger-section {
      margin-bottom: 20px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 2px 3px 6px grey;

      h3 {
        margin-bottom: 15px;
      }

      .flight-segment {
        margin: 4px;


        .origin-destination-btn {
          background-color: #007bff;
          box-shadow: 2px 2px 6px rgb(189, 189, 189);
          color: #fff;
          border: none;
          padding: 10px 15px;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #0056b3;
          }
        }
      }

      .seatbutton {
        background-color: #28a745;
        color: #fff;
        border: none;
        padding: 10px 15px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #218838;
        }
      }

      .city-seat-lay {
        display: flex;
        justify-content: space-between;
      }
    }

  }

  .seattypes {
    margin-top: 20px;

    h6 {
      margin-bottom: 10px;
      font-weight: 600;
      /* border-bottom: 1px solid grey; */
      justify-items: auto;
      /* display: flex; */
      max-width: max-content;
      box-shadow: 2px 3px 6px grey;
      padding: 2px 8px;
      border-radius: 7px;
    }

    .seat-desc {
      display: flex;
      flex-direction: column;

      .seat-category {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .seat-color {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 10px;
        }

        span {
          margin-right: 10px;
        }
      }
    }
  }

  .seatbutton {
    border: 1px solid;
    background: transparent;
    margin-bottom: 19px;
    margin-top: 5px;
    border-radius: 12px;
    width: 140px;
    height: 30px;
  }

  // .seatsss {
  //   display: flex;
  // }

  // .seatinner {
  //   margin: 22px 62px;

  //   @media (max-width: 767px) {
  //     margin: 0px;
  //   }
  // }
}

// body {
//   font-family:"Nunito" !important;
// }

.flightbox {
  display: table;
  margin: 0 auto;
  clear: both;
  position: relative;
}



.flightdetails {
  color: #2567a1;
  display: block;
  font-size: 16px;
  text-align: center !important;
}

.flightdetails span {
  color: #efa410;
  font-size: 11px;
}

.flgtrow {
  display: table;
  width: 100%;

  @media (max-width: 770px) {
    width: 100%;
  }

  @media (max-width: 991px)  {
    width: 101%;
  }
}

.innerrow {
  display: block;
  padding: 0 2px;
  margin-top: 2px;
}

.flgtrow h2 {
  display: block;
  margin: 0;
  padding: 2px 20px;
  text-align: center;
  background: #e7e7e7;
  font-size: 14px;
  font-weight: normal;
  // vertical-align: middle;
}

.leftwing,
.rightwing {
  width: 50px;
  height: auto;
  display: table-cell;
}





.mainrow {
  float: left;
  width: calc(100% - 15%);
}

.windowrow .leftwing {
  background: linear-gradient(270deg, #fff, #c5c5c5);
}

.windowrow .rightwing {
  background: linear-gradient(270deg, #c5c5c5, #fff);
}

.emergencydoor {
  border-radius: 20px;
  color: #fff;
  display: table;
  font-size: 11px;
  text-align: center;
  width: 95%;
  z-index: 100;
  background: #fff;
}

.emergencytext {
  background: red;
  display: inline-block;
  padding: 2px 5px;
}

.rightdoor,
.leftdoor {
  background: red;
  border-radius: 5px;
  display: table-cell;
  font-size: 12px;
  height: 20px;
  padding: 0 2px;
  width: 17px;
}

.seatrow {
  list-style: none;
  background: #fff;
  display: table;
  clear: both;
  margin: 0;
  padding: 0px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.seatrow li,
.seathint {
  float: left;
  height: 23px;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  padding: 0 3px 1px;
  background: #fff;
  margin-left: 16px;

  @media (max-width: 770px) {
    margin-left: 10px;
  }

  @media (max-width: 991px)  {
    margin-left: 10px;
  }
}

.seatrow li span,
.seathint span {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #c5c5c5;
  border-radius: 0 0 3px 3px;
  content: "";
  float: left;
  height: 16px;
  width: 25px;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
}

.seatrow li.poor,
.quitezone {
  background: #F4D4FC;
}

.seatrow li.poor span,
.quitezone span {
  background: #DD7FF4;
  border: 1px solid transparent;
  color: #fff;
}

.seatrow li.good,
.hintgood {
  background: #68bd56;
}

.seatrow li.good span,
.hintgood span {
  background: #509c44;
  border: 1px solid transparent;
  color: #fff;
}

.seatrow li.drawback,
.hintdrawback {
  background: #f1f160;
}

.seatrow li.drawback span,
.hintdrawback span {
  background: #c4c14e;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
}

.seatrow li.booked,
.hintbooked {
  background: #eaebed;
}

.seatrow li.booked span,
.hintbooked span {
  background: #bfbdbe;
  border: 1px solid transparent;
}

.blankrow li,
li.blankseat {
  visibility: hidden;
  width: 26px;
}

blankrow li,
li.blankseat,
li.blankseat.gallery {
  margin-left: 31px;
}

.blankrow li span,
li.blankseat span {
  display: none;
}

.recline {
  padding-top: 10px;
}

.seatnumrow {
  display: table-cell;
  list-style: none;
  vertical-align: top;
}

.seatnumrow ul {
  position: absolute;
  margin-top: 28px;
  right: 530px;
  width: 20px;
  list-style: none;
}

.seatnumrow li {
  background: #444;
  color: #fff;
  padding: 3px 2px;
  float: left;
  margin: 5px 0 0;
  font-size: 11px;
  height: 15px;
  width: 15px;
  line-height: 9px;
}

.blanknum {
  background: none;
  height: 10px !important;
  margin: 0 !important;
  background: transparent !important;
}

.hintbox {
  float: left;
  width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  font-size: 14px;
  margin-top: 15px;
}

.hintrow {
  float: left;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-left: none;
  border-right: none;
  margin-top: -1px;
}

.hintrow span {
  padding: 3px;
  float: left;
}

.hintseat {
  border-right: 1px solid #d7d7d7;
}

.seatrow.quitejone {
  background: #f4d4fc;
}

.bulkhead::after {
  border-top: 2px solid #000;
  content: "";
  height: 2px;
  margin-left: -26px;
  margin-top: -5px;
  position: absolute;
  width: 9%;
}







.seatlayoutbox {
  width: 72%;
  float: right;
  max-height: 670px;
  overflow-y: auto;
  margin-right: 5px;
  // box-shadow: -1px 2px 3px #e7e7e7;
  //direction: rtl;
  /* Set the direction to right-to-left */
}



.seatdetailbox {
  float: left;
  width: 25%;
  padding: 15px;
  height: 50px;
}

.seatinfobox {
  float: right;
  width: 100%;
  padding: 10px;
}

.seatinfobox h2 {
  background: #dee4f6 none repeat scroll 0 0;
  color: #115895;
  font-size: 13px;
  margin: 0;
  padding: 5px;
  width: 100%;
}

.seatrowdtl {
  float: left;
  width: 100%;
  padding: 8px;
  background: #fff;
  border: 1px solid #e7e7e7;
  border-top: none;
  margin-top: -1px;
  box-sizing: border-box;
}

.seatrowdtl:hover {
  background: #dee4f6;
}

.seatrowdtl h3 {
  float: left;
  font-size: 13px;
  color: #115895;
  margin: 0;
  padding: 0;
  width: 100%;
}

.seatrowdtl p {
  float: left;
  font-size: 13px;
  width: 100%;
  margin: 2px 0 0;
}

.seatrowdtl span {
  color: #efa410;
}

.seatno {
  display: block;
  color: #efa410;
  padding: 5px 0;
  font-weight: bold;
}

.price {
  display: block;
}

.price label {
  float: left;
  width: 20%;
}

.price b {
  float: right;
}

.seatdetails {
  position: absolute;
  margin-top: 23px;
  width: 45%;
  display: none;
  z-index: 10;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
}

.seatdetailsclick {
  background: #efa410;
  color: #fff;
  display: none;
  font-size: 12px;
  left: 0;
  padding: 2px;
  position: absolute;
  top: -4px;
  width: 100%;
  cursor: pointer;
}

.seatinfo {
  float: right;
  list-style: outside none none;
  width: 100%;
}

li:hover .seatdetails {
  display: block;
}

.seatinfo>li {
  float: left;
  width: 105%;
  padding: 3px 5px;
  margin: 0;
  border: none;
  height: auto;
}

// #topwing > span.leftwing::after {
//   border-color: #fff transparent transparent #fff;
//   border-style: solid;
//   border-width: 22px 30px 13px 20px;
//   content: "";
//   top: 0;
//   position: absolute;
//   z-index: 1;
// }

// #bottomwing > span.leftwing::after {
//   border-color: transparent #fff #fff transparent;
//   border-style: solid;
//   border-width: 11px 30px 8px 20px;
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
// }

// #topwing > span.rightwing::after {
//   border-color: #ffffff #fff transparent transparent;
//   border-style: solid;
//   border-width: 0px 12px 1px 38px;
//   content: "";
//   top: 0;
//   position: absolute;
//   z-index: 1;
// }

// #bottomwing > span.rightwing::after {
//   border-color: transparent transparent #fff #fff;
//   border-style: solid;
//   border-width: 10px 24px 9px;
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
// }

// #topwing>span.leftwing::after {
//   border-color: #fff transparent transparent #fff;
//   border-style: solid;
//   border-width:29px 19px;
//   content: "";
//   left: 432px;
//   top: -81px;
//   position: absolute;
//   z-index: 1;
//   transform: rotate(90deg);
//   /* Added rotation */
//   transform-origin: top left;
//   /* Adjust origin */
// }

// #bottomwing>span.leftwing::after {
//   border-color: transparent #fff #fff transparent;
//   border-style: solid;
//   //border-width: 
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
//   transform: rotate(40deg);
//   /* Added rotation */
//   transform-origin: bottom left;
//   /* Adjust origin */
// }

// #topwing>span.rightwing::after {
//   border-color: #ffffff #fff transparent transparent;
//   border-style: solid;
//   border-width: 78px  30px 78px 29px;
//   top: -50px;
//     left: -56px;
//   position: absolute;
//   z-index: 1;
//   transform: rotate(deg);
//   /* Added rotation */
//   transform-origin: top right;
//   /* Adjust origin */
// }

// #bottomwing>span.rightwing::after {
//   border-color: transparent transparent #fff #fff;
//   border-style: solid;
//   border-width: 10px 24px 9px;
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
//   transform: rotate(-40deg);
//   /* Added rotation */
//   transform-origin: bottom right;
//   /* Adjust origin */
// }

.windowrow {
  position: relative;
}

.flightdetails11 {
  margin-left: 74px;
}

.flightbox1 {
  margin-left: 250px;
}

.booked {
  background: #bfbdbe;
  color: grey;
  cursor: not-allowed;
}

.pass_name {
  padding: 5px 0px 5px 5px;
}

.seatrow .selected {
  background: rgb(82, 237, 82) !important;
  color: rgb(5, 5, 5);
}

@media (max-width: 1200px) {


  .seatlayoutbox {
    width: 60%;
  }

  .seatdetailbox {
    width: 35%;
  }

  .seatnumrow ul {
    right: 450px;
  }
}

@media (max-width: 992px) {
  .seatlayoutbox {
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .seatdetailbox {
    width: 100%;
    float: none;
  }

  .seatnumrow ul {
    right: 400px;
  }
}

@media (max-width: 767px) {


  .seatlayoutbox {
    width: 100%;
    max-height: 60%;
    overflow-y: auto;
  }

  .flightbox1 {
    margin-left: 50px;
  }

  .seatdetailbox {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .seatnumrow ul {
    position: static;
    margin-top: 10px;
  }

  .seatinn {
    height: 900px
  }
}

@media (max-width: 576px) {
  .seatlayoutbox {
    max-height: 50%;
  }

  .seatnumrow ul {
    position: static;
    margin-top: 5px;
    right: auto;
  }

  .flgtrow h2 {
    font-size: 12px;
    padding: 2px 10px;
  }

  .flightdetails {
    font-size: 14px;
  }

  .seatrow li,
  .seathint {
    height: 20px;
    margin-left: 10px;
  }

  .seatrow li span,
  .seathint span {
    width: 20px;
    font-size: 8px;
  }
}

@media (max-width: 480px) {


  .seatlayoutbox {
    max-height: 40%;
  }

  .seatnumrow ul {
    margin-top: 0;
  }

  .seatrow li,
  .seathint {
    height: 18px;
    margin-left: 5px;
  }

  .seatrow li span,
  .seathint span {
    width: 18px;
    font-size: 7px;
  }
}


.wingleft {

  width: 230px; // Width of the wing indicator
  height: 200px;
  background: url('https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/l-wing.png') no-repeat center center;
  background-size: cover;
}

.wingright {
  // position: 'absolute',
  // Adjust as needed to position the wing
  width: 230px; // Width of the wing indicator
  height: 200px;
  background: url('https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/r-wing.png') no-repeat center center;
  background-size: cover;
}

.nose-ft {
  width: 500px; // Width of the wing indicator
  height: 221px;
  background: url('https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/nose.png') no-repeat center center;
  background-size: cover;
}

@media (max-width: 767px) {
  .wingleft {
    width: 0px; // Width of the wing indicator
    height: 0px;
    background: none;
  }

  .wingright {
    width: 0px; // Width of the wing indicator
    height: 0px;
    background: none;
  }
}

.farerule-space {
  overflow: auto;
  height: 300px;
  padding: 20px;
}

.travel-duration .status {
  color: green;
  margin-top: -10px;
  font-weight: 600;
  font-family: 'Nunito';
  font-size: 14px;
}

.travel-duration .status1 {
  color: red;
  margin-top: -10px;
  font-weight: 600;
  font-family: 'Nunito';
  font-size: 13px;
}

.travel-duration .line-l {
  border-bottom: 1px solid #596ac7;
  width: 162% !important;
  margin-bottom: 20px;
  margin-top: 42px;
  margin-left: -38px !important;
  @media (max-width: 767px) {
    width:93%  !important;
    margin-left:23px !important;    
  }
}


.ant-card-body {
  padding: 0px !important;

}

.ant-card {
  // background: none !important;
  box-shadow: 0 9px 9px 3px hsla(0, 0%, 89%, .81);

}

.ant-collapse-header {
  // background: linear-gradient(90deg, #09b0dd, #026ba5);
  background: #f9f9f9;
}

.ant-collapse-content-box {
  // background: linear-gradient(90deg, #09b0dd, #026ba5);
  background: #ffffff;
}

.custom-form {

  // Target the label element inside form items
  .ant-form-item-label>label {
    font-size: 16px; // Example font size
    font-weight: 600; // Example font weight
    font-family: "Nunito"; // Example color

  }
}

.ssr-block {
  .ant-collapse-header {
    background: #ffffff;
    padding: 5px !important;
    box-shadow: 0px 1px 8px #b1b1b1;
    border-radius: 6px;
  }
}

.info {
  .ant-collapse-header {
    color: rgb(5, 5, 5) !important;
    font-family: "Nunito" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}

.login-infi-det {
  // background-color: #f9f9f9;
  // border-radius: 8px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .ant-alert-warning .ant-alert-icon {
    color: #faad14;
    margin-top: 12px;
  }

  .benefits-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }

  .benefits-list li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .benefits-list strong {
    min-width: 150px;
    /* Ensures consistent width for all headers */
    margin-right: 10px;
    /* Spacing between header and description */
    text-align: left;
    font-weight: bold;
    color: #333;
    line-height: 1.5;
  }

  .benefits-list span {
    flex: 1;
    /* Takes the remaining space */
    text-align: left;
    /* Aligns the description text neatly */
    color: #555;
    line-height: 1.5;
  }

  button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
  }
}

/* Truncated state: show only 2 lines */
.promo-percentage-cp .truncated {
  display: -webkit-box;
  // -webkit-line-clamp: 2;
  /* Show only 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

/* Expanded state: show full content */
.promo-percentage-cp .expanded {
  // -webkit-line-clamp: unset;
  overflow: visible;
  font-size: 12px;
}

/* Button styles */
.toggle-more {
  font-size: 14px;
  color: blue;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-size: 12px;
}

// .promo-percentage-cp {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
// }
.ant-modal-content {
  border-radius: 15px;
  padding: 0px;
}
.ant-modal-header {
  color: #fff;
  background: #08aad8 !important;
  padding:16px;
  h6 {
    color: #fff !important;
  }
}

.ant-modal-body{
  padding :16px
}

.ant-modal-footer{
  padding :16px
}
.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
  line-height: 0px;
  height: 18px;
}
.ant-collapse-header-text{
  margin-bottom: 14px;
}
.css-1oy18r0{
  line-height: 0px !important;
  height: 34px ;
  margin-top: 4px;
}
.css-1oy18r0.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #eeeeee !important;
}
.css-1oy18r0:hover .MuiOutlinedInput-notchedOutline {
  border-color: #eeeeee !important;
}