// .full-banner {
//     padding: 120px 0;
//     position: relative;
//     overflow: hidden;

//     .banner-content {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         height: 100%;

//         h5 {
//             color: #fff;
//             font-weight: 700;
//             text-transform: capitalize;
//             font-style: italic;
//             margin-top: -4px;
//             margin-top: 30px;

//             span {
//                 color: rgba(var(--theme-color1), 1);
//             }
//         }

//         h2 {
//             font-size: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
//             text-transform: capitalize;
//             font-weight: 700;
//             color: #ffff;
//             margin-bottom: 30px;

//             span {
//                 font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1920 - 320)));
//             }
//         }

//         p {
//             font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
//             color: rgba(white, 0.8);
//             line-height: 1.5;
//             margin-bottom: 25px;
//         }

//         .price {
//             font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1920 - 320)));

//             del {
//                 font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
//                 color: rgba(255, 255, 255, 0.9);
//                 font-weight: 400;
//             }
//         }

//         .bottom-section {
//             display: flex;
//             align-items: center;
//             margin-top: 35px;

//             .info-btn {
//                 margin-left: 25px;

//                 h6 {
//                     font-size: 14px;
//                     color: #ffff;
//                     font-weight: 700;
//                     text-transform: uppercase;
//                     margin-bottom: 0;
//                     background-color: rgba(white, 0.05);
//                     border-radius: 50px;
//                     padding: 10px 16px;
//                 }

//                 &.red-info {
//                     margin-left: 10px;

//                     h6 {
//                         color: rgba(var(--theme-color1), 1);
//                     }
//                 }
//             }
//         }
//     }

//     .offer-text {
//         position: absolute;

//         //   top: 20%;
//         //   left: -64%;
//         .offer {
//             padding: 3px 5px;
//             background-color: rgba(var(--theme-color1), 1);
//             color: #ffff;
//             font-size: 10px;
//             text-transform: uppercase;
//             border-radius: 25px;
//             font-weight: 700;
//         }

//         h6 {
//             color: #ffff;
//             text-transform: uppercase;
//             letter-spacing: 0.1em;
//             font-weight: 700;
//             position: relative;
//             margin-top: 10px;

//             span {
//                 margin-right: 7px;
//                 position: relative;
//                 display: inline-block;
//                 animation: ani 1s infinite alternate cubic-bezier(0.86, 0, 0.07, 1);

//                 &:nth-last-child(1n) {
//                     animation-delay: -0.1666666667s;
//                 }

//                 &:nth-last-child(2n) {
//                     animation-delay: -0.3333333333s;
//                 }

//                 &:nth-last-child(3n) {
//                     animation-delay: -0.5s;
//                 }
//             }

//             &:after {
//                 content: "";
//                 position: absolute;
//                 width: 80px;
//                 height: 3px;
//                 background-color: #ffff;
//                 top: 5px;
//                 right: -100px;
//                 background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(231, 56, 39, 0) 100%);
//             }
//         }
//     }

//     &.banner-section {
//         //   background-image: url(../../images/flights/bg-2.jpg);
//         background-image: url('../../assets/images/bg/bg.jpg');

//         background-size: cover;
//         background-position: center center;

//         .place-image {
//             position: relative;

//             .animation-place {
//                 .plane-l {
//                     animation: mover 1s infinite alternate;
//                     position: absolute;
//                     top: 22%;
//                     left: -11%;

//                     img {
//                         width: 70%;
//                     }
//                 }

//                 .plane-r {
//                     position: absolute;
//                     right: 7%;
//                     top: 0;
//                     animation: mover 1.1s infinite alternate;
//                 }

//                 .cloud-l {
//                     position: absolute;
//                     top: 0;
//                     left: 7%;

//                     img {
//                         width: 75%;
//                     }
//                 }

//                 .cloud-r {
//                     top: 0;
//                     position: absolute;
//                     right: 42%;
//                 }
//             }
//         }
//     }

//     &.small-section {
//         padding: 30px 0;
//     }

//     &.padding-cls {
//         padding-top: calc(90px + (200 - 90) * ((100vw - 320px) / (1920 - 320)));
//         padding-bottom: calc(90px + (200 - 90) * ((100vw - 320px) / (1920 - 320)));
//     }

//     &.overlay-banner {
//         background-blend-mode: overlay;
//         background-color: rgba(255, 255, 255, 0.7);

//         .banner-content {
//             h2 {
//                 color: black;
//             }

//             h5 {
//                 color: rgba(black, 0.8);
//             }

//             p {
//                 color: rgba(black, 0.7);
//             }

//             .bottom-section {
//                 .info-btn {
//                     h6 {
//                         color: black;
//                         background-color: rgba(black, 0.05);
//                     }
//                 }
//             }
//         }
//     }
// }

// .container {
//     .row-disc-ban {
//         // background-color: #003b95;
//         // background-image: url('../../assets/images/bg/bg.jpg');
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         padding: 20px;
//     }
// }

// .cloud {
//     animation: slide 50s linear infinite;
//     background-repeat: repeat !important;
// }
// .clod-img-1{
//     width: 20%;
// }
.full-banner {
    .zigZag {
        rotate: 180deg;
        /* top: -26%; */
        position: absolute;
        width: 100%;

    }

    .zigZagB {
        position: relative;
        margin-bottom: -25px;
        width: 100%;

    }
}

.banner-section {
    position: relative;
    width: 100%;
    // height: 100vh;
    overflow: hidden;
    background-image: url('https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/bg/bg.jpg');
    // background-size: cover;
}

.black-layer {
    position: relative;
    z-index: 2;
}

.cloud {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1;
    transform: translateY(-50%);
}

.clod-img-1 {
    position: absolute;
    width: 200px;
    /* Adjust as needed */
    height: auto;
    // top: 50%;
    animation-duration: 45s;

    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.clod-img {
    position: absolute;
    width: 100%;
    /* Adjust as needed */
    height: auto;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.clod-img-1 {
    left: -200px;
    /* Starts left offscreen */
    animation-name: slide-left;
}

.clod-img {
    right: -200px;
    /* Starts right offscreen */
    animation-name: slide-right;
}

/* Keyframe animations */
@keyframes slide-left {
    0% {
        left: -200px;
    }

    100% {
        left: 100%;
    }
}

@keyframes slide-right {
    0% {
        right: -200px;
    }

    100% {
        right: 100%;
    }
}

.row-disc-ban {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;

    @media (max-width: 480px) {
        flex-direction: column;
    }
}

.place-image img {
    width: 100%;
    max-width: 1100px;
}

.offset-lg-1 {
    .banner-content {
        color: white;

        .bann-wi-cont {
            width: 80%;

            @media (max-width: 480px) {
                width: 100%;
            }
        }
    }
}

.offer-text h6,
.banner-content h5,
.banner-content h2,
.banner-content p {
    margin: 10px 0;
    font-size: 18px;
}

.bottom-section {
    margin-top: 20px;
}

.info-btn h6 {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 5px 15px;
    border-radius: 5px;
}