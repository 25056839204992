.container {
  text-align: center;
  margin: 20px;
}

.container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.grid {
  display: flex;
  flex-direction: column;
  //  align-items: center;
  gap: 20px;
  @media (max-width: 767px) {
    display: block;
  }

}
.grid-1{
  display: flex;
  @media (max-width: 767px) {
    display: block;
  }
}

.card-im {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;

  // &:hover {
  //   transform: scale(1.05);
  // }

  img {
    width: 100%;
    height: 310px;
    object-fit: cover;
    //border-radius: 10px;
  }

  h2 {
    margin: 0;
    padding: 8px 12px;
    font-size: 18px;
    // background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    top: 10px; // Position from the top
    //right: 10px;       // Position from the right
    border-radius: 5px;
    text-align: left;
  }
}

@media (min-width: 768px) {


  .grid>div {
    display: flex;
    gap: 20px;
  }
}

.video-container {
  position: relative;
  width: 100%;
  // height: auto;
}

.plcehder-imge{
  width: 100%;
  height: auto;
  display: block;
}

.video-element {
  width: 100%;
  height: auto;
  display: block;
}

.hidden {
  display: none; /* Hide video until it's fully loaded */
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card-im:hover .video-overlay {
  opacity: 1; /* Show overlay on hover */
}

.overlay-text {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 200 !important;
  text-align: center;
  padding: 0 10px;
  transform: translateY(100%); /* Initially hides the text below */
  transition: transform 0.5s ease;
}
.card-im:hover .overlay-text {
  transform: translateY(0); /* Moves the text to the center when hovered */
}