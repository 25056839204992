@keyframes myOrbit {
    from {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }

    to {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}

@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-5px);
    }
}

@keyframes zoom_in {
    0% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

.animated-section {
    position: absolute;
    width: 100%;
    height: 80%;
    overflow: hidden;
    z-index: 0;

    .animation-section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60%;
        opacity: 0.5;

        .round,
        .square,
        .cross {
            position: absolute;
            background-color: transparent;
            animation: myOrbit 9s linear infinite;
        }

        .round {
            width: 20px;
            height: 20px;
            border: 4px solid #0881c5;
            border-radius: 50%;
        }

        .square {
            width: 30px;
            height: 30px;
            border: 4px solid #036aa5;
            animation: animationFramesTwo 15s linear infinite;
        }

        .cross {
            width: 40px;
            height: 40px;

            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 2px;
                height: 40px;
                background-color: #084ac5;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        // Positioning
        .po-1 {
            top: 50%;
            left: 5%;
        }

        .po-2 {
            right: 5%;
            bottom: 7%;
        }

        .po-3 {
            top: 20%;
            right: 8%;
        }

        .po-4 {
            bottom: 5%;
            left: 30%;
        }

        .po-5 {
            right: 20%;
            top: 10%;
        }

        .po-6 {
            top: 2%;
            left: 47%;
        }

        .po-7 {
            right: 1%;
            top: 53%;
        }

        .po-8 {
            bottom: 1%;
            right: 27%;
        }

        .po-9 {
            top: 24%;
            right: 30%;
        }

        .po-10 {
            top: 5%;
            left: 10%;
        }

        .po-11 {
            bottom: 32%;
            left: 36%;
        }

        .po-12 {
            left: 2%;
            bottom: 10%;
        }
    }
}